import { Stack, Typography, useTheme } from "@mui/material";
import { CircularProgress } from "ui/components";
import { useSelector } from "react-redux";
import { ProgressBlockContainer } from "./styled/ProgressBlockContainer";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const ProgressBlock = () => {
  const theme = useTheme();
  const user = useSelector(userSelectors.user);

  return (
    <ProgressBlockContainer direction="row" alignItems="flex-start" justifyContent="space-between">
      <Stack>
        <Typography variant="bodyM" color="white">
          Set up to send
        </Typography>
        <Typography variant="bodyS" color="white">
          {`Next: ${user.nextStep}`}
        </Typography>
      </Stack>
      <CircularProgress
        value={user.progress}
        bgColor={theme.palette.gray[300]}
        fillColor={theme.palette.white}
        textColor={theme.palette.white}
      />
    </ProgressBlockContainer>
  );
};
