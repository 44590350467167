export function format({ currency = "", total }) {
  const currencies = {
    eur: "€",
    usd: "$"
  };

  const currentCurrency = currencies[currency.toLowerCase()] || currency;
  const amount = parseFloat(total / 100).toFixed(2);
  return `${currentCurrency} ${amount}`;
}
