import { useDispatch } from "react-redux";
import {
  removePaymentMethod,
  selectPaymentMethod
} from "core/store/entities/Billing/billing.actions";

export const usePaymentCard = ({ id, brand, isSelected }) => {
  const dispatch = useDispatch();
  const availableBrands = ["Mastercard", "Visa", "Discover"];
  const icon = availableBrands.includes(brand) ? brand.toLowerCase() : "defaultPaymentIcon";
  const selectPaymentCard = () => {
    dispatch(selectPaymentMethod(id));
  };
  const removePaymentCard = e => {
    e.stopPropagation();
    dispatch(removePaymentMethod({ id, isSelected }));
  };
  return { icon, selectPaymentCard, removePaymentCard };
};
