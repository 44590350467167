import { BillingSectionTitle } from "../BillingSectionTitle";
import { Alert, Grid } from "@mui/material";
import { BillingInformationContainer } from "./styled";
import { BillingInfoBlock } from "../BillingInfoBlock";
import { useBillingInformation } from "./useBillingInformation";
import { TextButton } from "ui/components";
import { useCustomerSupport } from "hooks";
import { CANCEL_MY_PLAN, EDIT_BILLING_INFO_MODAL } from "core/constants";
import { EditBillingInformationModal } from "./EditBillingInformationModal";
import React from "react";
import { useSelector } from "react-redux";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const BillingInformation = () => {
  const { billingInformation, billingDataErrors, handleEditBillingInfo } = useBillingInformation();
  const { openCustomerSupport } = useCustomerSupport(CANCEL_MY_PLAN);
  const isEditBillingInfoModalOpen = useSelector(modalSelectors[EDIT_BILLING_INFO_MODAL]);
  return (
    <>
      <BillingSectionTitle title="Billing Information" />
      <Grid container item lg={7.9} gap={2}>
        {!!billingDataErrors && (
          <Grid container item>
            <Alert variant="filled" severity="error">
              {billingDataErrors?.detail}
            </Alert>
          </Grid>
        )}
        <Grid container item xs={12} flexDirection="column" alignItems="flex-start" gap={1}>
          {!billingDataErrors && (
            <BillingInformationContainer>
              <BillingInfoBlock
                data={billingInformation}
                spacing={3}
                handleEdit={handleEditBillingInfo}
              />
            </BillingInformationContainer>
          )}
          <TextButton underline onClick={openCustomerSupport}>
            Cancel my plan
          </TextButton>
        </Grid>
      </Grid>
      {isEditBillingInfoModalOpen && <EditBillingInformationModal />}
    </>
  );
};
