import { StyledMenu } from "./styled";
import { MenuItem, Typography } from "@mui/material";
import { Divider } from "ui/components";

export const Menu = ({ items, onClose, ...props }) => {
  const handleMenuItemClick = menuItemClick => () => {
    menuItemClick();
    onClose();
  };

  return (
    <StyledMenu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      onClose={onClose}
      {...props}
    >
      {items.map((menuItem, index) =>
        menuItem.divider ? (
          <Divider key={`${index}-divider`} />
        ) : (
          <MenuItem key={index} onClick={handleMenuItemClick(menuItem.onClick)} disableRipple>
            {menuItem.icon}
            <Typography variant="bodyM" sx={menuItem.customStyles}>
              {menuItem.name}
            </Typography>
          </MenuItem>
        )
      )}
    </StyledMenu>
  );
};
