import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { confirmEmail } from "core/store/entities/User/user.actions";
import { FullscreenSpinner, Link } from "ui/components";
import { DASHBOARD_SCREEN } from "core/constants";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const ConfirmEmail = () => {
  const { id, hash } = useParams();
  const dispatch = useDispatch();
  const confirmEmailMessage = useSelector(userSelectors.confirmEmailMessage);

  useEffect(() => {
    dispatch(confirmEmail({ id_: id, hash }));
  }, []);

  if (!confirmEmailMessage) return <FullscreenSpinner />;

  return (
    <Grid container alignItems="center" justifyContent="center" minHeight="100vh">
      <Stack justifyContent="center" alignItems="center" spacing={1}>
        <Typography variant="headlineM">{confirmEmailMessage}</Typography>
        <Typography variant="bodyM">
          <Link to={DASHBOARD_SCREEN}>Go to dashboard</Link>
        </Typography>
      </Stack>
    </Grid>
  );
};
