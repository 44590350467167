import { useDispatch, useSelector } from "react-redux";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { EDIT_BILLING_INFO_MODAL } from "core/constants";
import { billingDataSelectors } from "core/store/entities/Billing/billing.selectors";

export const useBillingInformation = () => {
  const dispatch = useDispatch();
  const billingDataErrors = useSelector(billingDataSelectors.error);
  const billingData = useSelector(billingDataSelectors.data);

  const billingInformation = [
    { title: "Email", values: [billingData?.email] },
    {
      title: "Address",
      values: [
        billingData?.companyName,
        billingData?.firstAddressLine,
        billingData?.secondAddressLine,
        `${billingData?.postalCode} ${billingData?.city}`,
        billingData?.state
      ]
    }
  ];

  const handleEditBillingInfo = () => {
    dispatch(showModal(EDIT_BILLING_INFO_MODAL));
  };

  return { billingInformation, billingDataErrors, handleEditBillingInfo };
};
