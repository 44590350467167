import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { EDIT_PERSONAL_INFO_MODAL } from "core/constants";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { editUser } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const useEditPersonalInfo = () => {
  const dispatch = useDispatch();
  const errors = useSelector(userSelectors.errors);
  const user = useSelector(userSelectors.user);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company
    }
  });
  const isEditPersonalInfoModalOpen = useSelector(modalSelectors[EDIT_PERSONAL_INFO_MODAL]);

  const onSubmit = data => {
    dispatch(editUser(data));
  };

  const onClose = () => {
    dispatch(hideModal(EDIT_PERSONAL_INFO_MODAL));
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    onClose,
    isEditPersonalInfoModalOpen,
    errors
  };
};
