import React from "react";
import { Header } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { Alert, Grid } from "@mui/material";
import { ARROW_RIGHT_SYMBOL } from "core/constants";
import { DomainsSelect } from "./DomainsSelect";
import { useStatisticsDetails } from "./useStatisticsDetails";
import { DomainOverall } from "./DomainOverall";
import { DeliveriesBounces } from "./DeliveriesBounces";
import { ISPTable } from "./ISPTable";
import { BouncesTable } from "./BouncesTable";

export const StatisticsDetails = () => {
  const { error, handleFetchDomainList } = useStatisticsDetails();
  return (
    <>
      <Header
        title={`Statistics ${ARROW_RIGHT_SYMBOL}`}
        selectComponent={<DomainsSelect />}
        onProfileSelect={handleFetchDomainList}
      />
      <Grid container gap={6} sx={{ padding: { ...generalContentPadding, lg: "0 128px 60px" } }}>
        {!!error && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <DomainOverall />
        </Grid>
        <Grid item xs={12}>
          <DeliveriesBounces />
        </Grid>
        <Grid item xs={12} mt={7}>
          <ISPTable />
        </Grid>
        <Grid item xs={12}>
          <BouncesTable />
        </Grid>
      </Grid>
    </>
  );
};
