import { Stack, Typography } from "@mui/material";
import { ReactComponent as PencilIcon } from "ui/assets/images/pencil-light.svg";
import React from "react";

export const EditButton = ({ onClick }) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      sx={{ textDecoration: "underline", cursor: "pointer" }}
      color={theme => theme.palette.blue[500]}
      onClick={onClick}
    >
      <PencilIcon height={12} width={12} fill="#0B97F4" />
      <Typography variant="bodyM" color="#0B97F4">
        edit
      </Typography>
    </Stack>
  );
};
