import { Stack } from "@mui/material";
import { InfoBlock, Spinner } from "ui/components";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { useEffect } from "react";
import { getReturnPaths } from "core/store/entities/User/user.actions";

export const ReturnPathContainer = () => {
  const dispatch = useDispatch();
  const returnPaths = useSelector(userSelectors.returnPaths);
  const isReturnPathsLoading = useSelector(userSelectors.isReturnPathsLoading);

  useEffect(() => {
    dispatch(getReturnPaths());
  }, []);

  if (isReturnPathsLoading) return <Spinner />;

  return (
    <Stack spacing={1.5} maxHeight={335} overflow="auto">
      {returnPaths?.map((returnPath, index) => (
        <InfoBlock key={index} item={returnPath} />
      ))}
    </Stack>
  );
};
