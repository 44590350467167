import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchGlobalStats,
  fetchPerDomainStatsTable,
  setFilterValue
} from "core/store/entities/Statistics/statistics.actions";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const useOverall = () => {
  const globalStats = useSelector(statisticsSelectors.globalStats);
  const filterOptions = useSelector(statisticsSelectors.filterOptions);
  const filterValue = useSelector(statisticsSelectors.filterValue);
  const isGlobalStatsLoading = useSelector(statisticsSelectors.isGlobalStatsLoading);
  const { currentPeriodText } = useCurrentPeriodText({ filterValue });
  const dispatch = useDispatch();

  const { total_send: totalSent = 0, bounce_rate: bounceRate = 0 } = globalStats.global_stats
    ? globalStats.global_stats
    : {};

  const { total_send: totalSentOld = 0, bounce_rate: bounceRateOld = 0 } =
    globalStats.global_stats_old ? globalStats.global_stats_old : {};

  const totalSentDiff = totalSentOld ? ((totalSent - totalSentOld) / totalSentOld) * 100 : 0;

  const percentDelivered = totalSent > 0 ? 100 - bounceRate : 0;
  const percentDeliveredDiff = percentDelivered > 0 ? percentDelivered - 100 + bounceRateOld : 0;

  const percentBounced = bounceRate;
  const totalBouncedDiff = bounceRate - bounceRateOld;

  useEffect(() => {
    dispatch(fetchGlobalStats({ startDate: filterValue }));
    dispatch(fetchPerDomainStatsTable({ startDate: filterValue }));
  }, []);

  const filterChangeHandler = event => {
    dispatch(setFilterValue(event.target.value));
    dispatch(fetchGlobalStats({ startDate: event.target.value }));
    dispatch(fetchPerDomainStatsTable({ startDate: event.target.value }));
  };

  return {
    isGlobalStatsLoading,
    totalSent,
    totalSentDiff,
    filterOptions,
    filterValue,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    currentPeriodText,
    filterChangeHandler
  };
};
