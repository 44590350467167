import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { changePassword, changePasswordOnReset } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";

const schema = yup.object({
  newPassword: yup.string().required("This field is required"),
  confirmPassword: yup.string().required("This field is required")
});

export const useChangePasswordForm = () => {
  const { id, hash } = useParams();
  const dispatch = useDispatch();
  const apiErrors = useSelector(userSelectors.errors);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm({ resolver: yupResolver(schema) });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const errors = { ...apiErrors, ...formErrors };

  const onSubmit = data => {
    if (hash && id) {
      dispatch(changePasswordOnReset({ ...data, id, hash }));
    } else {
      dispatch(changePassword(data));
    }
  };

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    showNewPassword,
    showConfirmPassword,
    toggleShowNewPassword,
    toggleShowConfirmPassword
  };
};
