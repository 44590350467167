import { getRequest } from "core/api/base";

export class StatisticsService {
  static getGlobalStats = ({ date, range }) =>
    getRequest({ url: `/rest/live_stats/${date}/?range=${range}` });
  static getDomainsStatsTable = ({ date, range }) =>
    getRequest({ url: `/rest/live_stats/${date}/table/?range=${range}` });
  static getStatsPerDomain = ({ date, domainId, range }) =>
    getRequest({ url: `/rest/live_stats/${date}/${domainId}/?range=${range}` });
  static getStatsPerDomainTable = ({ date, domainId, range }) =>
    getRequest({ url: `/rest/live_stats/${date}/${domainId}/table/?range=${range}` });
  static downloadLogs = ({ period }) => getRequest({ url: `/rest/download_logs/${period}/` });
  static getTinsel = () => getRequest({ url: `/rest/tinsel/` });
}
