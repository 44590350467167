import { Stack, Typography } from "@mui/material";
import React from "react";
import { useDomainOverall } from "./useDomainOverall";
import { FilterSelect, OverallBlock, Spinner } from "ui/components";

export const DomainOverall = () => {
  const {
    totalSent,
    totalSentDiff,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    filterOptions,
    filterDetailsValue,
    isLoading,
    currentPeriodText,
    filterChangeHandler
  } = useDomainOverall();

  return (
    <Stack>
      <Stack direction="row" alignItems="end" spacing={2}>
        <Typography variant="sectionHeaderS">Overall</Typography>
        <Typography variant="bodyXS">{currentPeriodText}</Typography>
        <FilterSelect
          options={filterOptions}
          value={filterDetailsValue}
          onChange={filterChangeHandler}
          style={{ marginLeft: "auto" }}
        />
      </Stack>
      {isLoading ? (
        <Spinner />
      ) : (
        <OverallBlock
          totalSent={totalSent}
          totalSentDiff={totalSentDiff}
          percentDelivered={percentDelivered}
          percentDeliveredDiff={percentDeliveredDiff}
          percentBounced={percentBounced}
          totalBouncedDiff={totalBouncedDiff}
        />
      )}
    </Stack>
  );
};
