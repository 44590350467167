import { diffPercentage } from "helpers/percentage";
import { useDispatch, useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { filterDetailsChange } from "core/store/entities/Statistics/statistics.actions";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const useDomainOverall = () => {
  const dispatch = useDispatch();
  const globalStatsPerDomain = useSelector(statisticsSelectors.globalStatsPerDomain);
  const isDomainsOptionsLoading = useSelector(statisticsSelectors.isDomainsOptionsLoading);
  const isGlobalStatsPerDomainLoading = useSelector(
    statisticsSelectors.isGlobalStatsPerDomainLoading
  );
  const filterDetailsValue = useSelector(statisticsSelectors.filterDetailsValue);
  const filterOptions = useSelector(statisticsSelectors.filterOptions);
  const { currentPeriodText } = useCurrentPeriodText({ compareToPrevious: true });

  const filterChangeHandler = event => {
    dispatch(filterDetailsChange(event.target.value));
  };

  const { counts = {}, counts_old = {} } = globalStatsPerDomain;
  const { total_send: totalSent = 0, bounce_rate: bounceRate = 0 } = counts;
  const { total_send: totalSentOld = 0, bounce_rate: bounceRateOld = 0 } = counts_old;

  const totalSentDiff = diffPercentage(totalSentOld, totalSent);
  const percentDelivered = totalSent > 0 ? 100 - bounceRate : 0;
  const percentDeliveredDiff = percentDelivered > 0 ? percentDelivered - 100 + bounceRateOld : 0;

  const percentBounced = bounceRate;
  const totalBouncedDiff = bounceRate - bounceRateOld;

  return {
    totalSent,
    totalSentDiff,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    filterOptions,
    filterDetailsValue,
    isLoading: isDomainsOptionsLoading || isGlobalStatsPerDomainLoading,
    currentPeriodText,
    filterChangeHandler
  };
};
