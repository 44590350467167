import React from "react";
import { Navigate } from "react-router-dom";
import { AdminView, ConfirmEmail, PageNotFound } from "ui/screens";
import {
  ADMIN_VIEW_SCREEN,
  CONFIRM_EMAIL_SCREEN,
  DASHBOARD_SCREEN,
  ROOT_SCREEN
} from "core/constants";

export const publicRoutes = [
  {
    path: ROOT_SCREEN,
    element: <Navigate to={DASHBOARD_SCREEN} />
  },
  {
    path: `${CONFIRM_EMAIL_SCREEN}/:id/:hash`,
    element: <ConfirmEmail />
  },
  {
    exact: true,
    path: ADMIN_VIEW_SCREEN,
    element: <AdminView />
  },
  {
    path: "*",
    element: <PageNotFound />
  }
];
