import { List, Stack, Typography } from "@mui/material";
import React from "react";
import { Image, Link } from "../../../components";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../../core/store/entities/User/user.selectors";

export const BounceLogsImportanceText = () => {
  const user = useSelector(userSelectors.user);

  return (
    <Stack spacing={3}>
      <Stack direction="row" gap={2} alignItems="center" mt={2}>
        <Image name="daniel" />
        <Typography variant="headlineS">{`Hi ${user.firstName},`}</Typography>
      </Stack>
      <Typography variant="bodyL">
        Processing your bounces and complaints is important for improving your sending results.
      </Typography>
      <Typography variant="bodyL">We split the data by type:</Typography>
      <Typography variant="bodyL">
        <List>
          <li>- “h” for hard bounces</li>
          <li>- “s” for soft bounces</li>
          <li>- “e” for expired</li>
          <li>- “f” for complaints</li>
        </List>
      </Typography>
      <Typography variant="bodyL">
        Read more from us about bounces{" "}
        <Link
          external
          target="_blank"
          href="https://inboxroad.com/bounced-emails-what-are-they-and-how-to-limit-them/"
        >
          here
        </Link>
      </Typography>
      <Typography variant="bodyL">Daniel</Typography>
    </Stack>
  );
};
