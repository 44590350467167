import { call, put, select, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  getInvoices,
  getInvoicesFailed,
  getInvoicesStartSuccess,
  getInvoicesSuccess,
  invoicesPagination
} from "../billing.actions";
import { billingInvoicesSelectors } from "../billing.selectors";

function* handleGetInvoices({ payload = { fromStart: false } }) {
  try {
    const starting_after = yield select(billingInvoicesSelectors.startingAfter);
    const invoices = yield call(
      BillingService.getInvoices,
      payload.fromStart ? {} : { starting_after }
    );
    if (payload.fromStart) {
      yield put(getInvoicesStartSuccess(invoices.data));
    } else {
      yield put(getInvoicesSuccess(invoices.data));
    }
    yield put(
      invoicesPagination({
        hasMore: !!invoices?.has_more,
        startingAfter: invoices?.has_more ? invoices?.starting_at || null : null
      })
    );
  } catch (error) {
    yield put(getInvoicesFailed(error));
  }
}

export default function* watchGetInvoices() {
  yield takeEvery(getInvoices.type, handleGetInvoices);
}
