import { BillingSectionTitle } from "../BillingSectionTitle";
import { Grid } from "@mui/material";
import { useBillingAccount } from "./useBillingAccount";
import { Divider } from "ui/components";
import { BillingAccountContainer } from "./styled";
import { BillingInfoBlock } from "../BillingInfoBlock";
import { EditPersonalInfoModal } from "./EditPersonalInfoModal";
import { ChangePasswordModal } from "./ChangePasswordModal";

export const BillingAccount = () => {
  const {
    userPersonalInfo,
    userAccountInfo,
    handleShowEditPersonalInfoModal,
    handleShowChangePasswordModal
  } = useBillingAccount();
  return (
    <>
      <BillingSectionTitle title="Account" />
      <Grid container item lg={7.9} flexDirection="column" alignItems="flex-start" gap={1}>
        <BillingAccountContainer
          divider={
            <Divider
              orientation="horizontal"
              sx={{ margin: { xs: "14px -10px", sm: "14px -24px" } }}
            />
          }
        >
          <BillingInfoBlock
            data={userPersonalInfo}
            handleEdit={handleShowEditPersonalInfoModal}
            spacing={0.5}
          />
          <BillingInfoBlock
            data={userAccountInfo}
            handleEdit={handleShowChangePasswordModal}
            spacing={0.5}
          />
        </BillingAccountContainer>
      </Grid>
      <EditPersonalInfoModal />
      <ChangePasswordModal />
    </>
  );
};
