import { Alert, Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { Button, Image, Input, Link, ShowPasswordIcon } from "ui/components";
import { Controller } from "react-hook-form";
import { CREATE_FREE_ACCOUNT_SCREEN, RESET_PASSWORD_SCREEN } from "core/constants";
import { useLogin } from "./useLogin";

export const Login = () => {
  const { control, handleSubmit, onSubmit, errors, showPassword, handleClickShowPassword } =
    useLogin();

  return (
    <Grid container alignItems="center" justifyContent="center" minHeight="100vh">
      <Grid
        item
        xs={8}
        md={5}
        lg={4}
        xl={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image name="inboxroadIcon" height="35px" />
        <Grid
          container
          rowSpacing={2}
          mt={7}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          width={360}
        >
          <Typography mb={2.5} variant="headlineM" textAlign="center" width="100%">
            Welcome back
          </Typography>
          {!!errors?.nonFieldErrors && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors?.nonFieldErrors}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="username"
                  label="Your business email"
                  name="username"
                  error={!!errors?.username?.message}
                  helperText={errors?.username?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  fullWidth
                  id="password"
                  label="Your password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={event => {
                    field.onChange(event);
                  }}
                  inputRef={field.ref}
                  InputProps={{
                    endAdornment: (
                      <ShowPasswordIcon show={showPassword} toggle={handleClickShowPassword} />
                    )
                  }}
                  error={!!errors?.password?.message}
                  helperText={errors?.password?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Checkbox sx={{ p: 0, mr: "5px" }} color="default" />
                <Typography variant="bodyS">Remember me</Typography>
              </Box>
              <Typography variant="bodyS">
                <Link to={RESET_PASSWORD_SCREEN}>Forgot password?</Link>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth sx={{ mb: 2 }}>
              Log in
            </Button>
          </Grid>
        </Grid>
        <Grid item xs textAlign="center">
          <Typography variant="bodyS">
            No account yet? <Link to={CREATE_FREE_ACCOUNT_SCREEN}>Start here</Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: "none", lg: "block" },
          position: "absolute",
          right: "85px",
          bottom: "67px"
        }}
      >
        <Image name="onboardingVisual" />
      </Grid>
    </Grid>
  );
};
