import { Chip as ChipMUI, chipClasses, styled } from "@mui/material";

export const StyledChip = styled(ChipMUI)(({ theme, type, variant }) => ({
  borderRadius: "4px",
  padding: "0 9px",
  flexDirection: "row-reverse",
  alignItem: "center",
  justifyContent: "flex-end",
  ...(variant === "outlined" && {
    borderColor: "rgba(0, 0, 0, .12)"
  }),
  ...(type === "success" && {
    backgroundColor: theme.palette.green[100],
    color: theme.palette.green[600]
  }),
  ...(type === "alert" && {
    backgroundColor: theme.palette.pink[100],
    color: theme.palette.pink[600]
  }),
  ...(type === "warning" && {
    backgroundColor: theme.palette.yellow[100],
    color: "#494631"
  }),
  [`& .${chipClasses.label}`]: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    padding: "0"
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: "1rem",
    marginRight: 0,
    color: "inherit",
    marginBottom: "3px"
  }
}));
