import {
  GENERAL_TOKEN,
  LOGOUT_TOKEN,
  PROFILE_TOKEN,
  PROFILES,
  SELECTED_PROFILE
} from "core/constants";

export const setLoginData = loginData => {
  const { customer_profiles = [], token } = loginData;
  const { customerprofile_token = "" } = customer_profiles[0] || "";
  localStorage.setItem(PROFILES, JSON.stringify(customer_profiles));
  localStorage.setItem(
    SELECTED_PROFILE,
    customer_profiles[0] ? JSON.stringify(customer_profiles[0]) : "{}"
  );
  localStorage.setItem(LOGOUT_TOKEN, token);
  if (customer_profiles.length) {
    localStorage.setItem(PROFILE_TOKEN, customerprofile_token);
  }
  localStorage.setItem(GENERAL_TOKEN, token);

  return customer_profiles;
};
