import { Button, Input } from "ui/components";
import React from "react";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";

import { useCreateAccountStep1 } from "./useCreateAccountStep1";

export const CreateAccountStep1 = () => {
  const { control, handleSubmit, onSubmit, errors } = useCreateAccountStep1();
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            fullWidth
            id="email"
            label="Your business email"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors?.email?.message}
            helperText={errors?.email?.message}
            {...field}
          />
        )}
      />
      <Button type="submit" fullWidth sx={{ mt: 2, mb: 2 }}>
        Create account
      </Button>
    </Box>
  );
};
