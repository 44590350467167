import { PlusIcon, TextButton } from "ui/components";
import { Stack } from "@mui/material";
import { AddNewCreditCardContainer } from "./styled";
import React from "react";
import { AddCreditCardModal } from "../AddCreditCardModal";
import { useDispatch } from "react-redux";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { ADD_CREDIT_CARD_MODAL } from "core/constants";

export const AddNewCreditCard = () => {
  const dispatch = useDispatch();

  const handleOpenAddNewCreditCardModal = () => {
    dispatch(showModal(ADD_CREDIT_CARD_MODAL));
  };

  return (
    <>
      <AddNewCreditCardContainer justifyContent="center" alignItems="center">
        <Stack direction="row" gap={0.5}>
          <PlusIcon />
          <TextButton underline type="primary" onClick={handleOpenAddNewCreditCardModal}>
            Add a new credit card
          </TextButton>
        </Stack>
      </AddNewCreditCardContainer>
      <AddCreditCardModal />
    </>
  );
};
