import { UPGRADE_PLANS, CUSTOM_PLANS } from "core/constants";
import { upgradePlanReducers } from "./upgradePlan.reducers";
import countries from "ui/assets/countries.json";

const { createSlice } = require("@reduxjs/toolkit");

const upgradePlanSlice = createSlice({
  name: "upgradePlan",
  initialState: {
    showUpgradePlanModal: false,
    showUpgradePlanConfirmation: false,
    sendUpgradePlan: false,
    sendUpgradePlanSuccess: false,
    sendUpgradePlanError: null,
    upgradePlans: UPGRADE_PLANS,
    customPlans: CUSTOM_PLANS,
    countries: countries.countries,
    topCountries: countries.topCountries
  },
  reducers: upgradePlanReducers
});

const upgradePlanActions = upgradePlanSlice.actions;
const upgradePlanReducer = upgradePlanSlice.reducer;

export { upgradePlanActions, upgradePlanReducer };
