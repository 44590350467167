import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "../../Image";
import { PlusIcon } from "../../.";
import { logout, logoutAsAdmin, selectProfile } from "core/store/entities/User/user.actions";
import { useCustomerSupport } from "hooks";
import { NEW_SUB_ACCOUNT } from "core/constants";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const useProfileMenu = ({ onProfileSelect }) => {
  const user = useSelector(userSelectors.user);
  const dispatch = useDispatch();
  const { openCustomerSupport } = useCustomerSupport(NEW_SUB_ACCOUNT);
  const [menu, setMenu] = useState(null);
  const profiles = useSelector(userSelectors.profiles);
  const selectedProfile = useSelector(userSelectors.selectedProfile);
  const isAdminView = useSelector(userSelectors.isAdminView);

  const open = !!menu;
  const menuButtonId = "profile-menu-button";
  const menuId = "profile-menu";

  const handleOpenMenu = event => {
    setMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenu(null);
  };

  const handleSwitchProfile = profile => () => {
    dispatch(selectProfile(profile));
    onProfileSelect();
  };

  const handleLogout = () => {
    isAdminView ? dispatch(logoutAsAdmin()) : dispatch(logout());
  };

  const profileName = selectedProfile?.name || user?.company;

  const menuItems = [
    ...profiles.flatMap(profile => {
      const { customerprofile_token: selectedToken = null } = selectedProfile || {};
      const { customerprofile_token: profileToken = null } = profile;
      return profileToken !== selectedToken
        ? [{ name: profile.name, onClick: handleSwitchProfile(profile) }]
        : [];
    }),
    { name: "New subaccount", icon: <PlusIcon />, onClick: openCustomerSupport },
    { divider: true },
    {
      name: "Log out",
      customStyles: { fontWeight: 500 },
      icon: <Image name="logout" width={16} height={16} />,
      onClick: handleLogout
    }
  ];

  return {
    profileName,
    menu,
    menuItems,
    selectedProfile,
    open,
    menuButtonId,
    menuId,
    handleOpenMenu,
    handleCloseMenu
  };
};
