import { Dialog } from "../../index";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideFeedbackPopup } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const FeedbackPopup = () => {
  const open = useSelector(userSelectors.showFeedbackPopup);
  const content = useSelector(userSelectors.feedbackPopupContent);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideFeedbackPopup());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack p="0 125px 25px 50px">
        {content.map((item, index) => (
          <Typography key={index} variant="bodyL">
            {item}
          </Typography>
        ))}
      </Stack>
    </Dialog>
  );
};
