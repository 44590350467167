import { Typography } from "@mui/material";
import { StyledDnsRecordHeader } from "../styled";

export const DnsRecordHeader = ({ width, title }) => {
  return (
    <StyledDnsRecordHeader width={width}>
      <Typography variant="bodyMBold">{title}</Typography>
    </StyledDnsRecordHeader>
  );
};
