const ENTITY = "billing";

export const billingSubscriptionSelectors = {
  data: state => state[ENTITY].subscription.data,
  isLoading: state => state[ENTITY].subscription.isLoading,
  error: state => state[ENTITY].subscription.error,
  totalSend: state => billingSubscriptionSelectors.data(state).totalSend,
  totalAvailable: state => billingSubscriptionSelectors.data(state).totalAvailable,
  totalSendPercentage: state => billingSubscriptionSelectors.data(state).totalSendPercentage,
  subscriptionStart: state => billingSubscriptionSelectors.data(state).subscriptionStart,
  subscriptionEnd: state => billingSubscriptionSelectors.data(state).subscriptionEnd
};

export const billingDataSelectors = {
  data: state => state[ENTITY].billingData.data,
  isLoading: state => state[ENTITY].billingData.isLoading,
  error: state => state[ENTITY].billingData.error
};

export const billingInvoicesSelectors = {
  data: state => state[ENTITY].invoices.data,
  isLoading: state => state[ENTITY].invoices.isLoading,
  error: state => state[ENTITY].invoices.error,
  hasMore: state => state[ENTITY].invoices.hasMore,
  startingAfter: state => state[ENTITY].invoices.startingAfter
};

export const billingPaymentMethodsSelectors = {
  data: state => state[ENTITY].paymentMethods.data,
  exists: state => !!state[ENTITY].paymentMethods.data.length,
  isLoading: state => state[ENTITY].paymentMethods.isLoading,
  error: state => state[ENTITY].paymentMethods.error,
  isAdding: state => state[ENTITY].paymentMethods.isAdding,
  addError: state => state[ENTITY].paymentMethods.addError,
  paymentMethodToDelete: state => state[ENTITY].paymentMethods.paymentMethodToDelete,
  errorDeleting: state => state[ENTITY].paymentMethods.errorDeleting
};
