import { StyledAlert } from "./styled";

export const Alert = ({ message, variant, severity, ...props }) => {
  return (
    message && (
      <StyledAlert variant={variant} severity={severity} {...props}>
        {message}
      </StyledAlert>
    )
  );
};

Alert.defaultProps = {
  message: "",
  variant: "filled",
  severity: "error"
};
