import { useDispatch, useSelector } from "react-redux";
import { showModal } from "core/store/entities/Modal/modal.actions";
import { CHANGE_PASSWORD_MODAL, EDIT_PERSONAL_INFO_MODAL } from "core/constants";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const useBillingAccount = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.user);

  const userPersonalInfo = [
    {
      title: "Name",
      values: [user.fullName]
    },
    {
      title: "Company",
      values: [user.company]
    }
  ];

  const userAccountInfo = [
    {
      title: "Username",
      values: [user.email]
    },
    {
      title: "Password",
      values: ["••••••••••••••"]
    }
  ];

  const handleShowEditPersonalInfoModal = () => {
    dispatch(showModal(EDIT_PERSONAL_INFO_MODAL));
  };

  const handleShowChangePasswordModal = () => {
    dispatch(showModal(CHANGE_PASSWORD_MODAL));
  };

  return {
    user,
    userPersonalInfo,
    userAccountInfo,
    handleShowEditPersonalInfoModal,
    handleShowChangePasswordModal
  };
};
