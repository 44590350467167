import { Button, Dialog, Input } from "ui/components";
import { Alert, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useEditPersonalInfo } from "./useEditPersonalInfo";

export const EditPersonalInfoModal = () => {
  const { control, handleSubmit, onSubmit, onClose, isEditPersonalInfoModalOpen, errors } =
    useEditPersonalInfo();

  return (
    <Dialog open={isEditPersonalInfoModalOpen} onClose={onClose} paperStyles={{ width: "476px" }}>
      <Stack spacing={0.5} p="0 48px 48px 28px">
        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          maxWidth="100%"
          spacing={2.5}
          ml={0}
        >
          <Grid item xs={12}>
            <Typography variant="bodyL">Edit account information</Typography>
          </Grid>
          {!!errors?.nonFieldErrors && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors?.nonFieldErrors}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="firstName"
                  label="First name"
                  name="firstName"
                  error={!!errors?.firstName?.message}
                  helperText={errors?.firstName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="lastName"
                  label="Last name"
                  name="lastName"
                  error={!!errors?.lastName?.message}
                  helperText={errors?.lastName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="company"
                  label="Company name"
                  name="company"
                  error={!!errors?.company?.message}
                  helperText={errors?.company?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button sx={{ mt: 1 }} type="submit" fullWidth>
              Edit
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
