import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TS27KZ4"
};

class GoogleTagManager {
  static init() {
    TagManager.initialize(tagManagerArgs);
  }
}

export default GoogleTagManager;
