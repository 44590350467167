import { Button as MUIButton, styled } from "@mui/material";

export const StyledButton = styled(MUIButton)(({ size = "default", bgColor, theme }) => ({
  color: theme.palette.white,
  fontWeight: 700,
  lineHeight: 1,
  fontFamily: "VolkartRegular",
  textTransform: "none",
  zIndex: 1,
  ...(size === "default" && {
    fontSize: "17px",
    padding: "20px",
    letterSpacing: 0.2
  }),
  ...(size === "small" && {
    padding: "15px",
    fontSize: "14px",
    height: "32px",
    letterSpacing: 0.1
  }),
  "&:after": {
    backgroundColor: theme.palette.black,
    zIndex: -1,
    content: '""',
    position: "absolute",
    borderRadius: 4,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: "skewX(-5deg)",
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 8%), 0 0 4px 0 rgb(0 0 0 / 20%)"
  },
  "&:hover:after": {
    backgroundColor: theme.palette.gray[400]
  },
  "&:active": {
    backgroundColor: theme.palette.gray[400],
    boxShadow: "none"
  },
  "&:disabled": {
    "&:after": {
      boxShadow: "none",
      backgroundColor: theme.palette.gray[100]
    }
  },
  ...(bgColor === "primary" && {
    backgroundColor: theme.palette.blue[500],
    transform: "skewX(-5deg)",
    "&:after": {
      backgroundColor: theme.palette.blue[500]
    },
    "&:hover": {
      backgroundColor: theme.palette.blue[400]
    },
    "&:active": {
      backgroundColor: theme.palette.blue[400]
    }
  })
}));
