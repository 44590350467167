import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDnsRecords } from "core/store/entities/Domain/domain.actions";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";

export const useDnsRecords = () => {
  const { domainId } = useParams();
  const dispatch = useDispatch();
  const dnsRecords = useSelector(domainSelectors.dnsRecords);

  useEffect(() => {
    dispatch(getDnsRecords(domainId));
  }, []);

  const recordWidth = {
    type: "7%",
    name: "25%",
    value: "55%",
    priority: "15%"
  };

  const headers = [
    {
      width: recordWidth["type"],
      title: "Type"
    },
    {
      width: recordWidth["name"],
      title: "Name"
    },
    {
      width: recordWidth["value"],
      title: "Value"
    },
    {
      width: recordWidth["priority"],
      title: "Priority"
    }
  ];

  return { dnsRecords, headers, recordWidth };
};
