import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Button, Dialog, Input, Select } from "ui/components";
import { Controller } from "react-hook-form";
import { useCustomerSupportForm } from "./useCustomerSupportForm";

export const CustomerSupport = () => {
  const { show, themes, errors, control, handleSubmit, onSubmit, handleClose } =
    useCustomerSupportForm();

  return (
    <Dialog open={show} onClose={handleClose} paperStyles={{ width: "500px" }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} maxWidth="100%">
        <Stack sx={{ padding: { xs: "0 30px 25px", sm: "0 50px 25px" } }} spacing={1}>
          <Typography variant="bodyL">Choose your subject</Typography>
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <Select
                name="subject"
                fullWidth
                options={themes}
                label="Subject"
                placeholder="Select subject"
                error={errors.subject}
                helperText={errors.subject?.[0]}
                {...field}
              />
            )}
          />
        </Stack>
        <Divider sx={{ opacity: "16%" }} />
        <Stack sx={{ padding: { xs: "25px 30px", sm: "25px 50px" } }}>
          <Typography variant="bodyL" mb={2}>
            Send us a message
          </Typography>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <Input
                multiline
                name="text"
                rows={6}
                label="Your message"
                placeholder="Your message"
                error={errors.text}
                helperText={errors.text?.[0]}
                {...field}
              />
            )}
          />

          <Button sx={{ mt: 3 }} type="submit">
            Send message
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
