import { Box, styled } from "@mui/material";

export const StyledUpgradePlanBox = styled(Box)(({ isSelected, isError, theme }) => ({
  width: "100%",
  minWidth: "222px",
  height: "240px",
  borderRadius: theme.shape.borderRadius,
  padding: "15px 20px 20px 25px",
  border: "1px solid #D6D6D6",
  position: "relative",
  ...(isSelected && { outline: `4px solid ${theme.palette.blue[500]}` }),
  ...(isError && { border: `1px solid ${theme.palette.error.main}` })
}));

export const StyledCustomPlanBox = styled(StyledUpgradePlanBox)(({ theme }) => ({
  width: "100%",
  height: "160px",
  [theme.breakpoints.down("sm")]: {
    height: "100%"
  }
}));
