export const messagesReducers = {
  showMessageModal: (state, { payload = {} }) => {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value;
    });
    state.showMessageModal = true;
    state.modalErrors = [];
  },
  hideMessageModal: state => {
    state.modalTitle = "";
    state.subject = "";
    state.onSuccessCallback = () => {};
    state.showMessageModal = false;
  },

  fetchMessageThemes: () => {},
  fetchMessageThemesSuccess: (state, { payload }) => {
    let mappedThemes = [];
    payload.map(theme => {
      mappedThemes.push({ label: theme.subject, value: theme.id, tag: theme.tag });
    });
    state.themes = mappedThemes;
  },
  fetchMessageThemesFailed: (state, { payload }) => {
    state.errors = payload;
  },
  sendMessage: state => {
    state.customerSupportSent = false;
    state.modalErrors = [];
  },
  sendMessageSuccess: state => {
    state.customerSupportSent = true;
    state.showMessageModal = false;
    state.modalErrors = [];
  },
  sendMessageFailed: (state, { payload }) => {
    state.modalErrors = payload;
  }
};
