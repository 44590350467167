import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addPaymentMethod,
  addPaymentMethodFailed
} from "core/store/entities/Billing/billing.actions";
import { billingPaymentMethodsSelectors } from "core/store/entities/Billing/billing.selectors";

const schema = yup.object({
  cardholderName: yup.string().required("This field is required")
});

export const useAddCreditCardForm = () => {
  const isAddingLoading = useSelector(billingPaymentMethodsSelectors.isAdding);
  const isMethodsExist = useSelector(billingPaymentMethodsSelectors.exists);
  const addPaymentMethodError = useSelector(billingPaymentMethodsSelectors.addError);
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async data => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: data.cardholderName
      }
    });
    if (payload?.error) {
      dispatch(addPaymentMethodFailed(payload.error));
    } else {
      dispatch(addPaymentMethod({ token: payload.paymentMethod.id, selected: !isMethodsExist }));
    }
  };
  return {
    stripe,
    control,
    handleSubmit,
    onSubmit,
    errors,
    addPaymentMethodError,
    isAddingLoading
  };
};
