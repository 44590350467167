import React from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { Button, Image, Input, Link } from "ui/components";
import { Controller } from "react-hook-form";
import { LOGIN_SCREEN } from "core/constants";
import { useResetPassword } from "./useResetPassword";

export const ResetPassword = () => {
  const { control, handleSubmit, onSubmit, errors } = useResetPassword();

  return (
    <Grid container alignItems="center" justifyContent="center" minHeight="100vh">
      <Grid
        item
        xs={8}
        md={5}
        lg={4}
        xl={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image name="inboxroadIcon" height="35px" />
        <Grid
          container
          rowSpacing={3}
          justifyContent="center"
          pl="22px"
          mt={5}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="headlineM">Reset your password</Typography>
          {!!errors?.nonFieldErrors && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors?.nonFieldErrors}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth sx={{ mb: 2 }}>
              Send reset instructions
            </Button>
          </Grid>
        </Grid>
        <Grid item xs textAlign="center">
          <Typography variant="bodyS">
            Remember your password? <Link to={LOGIN_SCREEN}>Log in here</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
