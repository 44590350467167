export const paletteTheme = {
  white: "#ffffff",
  black: "#000000",
  error: {
    main: "#E25081"
  },
  gray: {
    50: "#F5F5F5",
    100: "#DBDBDB",
    200: "#D6D6D6",
    300: "rgba(255, 255, 255, 0.2)",
    400: "#1f2937"
  },
  pink: {
    50: "#ECD5D6",
    100: "#F2E4E3",
    200: "#F8C9CC",
    300: "#F5B7BB",
    400: "#EE8197",
    500: "#E25081",
    600: "#512439"
  },
  purple: {
    50: "#512439",
    100: "#EEE3EB",
    200: "#ECCAE3",
    300: "#E7BBE4",
    400: "#D28ED7",
    500: "#D28ED7"
  },
  green: {
    50: "#C9DEDB",
    100: "#DFECEB",
    200: "#BFE8E3",
    300: "#A4DFD4",
    400: "#64C9A7",
    500: "#3DAE79",
    600: "#273F31"
  },
  yellow: {
    50: "#ECECD5",
    100: "#F8F8C9",
    200: "#F0F08F",
    300: "#E9D559"
  },
  blue: {
    50: "#DAE0F1",
    100: "#E5E8F5",
    200: "#CEDBFD",
    300: "#BAD0FC",
    400: "#76B3F9",
    500: "#0B97F4",
    600: "#1D414E"
  }
};
