import { StyledFilterSelect } from "./styled";
import { MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

export const FilterSelect = ({ options, value, ...props }) => {
  return (
    <StyledFilterSelect value={value} IconComponent={KeyboardArrowDown} {...props}>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <Typography variant="bodySBold">{option.label}</Typography>
        </MenuItem>
      ))}
    </StyledFilterSelect>
  );
};
