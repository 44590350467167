import {
  FormHelperText,
  InputLabel,
  Select as SelectMUI,
  outlinedInputClasses,
  selectClasses,
  styled
} from "@mui/material";

export const StyledSelect = styled(SelectMUI)(({ theme }) => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
  [`&.Mui-focused.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.error.main,
    borderWidth: 2
  },
  [`&.Mui-focused `]: {
    color: theme.palette.black
  },

  [`& .${selectClasses.icon}`]: {
    color: theme.palette.black
  }
}));

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  fontFamily: "VolkartRegular",
  fontSize: 14,
  lineHeight: "14px",
  marginLeft: 0,
  marginTop: 4
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.Mui-focused": {
    color: theme.palette.black
  },
  [`&.Mui-focused.Mui-error `]: {
    color: theme.palette.error.main
  }
}));
