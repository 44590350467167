import { call, put, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  addPaymentMethod,
  addPaymentMethodFailed,
  addPaymentMethodSuccess,
  getPaymentMethods
} from "../billing.actions";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { ADD_CREDIT_CARD_MODAL } from "core/constants";

function* handleAddPaymentMethod({ payload }) {
  try {
    yield call(BillingService.addPaymentMethod, payload);

    yield put(addPaymentMethodSuccess());
    yield put(getPaymentMethods());
    yield put(hideModal(ADD_CREDIT_CARD_MODAL));
  } catch (error) {
    yield put(addPaymentMethodFailed(error));
  }
}

export default function* watchAddPaymentMethod() {
  yield takeEvery(addPaymentMethod.type, handleAddPaymentMethod);
}
