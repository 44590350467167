import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getBillingData,
  getInvoices,
  getPaymentMethods,
  getSubscriptionInfo
} from "core/store/entities/Billing/billing.actions";

export const useBilling = () => {
  const profile = useSelector(state => state.user?.selectedProfile?.customerprofile_token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoices({ fromStart: true }));
    dispatch(getPaymentMethods());
    dispatch(getBillingData());
    dispatch(getSubscriptionInfo());
  }, [profile]);
};
