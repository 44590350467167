import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { numberWithDots } from "helpers";
import { DiffStatus } from "ui/components";
import { diffPercentage, formatPercentage } from "helpers/percentage";
import React, { useMemo } from "react";
import { getLongestNumber, getLongestPercentageValue } from "helpers/number";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useISPTable = () => {
  const isIspTableLoading = useSelector(statisticsSelectors.isIspTableLoading);
  const isDomainsOptionsLoading = useSelector(statisticsSelectors.isDomainsOptionsLoading);
  const ispTable = useSelector(statisticsSelectors.ispTable);

  const headers = [
    {
      name: "ISP's",
      align: "left"
    },
    {
      name: "Sent",
      align: "left"
    },
    {
      name: "Delivery rate",
      align: "left"
    },
    {
      name: "Advice",
      align: "left"
    }
  ];

  const longestRecipientsValue = useMemo(
    () => getLongestNumber(ispTable.slice(0, 10), "recipients"),
    [ispTable]
  );

  const longestDeliveryRatePercentage = useMemo(
    () => getLongestPercentageValue(ispTable.slice(0, 10), "recipients", "percentage"),
    [ispTable]
  );

  const rows = ispTable.slice(0, 10).map(isp => ({
    key: isp.domain,
    columns: [
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.domain}</Typography>
      },
      {
        align: "left",
        content: (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="bodyM" width={longestRecipientsValue * 10} align="right">
              {numberWithDots(isp.recipients)}
            </Typography>
            <DiffStatus diff={diffPercentage(isp.recipients_old, isp.recipients)} />
          </Stack>
        )
      },
      {
        align: "left",
        content: (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="bodyM" width={longestDeliveryRatePercentage * 10} align="right">
              {isp.recipients ? formatPercentage(100 - isp.percentage) : 0}%
            </Typography>
            <DiffStatus diff={isp.delivery_growth} />
          </Stack>
        )
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.advice}</Typography>
      }
    ]
  }));

  return {
    isLoading: isIspTableLoading || isDomainsOptionsLoading,
    headers,
    rows
  };
};
