import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { numberWithDots } from "helpers";
import React from "react";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useBouncesTable = () => {
  const isTopBouncesTableLoading = useSelector(statisticsSelectors.isTopBouncesTableLoading);
  const isDomainsOptionsLoading = useSelector(statisticsSelectors.isDomainsOptionsLoading);
  const topBouncesTable = useSelector(statisticsSelectors.topBouncesTable);

  const headers = [
    {
      name: "ISP's",
      align: "left"
    },
    {
      name: "Sent",
      align: "left"
    },
    {
      name: "Bounced",
      align: "left"
    },
    {
      name: "Bounce rate",
      align: "left"
    },
    {
      name: "Advice",
      align: "left"
    },
    {
      name: "Most common bounce category",
      align: "left"
    }
  ];

  const rows = topBouncesTable.slice(0, 10).map(isp => ({
    key: isp.domain,
    columns: [
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.domain}</Typography>
      },
      {
        align: "left",
        content: (
          <Typography variant="bodyM" align="right">
            {numberWithDots(isp.recipients)}
          </Typography>
        )
      },
      {
        align: "left",
        content: (
          <Typography variant="bodyM" align="right">
            {numberWithDots(isp.total_bounced)}
          </Typography>
        )
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.percentage}%</Typography>
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.advice}</Typography>
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{isp.top_dsn_code}</Typography>
      }
    ]
  }));

  return {
    isLoading: isTopBouncesTableLoading || isDomainsOptionsLoading,
    headers,
    rows
  };
};
