import { useState } from "react";

import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { Check, ExpandLess, ExpandMore } from "@mui/icons-material";

import { SetupStepCollapse, StepButton, StepContainer } from "./styled";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const SetupStepContainer = ({ children, stepNumber, text }) => {
  const user = useSelector(userSelectors.user);
  const [open, setOpen] = useState(stepNumber === user.currentStep);

  const onExpandClick = () => {
    setOpen(!open);
  };

  const disabled = stepNumber < user.currentStep;

  return (
    <StepContainer disabled={disabled}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1.5} alignItems="center">
          <StepButton>
            {disabled ? (
              <SvgIcon sx={{ color: "white", fontSize: "1rem" }}>
                <Check />
              </SvgIcon>
            ) : (
              stepNumber
            )}
          </StepButton>
          <Typography variant="bodyLBolder">{text}</Typography>
        </Stack>
        {!disabled && (
          <IconButton
            sx={{ color: "black" }}
            onClick={stepNumber === user.currentStep ? onExpandClick : null}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Stack>
      <SetupStepCollapse in={open} timeout={50} unmountOnExit>
        {children}
      </SetupStepCollapse>
    </StepContainer>
  );
};
