import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useUpgradePlan } from "hooks";
import { upgradePlanSelectors } from "core/store/entities/UpgradePlan/upgradePlan.selectors";
import { upgradePlanActions } from "core/store/entities/UpgradePlan/upgradePlan.slice";
import { userSelectors } from "core/store/entities/User/user.selectors";

const schema = yup.object({
  selectedPlan: yup.string().required("This field is required"),
  selectedCustomPlan: yup.string(),
  username: yup.string().trim().required("This field is required"),
  email: yup.string().email("Invalid email").required("This field is required"),
  country: yup.string().required("This field is required")
});

export const useUpgradePlanForm = () => {
  const dispatch = useDispatch();
  const show = useSelector(upgradePlanSelectors.showUpgradePlanModal);
  const upgradePlans = useSelector(upgradePlanSelectors.upgradePlans);
  const customPlans = useSelector(upgradePlanSelectors.customPlans);
  const countries = useSelector(upgradePlanSelectors.countries);
  const topCountries = useSelector(upgradePlanSelectors.topCountries);
  const sendUpgradePlanSuccess = useSelector(upgradePlanSelectors.sendUpgradePlanSuccess);
  const sendUpgradePlanError = useSelector(upgradePlanSelectors.sendUpgradePlanError);
  const user = useSelector(userSelectors.user);
  const { closeUpgradePlan } = useUpgradePlan();

  useEffect(() => {
    if (sendUpgradePlanSuccess) {
      handleResetForm();
    }
  }, [sendUpgradePlanSuccess]);

  const { control, handleSubmit, reset, setValue, formState, watch, resetField } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: user.fullName?.trim(),
      email: user.email
    }
  });

  const handleResetForm = () => {
    reset();
  };

  const onSubmit = data => {
    dispatch(upgradePlanActions.sendUpgradePlan(data));
  };

  const handleClose = () => {
    closeUpgradePlan();
    handleResetForm();
  };

  return {
    show,
    control,
    formState,
    upgradePlans,
    customPlans,
    countries,
    topCountries,
    sendUpgradePlanError,
    onSubmit,
    handleSubmit,
    handleClose,
    watch,
    setValue,
    resetField
  };
};
