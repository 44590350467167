import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";

export const MenuIcon = ({ onClick, sx }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: theme => theme.palette.white,
        ...sx
      }}
    >
      <Menu sx={{ fontSize: "32px" }} />
    </IconButton>
  );
};
