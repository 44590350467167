import { useCustomerSupport } from "hooks";
import { ASK_NEW_SMTP_PASSWORD } from "core/constants";
import { useDispatch, useSelector } from "react-redux";
import { getSmtpInfo } from "core/store/entities/User/user.actions";
import { useEffect } from "react";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const useSmtpDetails = () => {
  const dispatch = useDispatch();
  const { openCustomerSupport } = useCustomerSupport(ASK_NEW_SMTP_PASSWORD);
  const smtpInfo = useSelector(userSelectors.smtpInfo);
  const isSmtpInfoLoading = useSelector(userSelectors.isSmtpInfoLoading);
  const passwordFooter = {
    text: "Ask for a new SMTP password",
    onClick: openCustomerSupport
  };

  useEffect(() => {
    dispatch(getSmtpInfo());
  }, []);

  return { smtpInfo, isSmtpInfoLoading, passwordFooter };
};
