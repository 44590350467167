import { StyledFormHelperText, StyledInputLabel, StyledSelect } from "./styled";
import { FormControl, MenuItem } from "@mui/material";

export const Select = ({
  options = [],
  value,
  label,
  error,
  helperText,
  fullWidth,
  children,
  ...props
}) => {
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect label={label} value={value} {...props}>
        {children ||
          options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </StyledSelect>
      {error && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </FormControl>
  );
};
