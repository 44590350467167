import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  downloadLogs,
  downloadLogsFailed,
  downloadLogsSuccess,
  fetchDeliveriesAndBounces,
  fetchDeliveriesAndBouncesFailed,
  fetchDeliveriesAndBouncesSuccess,
  fetchDomainsList,
  fetchDomainsListFailed,
  fetchDomainsListSuccess,
  fetchGlobalStats,
  fetchGlobalStatsFailed,
  fetchGlobalStatsPerDomain,
  fetchGlobalStatsPerDomainFailed,
  fetchGlobalStatsPerDomainSuccess,
  fetchGlobalStatsSuccess,
  fetchPerDomainStatsTable,
  fetchPerDomainStatsTableFailed,
  fetchPerDomainStatsTableSuccess,
  filterDetailsChange,
  selectDomain
} from "./statistics.actions";
import { getSendingDomains } from "../Domain/domain.actions";

import { DASHBOARD_STATISTICS_SCREEN } from "core/constants";
import { DomainService, StatisticsService } from "core/api";
import { push } from "redux-first-history";
import { statisticsSelectors } from "./statistics.selectors";
import { downloadFile } from "helpers/downloadFile";
import { getCurrentDate } from "helpers/date";

//TODO: rework selector
const getDomainId = state => state.statistics.selectedDomainId;

const getRangeFromDays = daysCount => {
  switch (daysCount) {
    case 0:
      return "today";
    case 1:
      return "yesterday";
    case 3:
      return "three_days";
    case 14:
      return "two_weeks";
    case 30:
      return "last_month";
    default:
      return "last_week";
  }
};

function* fetchGlobalStatsHandler({ payload }) {
  const { startDate: lastDays } = payload;
  const startDate = getCurrentDate();
  const range = getRangeFromDays(lastDays);

  try {
    const globalStats = yield call(StatisticsService.getGlobalStats, {
      date: startDate,
      range
    });
    yield put(fetchGlobalStatsSuccess(globalStats));
  } catch (error) {
    yield put(fetchGlobalStatsFailed(error));
  }
}

function* fetchGlobalStatsTableHandler({ payload }) {
  const { startDate: lastDays } = payload;
  const startDate = getCurrentDate();
  const range = getRangeFromDays(lastDays);

  try {
    const { results = [] } = yield call(StatisticsService.getDomainsStatsTable, {
      date: startDate,
      range
    });

    if (!results.length) {
      yield put(getSendingDomains());
    }

    yield put(fetchPerDomainStatsTableSuccess(results));
  } catch (error) {
    yield put(fetchPerDomainStatsTableFailed(error));
  }
}

function* fetchDomainsListHandler(payload) {
  const lastDays = payload;
  const range = getRangeFromDays(lastDays);

  let domains = [];
  try {
    domains = yield call(StatisticsService.getDomainsStatsTable, {
      date: getCurrentDate(),
      range
    });

    if (!domains.results.length) {
      domains = yield call(DomainService.get);
    }

    if (domains.results.length) {
      yield put(fetchDomainsListSuccess(domains.results));

      const selectedDomainId = yield select(statisticsSelectors.selectedDomainId);

      selectedDomainId
        ? yield put(push(`${DASHBOARD_STATISTICS_SCREEN}/${selectedDomainId}`))
        : yield put(push(`${DASHBOARD_STATISTICS_SCREEN}/${domains.results[0].domain_id}`));

      const domainId = yield select(getDomainId);
      const startDate = yield select(statisticsSelectors.filterDetailsValue);

      yield put(fetchGlobalStatsPerDomain({ startDate: 7, domainId }));
      yield put(fetchDeliveriesAndBounces({ startDate, domainId }));
    } else {
      yield put(fetchDomainsListFailed("Domains list is empty"));
    }
  } catch (error) {
    yield put(fetchDomainsListFailed(error));
  }
}

function* selectDomainHandler() {
  const domainId = yield select(getDomainId);
  const startDate = yield select(statisticsSelectors.filterDetailsValue);

  yield put(fetchGlobalStatsPerDomain({ startDate: 7, domainId }));
  yield put(fetchDeliveriesAndBounces({ startDate, domainId }));
}

function* filterDetailsChangeHandler() {
  const domainId = yield select(getDomainId);
  const startDate = yield select(statisticsSelectors.filterDetailsValue);

  yield put(fetchDeliveriesAndBounces({ startDate, domainId }));
  yield put(fetchGlobalStatsPerDomain({ startDate, domainId }));
}

function* fetchGlobalStatsPerDomainHandler({ payload }) {
  const { domainId, startDate: lastDays } = payload;
  const startDate = getCurrentDate();
  const range = getRangeFromDays(lastDays);

  try {
    const data = yield call(StatisticsService.getStatsPerDomain, {
      date: startDate,
      domainId,
      range
    });
    yield put(fetchGlobalStatsPerDomainSuccess(data));
  } catch (error) {
    yield put(fetchGlobalStatsPerDomainFailed(error));
  }
}

function* fetchDeliveriesAndBouncesHandler({ payload }) {
  const { domainId, startDate: lastDays } = payload;
  const startDate = getCurrentDate();
  const range = getRangeFromDays(lastDays);

  try {
    const data = yield call(StatisticsService.getStatsPerDomainTable, {
      date: startDate,
      domainId,
      range
    });
    yield put(fetchDeliveriesAndBouncesSuccess(data));
  } catch (error) {
    yield put(fetchDeliveriesAndBouncesFailed(error));
  }
}

export function* fetchDownloadLogsHandler({ payload }) {
  const { period } = payload;

  try {
    const { csv_url } = yield call(StatisticsService.downloadLogs, { period });
    downloadFile(csv_url);
    yield put(downloadLogsSuccess("Logs have been downloaded successfully!"));
  } catch (error) {
    yield put(downloadLogsFailed(error?.message || "Logs can't be downloaded. Please try again."));
  }
}

export default function* statisticsSaga() {
  yield takeLatest(fetchGlobalStats.type, fetchGlobalStatsHandler);
  yield takeLatest(fetchPerDomainStatsTable.type, fetchGlobalStatsTableHandler);
  yield takeLatest(selectDomain.type, selectDomainHandler);
  yield takeLatest(filterDetailsChange.type, filterDetailsChangeHandler);
  yield takeLatest(fetchGlobalStatsPerDomain.type, fetchGlobalStatsPerDomainHandler);
  yield takeLatest(fetchDomainsList.type, fetchDomainsListHandler);
  yield takeLatest(fetchDeliveriesAndBounces.type, fetchDeliveriesAndBouncesHandler);
  yield takeLatest(downloadLogs.type, fetchDownloadLogsHandler);
}
