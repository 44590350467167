import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";

import { Overall } from "./Statistics/Overall";
import { Domains } from "./Statistics/Domains";
import { Header, Image } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { SetupFlow } from "./SetupFlow";
import { SHOW_PAID_PLAN_BLOCK } from "core/constants";
import {
  fetchGlobalStats,
  fetchPerDomainStatsTable
} from "core/store/entities/Statistics/statistics.actions";
import { PaidPlanBlock } from "./PaidPlanBlock";
import { WelcomeBlock } from "./WelcomeBlock";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const Dashboard = () => {
  const filterValue = useSelector(statisticsSelectors.filterValue);
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.user);
  const showPaidPlanBlock = useSelector(modalSelectors[SHOW_PAID_PLAN_BLOCK]);

  const onProfileSelect = () => {
    dispatch(fetchGlobalStats({ startDate: filterValue }));
    dispatch(fetchPerDomainStatsTable({ startDate: filterValue }));
  };

  return (
    <Box>
      <Header title="Dashboard" onProfileSelect={onProfileSelect} />
      <Grid container sx={{ padding: { ...generalContentPadding, lg: "0 128px 65px" } }}>
        {showPaidPlanBlock && <PaidPlanBlock />}
        {user.showSetupFlow && (
          <>
            <WelcomeBlock />
            <Grid container item>
              <SetupFlow />
              <Grid item xs={4.0}>
                <Image
                  name="onboardingVisual"
                  width={358}
                  height={362}
                  customStyles={{ marginBottom: "10px", marginLeft: "12px" }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {user.showStats && (
          <>
            <Overall />
            <Domains />
          </>
        )}
      </Grid>
    </Box>
  );
};
