import { Select, outlinedInputClasses, selectClasses, styled } from "@mui/material";

export const StyledFilterSelect = styled(Select)(({ theme }) => ({
  "&": {
    height: "32px"
  },
  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
  [`& .${selectClasses.select}`]: {
    padding: "10px"
  },
  [`& .${selectClasses.iconOutlined}`]: {
    color: theme.palette.black,
    fontSize: "1.3rem"
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    boxShadow:
      "0px 1px 0px 0 rgb(0 0 0 / 12%), 0px 1px 2px 0px rgb(0 0 0 / 16%), 0px 0px 3px 0px rgb(0 0 0 / 12%)"
  },
  legend: {
    width: "inherit"
  }
}));
