import { useDispatch, useSelector } from "react-redux";
import { ADD_CREDIT_CARD_MODAL } from "core/constants";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";

export const useAddCreditCardModal = () => {
  const dispatch = useDispatch();
  const isAddCreditCardModalOpen = useSelector(modalSelectors[ADD_CREDIT_CARD_MODAL]);

  const onModalClose = () => {
    dispatch(hideModal(ADD_CREDIT_CARD_MODAL));
  };

  return { isAddCreditCardModalOpen, onModalClose };
};
