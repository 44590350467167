import { TableHead, TableRow, Typography } from "@mui/material";
import { TableHeaderCell } from "../../styled";
import React from "react";

export const TableHeader = ({ headers = [] }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <TableHeaderCell
            key={index}
            align={header.align}
            customStyles={header.customStyles}
            sx={{ paddingRight: "10px" }}
          >
            <Typography variant="bodyMBold">{header.name}</Typography>
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
