import { Button, Dialog, Input } from "ui/components";
import { Alert, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEditBillingInformation } from "./useEditBillingInformation";
import { Controller } from "react-hook-form";

export const EditBillingInformationModal = () => {
  const { control, handleSubmit, onSubmit, onClose, isEditBillingInfoModalOpen, errors } =
    useEditBillingInformation();

  return (
    <Dialog open={isEditBillingInfoModalOpen} onClose={onClose} paperStyles={{ width: "476px" }}>
      <Stack spacing={0.5} p="0 48px 48px 28px">
        <Grid
          container
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          maxWidth="100%"
          spacing={2.5}
          ml={0}
        >
          <Grid item xs={12}>
            <Typography variant="bodyL">Change billing address</Typography>
          </Grid>
          {!!errors?.nonFieldErrors && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errors?.nonFieldErrors}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="companyName"
                  label="Company name"
                  name="companyName"
                  error={!!errors?.companyName?.message}
                  helperText={errors?.companyName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="phone"
                  label="Phone number"
                  name="phone"
                  error={!!errors?.phone?.message}
                  helperText={errors?.phone?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="firstAddressLine"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="firstAddressLine"
                  label="Address"
                  name="firstAddressLine"
                  error={!!errors?.firstAddressLine?.message}
                  helperText={errors?.firstAddressLine?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="secondAddressLine"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="secondAddressLine"
                  label="Address (line 2)"
                  name="secondAddressLine"
                  error={!!errors?.secondAddressLine?.message}
                  helperText={errors?.secondAddressLine?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="postalCode"
                  label="Zip code"
                  name="postalCode"
                  error={!!errors?.postalCode?.message}
                  helperText={errors?.postalCode?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  error={!!errors?.city?.message}
                  helperText={errors?.city?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  error={!!errors?.country?.message}
                  helperText={errors?.country?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  fullWidth
                  id="state"
                  label="State"
                  name="state"
                  error={!!errors?.state?.message}
                  helperText={errors?.state?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button sx={{ mt: 1 }} type="submit" fullWidth>
              Change billing address
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
