import React from "react";
import { Table } from "ui/components";
import { useDomainTable } from "./useDomainTable";
import { useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

const DomainTable = () => {
  const isStatsPerDomainTableLoading = useSelector(
    statisticsSelectors.isStatsPerDomainTableLoading
  );
  const { headers, rows } = useDomainTable();

  return <Table headers={headers} data={rows} isLoading={isStatsPerDomainTableLoading} />;
};

export default DomainTable;
