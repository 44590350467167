import React from "react";
import { Box, Table as TableMUI } from "@mui/material";
import { TableBody, TableBodyEmpty, TableHeader } from "./components";

export const Table = ({ headers, data = [], emptyText }) => {
  return (
    <Box sx={{ overflow: "auto", width: "100%" }}>
      <TableMUI sx={{ minWidth: "max-content" }}>
        <TableHeader headers={headers} />
        {data.length ? (
          <TableBody data={data} />
        ) : (
          <TableBodyEmpty colSpan={headers.length} emptyText={emptyText} />
        )}
      </TableMUI>
    </Box>
  );
};
