import { Link as MUILink, styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const baseLinkStyles = ({ theme, color }) => ({
  color: color || theme.palette.black,
  textDecorationColor: color || theme.palette.black,
  textDecorationSkipInk: "none",
  "&:hover": {
    color: color || theme.palette.black
  }
});

export const StyledRouterLink = styled(RouterLink)(baseLinkStyles);
export const StyledMUILink = styled(MUILink)(baseLinkStyles);
