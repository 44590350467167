import { messagesActions } from "./messages.slice";

export const {
  fetchMessages,
  fetchMessagesSuccess,
  fetchMessagesFailed,

  fetchMessage,
  fetchMessageSuccess,
  fetchMessageFailed,

  fetchUnreadMessagesCount,
  fetchUnreadMessagesCountSuccess,
  fetchUnreadMessagesCountFailed,

  getNotification,
  getNotificationSuccess,
  notificationShow,
  notificationHide,

  showMessageModal,
  hideMessageModal,
  fetchMessageThemes,
  fetchMessageThemesSuccess,
  fetchMessageThemesFailed,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailed,

  clearErrorMessage,
  logoutSuccess
} = messagesActions;
