import React from "react";
import { Stack, Typography } from "@mui/material";
import { Spinner } from "ui/components";

import { DeliveriesChart } from "./DeliveriesChart";
import { useDeliveriesBounces } from "./useDeliveriesBounces";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const DeliveriesBounces = () => {
  const { isLoading, deliveriesAndBounces } = useDeliveriesBounces();
  const { currentPeriodText } = useCurrentPeriodText();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="end" spacing={2}>
          <Typography variant="sectionHeaderS">Deliveries & bounces</Typography>
          <Typography variant="bodyXS">{currentPeriodText}</Typography>
        </Stack>
      </Stack>
      {isLoading ? <Spinner /> : <DeliveriesChart data={deliveriesAndBounces} />}
    </Stack>
  );
};
