import { Grid, Stack } from "@mui/material";
import { formatPercentage } from "helpers/percentage";
import React from "react";
import { OverallContainer } from "./styled";
import { Divider, Indicator } from "ui/components";

export const OverallBlock = ({
  totalSent,
  totalSentDiff,
  percentDelivered,
  percentDeliveredDiff,
  percentBounced,
  totalBouncedDiff
}) => {
  return (
    <OverallContainer container gap={{ xs: 2, lg: 0 }} alignItems="center">
      <Grid item xs={12} md={4} lg={4}>
        <Indicator value={totalSent} label="Sent volume" diff={totalSentDiff} />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
          <Divider flexItem orientation="vertical" sx={{ display: { xs: "none", lg: "block" } }} />
          <Indicator
            value={`${formatPercentage(percentDelivered)}%`}
            label="Delivery rate"
            diff={percentDeliveredDiff}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Stack spacing={{ xs: 0, lg: 4 }} direction="row">
          <Divider
            flexItem
            orientation="vertical"
            sx={{ display: { xs: "none", lg: "block" }, height: "auto !important" }}
          />
          <Indicator
            inverted
            value={`${formatPercentage(percentBounced)}%`}
            label="Bounce rate"
            diff={totalBouncedDiff}
          />
        </Stack>
      </Grid>
    </OverallContainer>
  );
};
