import React from "react";
import { Link } from "react-router-dom";

import { isAuthenticated } from "helpers/checkAuth";
import styles from "./styles.module.scss";
import { DASHBOARD_SCREEN, LOGIN_SCREEN } from "core/constants";

export const PageNotFound = () => {
  return (
    <div className={`container-fluid ${styles["page-container"]}`}>
      <div className="d-flex justify-content-center flex-wrap">
        <span className={styles.error}>404</span>
        <h1 className={styles.title}>Page Not Found</h1>
        <p>
          Return to{" "}
          {isAuthenticated() ? (
            <Link to={DASHBOARD_SCREEN}>Dashboard</Link>
          ) : (
            <Link to={LOGIN_SCREEN}>Login</Link>
          )}
        </p>
      </div>
    </div>
  );
};
