import { Dialog } from "ui/components";
import { Stack } from "@mui/material";
import React from "react";
import { useAddCreditCardModal } from "./useAddCreditCardModal";
import { AddCreditCardForm } from "../AddCreditCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export const AddCreditCardModal = () => {
  const { isAddCreditCardModalOpen, onModalClose } = useAddCreditCardModal();
  const token =
    window.location.hostname === "app.inboxroad.com"
      ? "pk_live_7gRSUrJ8p3MROpE8cM3ZndTO00miBZGL5E"
      : "pk_test_3g2CX0JdT2cPAynkDLNmekk600SV2O93zz";
  const stripePromise = loadStripe(token);

  return (
    <Dialog open={isAddCreditCardModalOpen} onClose={onModalClose} paperStyles={{ width: "476px" }}>
      <Stack spacing={0.5} p="0 48px 48px 28px">
        <Elements stripe={stripePromise}>
          <AddCreditCardForm />
        </Elements>
      </Stack>
    </Dialog>
  );
};
