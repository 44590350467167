import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import React from "react";
import {
  FeedbackPopup,
  FullscreenSpinner,
  Navbar,
  CustomerSupport,
  UpgradePlan,
  UpgradePlanConfirmation
} from "../.";
import { useUserInitialization, useDefineMobileView } from "hooks";
import CompressedNavbar from "../Navbar/CompressedNavbar";

export const generalHeaderPadding = {
  xs: "28px 7px 28px 5px",
  sm: "28px 25px",
  md: "28px 40px 28px 60px",
  lg: "28px 40px 28px 128px"
};

export const generalContentPadding = {
  xs: "28px 10px 60px",
  sm: "28px 25px",
  md: "28px 40px 28px 60px",
  lg: "28px 40px 40px 128px"
};

export const Layout = () => {
  const { user } = useUserInitialization();
  const isMobileView = useDefineMobileView();

  if (!user) return <FullscreenSpinner />;

  return (
    <Stack minHeight="100vh" flexDirection="row">
      {isMobileView ? <CompressedNavbar /> : <Navbar />}
      <Box
        sx={{
          width: "100%",
          maxHeight: "100vh",
          overflow: "auto"
        }}
      >
        <Outlet />
      </Box>
      <CustomerSupport />
      <UpgradePlan />
      <UpgradePlanConfirmation />
      <FeedbackPopup />
    </Stack>
  );
};
