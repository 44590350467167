import { Stack, Typography } from "@mui/material";
import { Chip } from "ui/components";
import { Check } from "@mui/icons-material";
import React from "react";

export const Status = ({ item }) => {
  const chipType = {
    Pending: "warning",
    Failed: "alert",
    Active: "success"
  };

  return (
    <Stack spacing={2} alignItems="flex-start">
      <Chip
        label={item.status}
        type={chipType[item.status]}
        icon={item.status === "Active" ? <Check /> : null}
      />
      {item.status_info && (
        <Typography
          minWidth={150}
          sx={{ maxWidth: { xs: "165px", md: "205px", lg: "36vw", xl: "100%" } }}
          variant="bodyS"
        >
          It may take up to 48 hours before DNS changes become active
        </Typography>
      )}
    </Stack>
  );
};
