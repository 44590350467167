import { Grid, Typography } from "@mui/material";

export const BillingSectionTitle = ({ title, subTitle }) => {
  return (
    <Grid container item lg={3.9} flexDirection="column">
      <Typography variant="bodyLBolder" fontSize={20}>
        {title}
      </Typography>
      <Typography variant="bodyM">{subTitle}</Typography>
    </Grid>
  );
};
