import { useCallback } from "react";

export const usePreventDefault = callback => {
  return useCallback(
    event => {
      event?.preventDefault();
      callback();
    },
    [callback]
  );
};
