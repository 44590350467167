import { Stack } from "@mui/material";
import { DnsRecord } from "./DnsRecord";
import { useDnsRecords } from "./useDnsRecords";

export const DnsRecords = () => {
  const { dnsRecords, headers, recordWidth } = useDnsRecords();

  return (
    <Stack spacing={2} minWidth={815}>
      {dnsRecords.map((record, index) => (
        <DnsRecord
          key={index}
          showHeaders={index === 0}
          headers={headers}
          recordWidth={recordWidth}
          record={record}
        />
      ))}
    </Stack>
  );
};
