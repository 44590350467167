const ENTITY = "messages";

export const messagesSelectors = {
  onSuccessCallback: state => state[ENTITY].onSuccessCallback,
  showMessageModal: state => state[ENTITY].showMessageModal,
  customerSupportSent: state => state[ENTITY].customerSupportSent,
  themes: state => state[ENTITY].themes,
  modalTitle: state => state[ENTITY].modalTitle,
  subject: state => state[ENTITY].subject,
  errors: state => state[ENTITY].errors,
  modalErrors: state => state[ENTITY].modalErrors
};
