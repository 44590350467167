import { domainReducers } from "./domain.reducers";

const { createSlice } = require("@reduxjs/toolkit");

const domainSlice = createSlice({
  name: "domain",
  initialState: {
    domains: [],
    domainsOptions: [],
    dnsRecords: [],
    isSendingDomainsLoading: false,
    errors: []
  },
  reducers: domainReducers
});

const domainActions = domainSlice.actions;
const domainReducer = domainSlice.reducer;

export { domainActions, domainReducer };
