import { formatUrl } from "helpers";

const getDomainOptions = domainsTable => {
  return domainsTable.map(domain => {
    return {
      label: formatUrl(domain?.orig_domain || domain?.domain_name),
      value: domain?.domain_id
    };
  });
};

const findDomainOption = (domainsOptions, selectedDomainId) => {
  return domainsOptions.filter(domain => {
    return domain.value == selectedDomainId;
  });
};

export const statisticsReducers = {
  // Global stats overall
  fetchGlobalStats: state => {
    state.isGlobalStatsLoading = true;
    state.error = "";
  },
  fetchGlobalStatsSuccess: (state, { payload }) => {
    state.isGlobalStatsLoading = false;
    state.error = "";
    state.errorMessage = "";
    state.globalStats = payload;
  },
  fetchGlobalStatsFailed: (state, { payload }) => {
    state.error = payload;
    state.globalStats = [];
    state.isGlobalStatsLoading = false;
  },
  // Global stats overall end

  // Global stats table
  fetchPerDomainStatsTable: state => {
    state.isStatsPerDomainTableLoading = true;
    state.statsPerDomainTable = [];
    state.error = "";
  },
  fetchPerDomainStatsTableSuccess: (state, { payload }) => {
    state.isStatsPerDomainTableLoading = false;
    state.statsPerDomainTable = payload;
  },
  fetchPerDomainStatsTableFailed: (state, { payload }) => {
    state.isStatsPerDomainTableLoading = false;
    state.statsPerDomainTable = [];
    state.error = payload;
  },
  // Global stats table end

  fetchDomainsList: (state, { payload }) => {
    state.selectedDomainId = payload;
    state.isDomainsOptionsLoading = true;
    state.error = "";
  },
  fetchDomainsListSuccess: (state, { payload }) => {
    const domainOptions = getDomainOptions(payload);
    let selectedDomain = findDomainOption(domainOptions, state.selectedDomainId);

    if (!selectedDomain.length) {
      selectedDomain = [domainOptions[0]];
    }

    state.domainsOptions = domainOptions;
    state.selectedDomain = selectedDomain;
    state.selectedDomainId = selectedDomain[0].value;
    state.isDomainsOptionsLoading = false;
  },
  fetchDomainsListFailed: (state, { payload }) => {
    state.error = payload;
    state.isDomainsOptionsLoading = false;
  },
  selectDomain: (state, { payload }) => {
    state.selectedDomainId = payload;
    state.selectedDomain = findDomainOption(state.domainsOptions, payload);
  },
  filterDetailsChange: (state, { payload }) => {
    state.filterDetailsValue = payload;
  },
  fetchGlobalStatsPerDomain: state => {
    state.isGlobalStatsPerDomainLoading = true;
    state.error = "";
  },
  fetchGlobalStatsPerDomainSuccess: (state, { payload }) => {
    state.globalStatsPerDomain = payload;
    state.isGlobalStatsPerDomainLoading = false;
  },
  fetchGlobalStatsPerDomainFailed: (state, { payload }) => {
    state.error = payload;
    state.isGlobalStatsPerDomainLoading = false;
  },
  fetchDeliveriesAndBounces: state => {
    state.isDeliveriesAndBouncesLoading = true;
    state.isIspTableLoading = true;
    state.isTopBouncesTableLoading = true;
    state.error = "";
  },
  fetchDeliveriesAndBouncesSuccess: (state, { payload }) => {
    const { chart_data = [], table = [], table_2 = [] } = payload;
    state.deliveriesAndBounces = chart_data;
    state.ispTable = table;
    state.topBouncesTable = table_2;
    state.isDeliveriesAndBouncesLoading = false;
    state.isIspTableLoading = false;
    state.isTopBouncesTableLoading = false;
  },
  fetchDeliveriesAndBouncesFailed: (state, { payload }) => {
    state.error = payload;
    state.isDeliveriesAndBouncesLoading = false;
    state.isIspTableLoading = false;
    state.isTopBouncesTableLoading = false;
  },
  setFilterValue: (state, { payload }) => {
    state.filterValue = payload;
  },
  downloadLogs: state => {
    state.isDownloadingLogs = true;
  },
  downloadLogsSuccess: (state, { payload }) => {
    state.isDownloadingLogs = false;
    state.downloadingLogsSuccess = payload;
  },
  downloadLogsFailed: (state, { payload }) => {
    state.isDownloadingLogs = false;
    state.downloadingLogsError = payload;
  },
  downloadLogsResetFeedback: state => {
    state.isDownloadingLogs = false;
    state.downloadingLogsError = "";
    state.downloadingLogsSuccess = "";
  },
  clearErrorMessage: state => {
    state.error = "";
  }
};
