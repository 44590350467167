import React from "react";
import { Stack, Typography } from "@mui/material";
import { ProfileMenu, Button } from "../.";
import { generalHeaderPadding } from "../Layout";
import { useUpgradePlan } from "hooks";

export const Header = ({ title, selectComponent, onProfileSelect, sx }) => {
  const { openUpgradePlan, currentStep } = useUpgradePlan();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: generalHeaderPadding,
        ...sx
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="headlineM" sx={{ fontSize: { xs: "26px", sm: "30px" } }}>
          {title}
        </Typography>
        {selectComponent}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1.5}
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
      >
        <Button
          size="small"
          onClick={openUpgradePlan}
          sx={{ marginBottom: { xs: "10px", sm: "0" } }}
          disabled={currentStep <= 2}
        >
          Upgrade
        </Button>
        <ProfileMenu onProfileSelect={onProfileSelect} />
      </Stack>
    </Stack>
  );
};
