import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSendingDomains } from "core/store/entities/Domain/domain.actions";
import { SendingDomain } from "./SendingDomain";
import { Status } from "./Status";
import { Delete } from "./Delete";
import { Link } from "ui/components";
import { SETUP_DOMAIN_SCREEN } from "core/constants";
import { useTheme } from "@mui/material";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";

export const useSendingDomainsTable = () => {
  const sendingDomains = useSelector(domainSelectors.domains);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(getSendingDomains());
  }, []);

  const headers = [
    {
      name: "Sending domain",
      align: "left"
    },
    {
      name: "Status",
      align: "left"
    },
    {},
    {
      name: ""
    }
  ];

  const rows = sendingDomains.map(item => ({
    key: item.domain_id,
    sx: {
      verticalAlign: item.status_info ? "top" : "middle"
    },
    columns: [
      {
        align: "left",
        content: <SendingDomain item={item} />
      },
      {
        align: "left",
        content: <Status item={item} />
      },
      {
        align: "center",
        content: (
          <Link to={`${SETUP_DOMAIN_SCREEN}/${item.domain_id}`} color={theme.palette.blue[500]}>
            DNS Settings
          </Link>
        )
      },
      {
        align: "right",
        content: <Delete item={item} />
      }
    ]
  }));

  return { headers, rows };
};
