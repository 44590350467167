import React from "react";
import {
  CHOOSE_NEW_PASSWORD_SCREEN,
  CREATE_ACCOUNT_SCREEN,
  LOGIN_SCREEN,
  RESET_PASSWORD_SCREEN
} from "core/constants";
import { ChooseNewPassword, CreateAccount, Login, ResetPassword } from "ui/screens";

export const unauthorizedRoutes = [
  {
    path: LOGIN_SCREEN,
    element: <Login />
  },
  {
    path: `${CREATE_ACCOUNT_SCREEN}/:plan`,
    element: <CreateAccount />
  },
  {
    path: RESET_PASSWORD_SCREEN,
    element: <ResetPassword />
  },
  {
    exact: true,
    path: `${CHOOSE_NEW_PASSWORD_SCREEN}/:id/:hash`,
    element: <ChooseNewPassword />
  }
];
