import { Stack } from "@mui/material";
import { Spinner } from "ui/components";
import React from "react";

export const FullscreenSpinner = ({ size = 50 }) => {
  return (
    <Stack height="100vh" justifyContent="center">
      <Spinner size={size} />
    </Stack>
  );
};
