import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { applyMiddleware, compose } from "redux";
import { routerMiddleware } from "../utils/history";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware];

const logger = createLogger({ collapsed: true });

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhacer = composeEnhancers(applyMiddleware(...middlewares));

export { sagaMiddleware, enhacer };
