import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser, logout, restoreProfile } from "core/store/entities/User/user.actions";
import { fetchMessageThemes } from "core/store/entities/Messages/messages.actions";
import { showPaidPlanBlock } from "core/store/entities/Modal/modal.actions";
import { ADD_DOMAIN_STEP, PROFILE_TOKEN } from "core/constants";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { useLocation } from "react-router-dom";

export const useUserInitialization = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.user);
  const profiles = useSelector(userSelectors.profiles);
  const location = useLocation();

  useEffect(() => {
    dispatch(restoreProfile());
    dispatch(fetchMessageThemes());
    localStorage.getItem(PROFILE_TOKEN) && dispatch(showPaidPlanBlock());
  }, []);

  useEffect(() => {
    dispatch(getUser());
  }, [location]);

  useEffect(() => {
    if (user?.currentStep === ADD_DOMAIN_STEP && !profiles?.length) {
      dispatch(logout());
    }
  }, [user]);

  return { user };
};
