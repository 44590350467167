import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOGIN_SCREEN } from "core/constants";
import { isAuthenticated } from "helpers/checkAuth";

export const PrivateRoute = () => {
  // TODO: create wrapper to unify and to remove from components calls "window" stored gtag function
  // window.gtag("event", "page_view", {
  //   page_path: props.path
  // });

  return isAuthenticated() ? <Outlet /> : <Navigate to={LOGIN_SCREEN} />;
};
