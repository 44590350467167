import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

export const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: "100%",
  height: 8,
  transform: "skewX(-15deg)",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D6D6D6",
    zIndex: -1
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "black"
  }
}));
