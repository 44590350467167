import { createStore } from "redux";
import { rootReducer } from "./reducers";
import { enhacer, sagaMiddleware } from "./middlewares";
import { createReduxHistory } from "./utils/history";
import rootSaga from "./sagas";

const setupStore = (preloadedState = {}) => {
  return createStore(rootReducer, preloadedState, enhacer);
};

const store = setupStore();

sagaMiddleware.run(rootSaga);

const history = createReduxHistory(store);

export { setupStore, store, history };
