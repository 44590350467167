import { useDispatch, useSelector } from "react-redux";
import { hideMessageModal, showMessageModal } from "core/store/entities/Messages/messages.actions";
import { DELETE_DOMAIN_THEME } from "core/constants";
import { messagesSelectors } from "core/store/entities/Messages/messages.selectors";

export const useCustomerSupport = (theme = "") => {
  const messageThemes = useSelector(messagesSelectors.themes);
  const dispatch = useDispatch();
  const openCustomerSupport = onSuccessCallback => {
    dispatch(
      showMessageModal({
        onSuccessCallback: theme === DELETE_DOMAIN_THEME ? onSuccessCallback : () => {},
        subject: messageThemes.find(messageTheme => messageTheme.tag === theme)
      })
    );
  };

  const closeCustomerSupport = () => {
    dispatch(hideMessageModal());
  };

  return { openCustomerSupport, closeCustomerSupport };
};
