import { formatDate } from "helpers/date";
import { format as formatNumber } from "helpers/number";

export class BillingMapper {
  static billingDataToState = data => {
    const {
      name,
      email,
      phone,
      address: { line1, line2, postal_code, city, country, state }
    } = data;

    return {
      companyName: name,
      email,
      phone,
      firstAddressLine: line1,
      secondAddressLine: line2,
      postalCode: postal_code,
      city,
      country,
      state
    };
  };
  static billingDataToRequest = billingData => {
    const {
      companyName,
      email,
      phone,
      firstAddressLine,
      secondAddressLine,
      postalCode,
      city,
      country,
      state
    } = billingData;

    return {
      name: companyName,
      email,
      phone,
      address: {
        line1: firstAddressLine,
        line2: secondAddressLine,
        postal_code: postalCode,
        city,
        country,
        state
      }
    };
  };

  static billingErrorsToState = errors => {
    const {
      address: { city = "", country = "", line1 = "", line2 = "", postal_code = "", state = "" },
      non_field_errors
    } = errors;
    return {
      city: { message: city },
      country: { message: country },
      firstAddressLine: { message: line1 },
      secondAddressLine: { message: line2 },
      postalCode: { message: postal_code },
      state: { message: state },
      nonFieldErrors: non_field_errors
    };
  };

  static subscriptionDataToState = subscription => {
    const {
      total_send,
      total_available,
      total_send_percentage,
      date_range: { start, end }
    } = subscription;
    return {
      totalSend: formatNumber(total_send, "."),
      totalAvailable: formatNumber(total_available, "."),
      totalSendPercentage: total_send_percentage,
      subscriptionStart: formatDate(start, "DD MMM YY"),
      subscriptionEnd: formatDate(end, "DD MMM YY")
    };
  };

  static paymentMethodsDataToState = paymentMethods => {
    return paymentMethods.map(({ id, name, exp_month, exp_year, number, brand, selected }) => ({
      id,
      name,
      expirationDate: `${exp_month}/${exp_year.slice(-2)}`,
      cardLastDigits: number,
      brand,
      isSelected: selected
    }));
  };
}
