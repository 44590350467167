import { ADMIN_TOKEN, PROFILES, PROFILE_TOKEN, SELECTED_PROFILE } from "core/constants";

export const userReducers = {
  createCustomerObject: () => {},
  createCustomerObjectSuccess: (state, { payload }) => {
    state.email = payload.email;
    state.createAccountStep = 2;
    state.errors = [];
  },
  createCustomerObjectFailed: (state, { payload }) => {
    state.errors = payload;
  },
  createCustomerProfile: () => {},
  createCustomerProfileSuccess: state => {
    state.errors = [];
  },
  createCustomerProfileFailed: (state, action) => {
    state.errors = action.payload;
  },
  fetchMailingSystems: state => {
    state.isMailingSystemsLoading = true;
  },
  fetchMailingSystemsSuccess: (state, { payload }) => {
    state.mailingSystemsOptions = payload;
    state.isMailingSystemsLoading = false;
  },
  fetchMailingSystemsFailed: (state, { payload }) => {
    state.errors = { payload };
    state.isMailingSystemsLoading = false;
  },
  clearErrorMessage: state => {
    state.errors = [];
  },
  login: state => {
    state.isLoginLoading = true;
  },
  loginSuccess: (state, { payload }) => {
    state.errors = [];
    state.profiles = payload;
    state.selectedProfile = payload[0] ? payload[0] : {};
    state.isAdminView = false;
    state.isLoginLoading = false;
  },
  loginFailed: (state, action) => {
    state.errors = action.payload;
    state.isLoginLoading = false;
  },
  loginAsAdmin: (state, { payload }) => {
    state.isLoginAsAdminLoading = true;
    state.adminToken = payload;
  },
  loginAsAdminSuccess: (state, { payload }) => {
    state.errors = [];
    state.profiles = payload;
    state.selectedProfile = payload ? (payload[0] ? payload[0] : null) : null;
    state.isLoginAsAdminLoading = false;
    state.isAdminView = true;
  },
  loginAsAdminFailed: (state, { payload }) => {
    state.isLoginAsAdminLoading = false;
    state.errorMessage = payload;
  },
  logoutAsAdmin: () => {},
  resetPassword: () => {},
  resetPasswordSuccess: state => {
    state.errors = [];
  },
  resetPasswordFailed: (state, { payload }) => {
    state.errorMessage = payload;
    state.errors = payload;
  },
  changePasswordOnReset: () => {},
  changePasswordOnResetSuccess: state => {
    state.errors = [];
  },
  changePasswordOnResetFailed: (state, { payload }) => {
    state.errors = payload;
  },
  changePassword: () => {},
  changePasswordSuccess: state => {
    state.errors = [];
  },
  changePasswordFailed: (state, { payload }) => {
    state.errors = payload;
  },
  getUser: () => {},
  getUserSuccess: (state, { payload }) => {
    state.user = payload;
  },
  getUserFailed: (state, { payload }) => {
    state.errors = payload;
  },
  editUser: () => {},
  editUserSuccess: (state, { payload: { company, firstName, lastName } }) => {
    state.user = {
      ...state.user,
      fullName: `${firstName} ${lastName}`,
      company,
      firstName,
      lastName
    };
    state.errors = [];
  },
  editUserFailed: (state, { payload }) => {
    state.errors = payload;
  },
  restoreProfile: state => {
    const profiles = JSON.parse(localStorage.getItem(PROFILES));
    const selectedProfile = JSON.parse(localStorage.getItem(SELECTED_PROFILE) || "{}");
    const adminToken = localStorage.getItem(ADMIN_TOKEN);
    let isAdminView;
    adminToken ? (isAdminView = true) : (isAdminView = false);

    state.profiles = profiles;
    state.selectedProfile = selectedProfile;
    state.adminToken = adminToken;
    state.isAdminView = isAdminView;
  },
  selectProfile: (state, action) => {
    localStorage.setItem(SELECTED_PROFILE, JSON.stringify(action.payload));
    localStorage.setItem(PROFILE_TOKEN, action.payload.customerprofile_token);
    state.selectedProfile = action.payload;
  },
  confirmEmail: () => {},
  confirmEmailSuccess: (state, { payload }) => {
    state.confirmEmailMessage = payload;
  },
  confirmEmailFailed: (state, { payload }) => {
    state.confirmEmailMessage = payload;
  },
  resendEmail: () => {},
  resendEmailSuccess: () => {},
  resendEmailFailed: () => {},
  showFeedbackPopup: (state, action) => {
    state.showFeedbackPopup = true;
    state.feedbackPopupContent = action.payload;
  },
  hideFeedbackPopup: state => {
    state.showFeedbackPopup = false;
    state.feedbackPopupContent = [];
  },
  getSmtpInfo: state => {
    state.isSmtpInfoLoading = true;
  },
  getSmtpInfoSuccess: (state, { payload }) => {
    state.smtpInfo = payload;
    state.isSmtpInfoLoading = false;
  },
  getSmtpInfoFailed: (state, { payload }) => {
    state.errors = payload;
    state.isSmtpInfoLoading = false;
  },
  getReturnPaths: state => {
    state.isReturnPathsLoading = true;
  },
  getReturnPathsSuccess: (state, { payload }) => {
    state.returnPaths = payload;
    state.isReturnPathsLoading = false;
  },
  getReturnPathsFailed: (state, { payload }) => {
    state.errors = payload;
    state.isReturnPathsLoading = false;
  },
  getWebInfo: state => {
    state.isWebInfoLoading = true;
  },
  getWebInfoSuccess: (state, { payload }) => {
    state.webInfo = payload;
    state.isWebInfoLoading = false;
  },
  getWebInfoFailed: (state, { payload }) => {
    state.errors = payload;
    state.isWebInfoLoading = false;
  },
  logout: () => {},
  logoutSuccess: () => {
    return {
      createAccountStep: 1,
      email: "",
      profiles: [],
      user: null,
      isLoading: false,
      selectedProfile: {},
      isLoginAsAdminLoading: false,
      isAdminView: false,
      adminToken: null,
      errorMessage: "",
      errors: [],
      confirmEmailMessage: "",
      showFeedbackPopup: false,
      feedbackPopupContent: []
    };
  },
  logoutFailed: (state, { payload }) => {
    state.errors = payload;
  }
};
