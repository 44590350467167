import { Box, Typography, CircularProgress as CircularProgressMUI } from "@mui/material";

export const CircularProgress = ({
  value = 0,
  size = 50,
  thickness = 2,
  bgColor,
  fillColor,
  textColor
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgressMUI
        variant="determinate"
        sx={{
          color: bgColor
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgressMUI
        variant="determinate"
        value={value}
        sx={{
          color: fillColor,
          position: "absolute",
          left: 0
        }}
        size={size}
        thickness={thickness}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="bodyS" component="div" color={textColor}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
