import React from "react";
import { Link, PlusIcon } from "ui/components";
import { Stack } from "@mui/material";
import { REQUEST_DOMAIN_THEME } from "core/constants";
import { useCustomerSupport } from "hooks";

export const DomainRequest = () => {
  const { openCustomerSupport } = useCustomerSupport(REQUEST_DOMAIN_THEME);

  return (
    <Stack direction="row" spacing={1} mt="15px" onClick={openCustomerSupport}>
      <PlusIcon />
      <Link to="">Add a new sending domain</Link>
    </Stack>
  );
};
