import { domainActions } from "./domain.slice";

export const {
  getSendingDomains,
  getSendingDomainsSuccess,
  getSendingDomainsFailed,

  addSendingDomain,
  addSendingDomainSuccess,
  addSendingDomainFailed,

  deleteSendingDomain,
  deleteSendingDomainSuccess,
  deleteSendingDomainFailed,

  getDnsRecords,
  getDnsRecordsSuccess,
  getDnsRecordsFailed,

  verifyDomain,
  verifyDomainSuccess,
  verifyDomainFailed
} = domainActions;
