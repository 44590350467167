import { call, put, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  getPaymentMethods,
  getPaymentMethodsFailed,
  getPaymentMethodsSuccess
} from "../billing.actions";
import { BillingMapper } from "core/api";

function* handleGetPaymentMethods() {
  try {
    const paymentMethods = yield call(BillingService.getPaymentMethods);
    yield put(getPaymentMethodsSuccess(BillingMapper.paymentMethodsDataToState(paymentMethods)));
  } catch (error) {
    yield put(getPaymentMethodsFailed(error));
  }
}

export default function* watchGetPaymentMethods() {
  yield takeEvery(getPaymentMethods.type, handleGetPaymentMethods);
}
