import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { login } from "../../../../core/store/entities/User/user.actions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { userSelectors } from "../../../../core/store/entities/User/user.selectors";

const schema = yup.object({
  username: yup.string().required("This field is required"),
  password: yup.string().required("This field is required")
});

export const useLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const apiErrors = useSelector(userSelectors.errors);
  const isLoginLoading = useSelector(userSelectors.isLoginLoading);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm({ resolver: yupResolver(schema) });

  const errors = { ...apiErrors, ...formErrors };

  const onSubmit = data => {
    !isLoginLoading && dispatch(login(data));
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    isLoginLoading,
    errors,
    showPassword,
    handleClickShowPassword
  };
};
