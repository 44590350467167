import { call, put, takeLatest } from "redux-saga/effects";
import { BillingMapper } from "core/api";
import { BillingService } from "core/api/services/BillingService";
import { editBillingData, editBillingDataFailed, editBillingDataSuccess } from "../billing.actions";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { EDIT_BILLING_INFO_MODAL } from "core/constants";

function* handleEditBillingData({ payload }) {
  try {
    const billingData = yield call(
      BillingService.editBillingData,
      BillingMapper.billingDataToRequest(payload)
    );
    yield put(editBillingDataSuccess(BillingMapper.billingDataToState(billingData)));
    yield put(hideModal(EDIT_BILLING_INFO_MODAL));
  } catch (error) {
    yield put(editBillingDataFailed(BillingMapper.billingErrorsToState(error)));
  }
}

export default function* watchEditBillingData() {
  yield takeLatest(editBillingData.type, handleEditBillingData);
}
