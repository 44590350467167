import { StyledInfoBox, StyledInfoBoxFooter, StyledInfoBoxText } from "./styled";
import { Stack, Typography } from "@mui/material";
import { CopyButton } from "../../.";

export const InfoBlock = ({ item: { title = "", value = "", footer = false }, footerContent }) => {
  return (
    <StyledInfoBox withFooter={!!footer}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <Stack justifyContent="center">
          <Typography variant="bodyS">{title}</Typography>
          <Typography variant="bodyMBold">{value}</Typography>
        </Stack>
        <CopyButton text={value} showCopyText />
      </Stack>
      {footer && (
        <StyledInfoBoxFooter>
          <StyledInfoBoxText onClick={footerContent.onClick}>
            {footerContent.text}
          </StyledInfoBoxText>
        </StyledInfoBoxFooter>
      )}
    </StyledInfoBox>
  );
};
