import {
  TextField as MUITextField,
  formHelperTextClasses,
  inputAdornmentClasses,
  inputLabelClasses,
  outlinedInputClasses,
  styled
} from "@mui/material";

export const StyledTextField = styled(MUITextField)(({ theme }) => ({
  [`.${outlinedInputClasses.input} `]: {
    height: "1.2em"
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
  [`& .${outlinedInputClasses.root}.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.black
  },
  [`& .${outlinedInputClasses.root}.Mui-focused.Mui-error .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: theme.palette.error.main,
      borderWidth: 2
    },
  [`& .${inputLabelClasses.root}.Mui-focused`]: {
    color: theme.palette.black,
    fontWeight: 500,
    letterSpacing: 0.1
  },
  [`& .${inputLabelClasses.root}.Mui-error`]: {
    color: theme.palette.error.main,
    fontWeight: 500,
    letterSpacing: 0.1
  },
  [`& .${inputAdornmentClasses.root}`]: {
    cursor: "pointer"
  },
  [`& .${inputAdornmentClasses.root} > p`]: {
    color: theme.palette.black,
    cursor: "pointer",
    fontWeight: 500,
    fontSize: 14
  },
  [`& .${formHelperTextClasses.root}`]: {
    fontFamily: "VolkartRegular",
    fontSize: 14,
    lineHeight: "16px",
    marginLeft: 0,
    marginTop: 4
  },
  legend: {
    width: "inherit"
  }
}));
