export const CREATE_ACCOUNT_SCREEN = "/create-account";
export const CREATE_FREE_ACCOUNT_SCREEN = "/create-account/free";
export const CREATE_PAID_ACCOUNT_SCREEN = "/create-account/paid";
export const LOGIN_SCREEN = "/login";
export const CONFIRM_EMAIL_SCREEN = "/confirm-email";
export const RESET_PASSWORD_SCREEN = "/reset-password";
export const CHOOSE_NEW_PASSWORD_SCREEN = "/choose-new-password";
export const DASHBOARD_SCREEN = "/dashboard";
export const DASHBOARD_STATISTICS_SCREEN = "/dashboard/statistics";
export const DASHBOARD_SENDING_DOMAINS_SCREEN = "/dashboard/sending-domains";
export const SETUP_DOMAIN_SCREEN = "/dashboard/setup-domain";
export const DASHBOARD_MESSAGES_SCREEN = "/dashboard/messages";
export const DASHBOARD_BILLING_SCREEN = "/dashboard/billing";
export const CONNECT_API_SCREEN = "/dashboard/connect-api";
export const CONNECT_SMTP_SCREEN = "/dashboard/connect-smtp";
export const PAGE_NOT_FOUND_SCREEN = "/page-not-found";
export const ADMIN_VIEW_SCREEN = "/admin_view";
export const ROOT_SCREEN = "/";
export const LOG_FILES_SCREEN = "/dashboard/log-files";
