import { BillingSectionTitle } from "../BillingSectionTitle";
import { Alert, Grid } from "@mui/material";
import { EditBillingInformationModal } from "../BillingInformation/EditBillingInformationModal";
import { AddNewCreditCard } from "./AddNewCreditCard";
import { PaymentCard } from "./PaymentCard";
import { useSelector } from "react-redux";
import React from "react";
import { billingPaymentMethodsSelectors } from "core/store/entities/Billing/billing.selectors";

export const PaymentMethods = () => {
  const paymentCards = useSelector(billingPaymentMethodsSelectors.data);
  const paymentMethodsErrors = useSelector(billingPaymentMethodsSelectors.error);
  const paymentMethodToDelete = useSelector(billingPaymentMethodsSelectors.paymentMethodToDelete);

  return (
    <>
      <BillingSectionTitle title="Payment method" />
      <Grid container item lg={7.9} direction="row" gap={2}>
        {!!paymentMethodsErrors && (
          <Grid container item>
            <Alert variant="filled" severity="error">
              {paymentMethodsErrors?.detail}
            </Alert>
          </Grid>
        )}
        {paymentCards.map(card => (
          <Grid item xs={12} xl={5.85} key={card.id}>
            <PaymentCard
              id={card.id}
              name={card.name}
              expirationDate={card.expirationDate}
              cardLastDigits={card.cardLastDigits}
              brand={card.brand}
              isSelected={card.isSelected}
              isDeleteDisabled={paymentCards.length === 1 || card.isSelected}
              isLoading={card.id === paymentMethodToDelete}
            />
          </Grid>
        ))}

        <Grid item xs={12} xl={5.85}>
          <AddNewCreditCard />
        </Grid>
      </Grid>
      <EditBillingInformationModal />
    </>
  );
};
