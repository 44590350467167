import { Stack } from "@mui/material";
import { Spinner, InfoBlock } from "ui/components";
import { useSmtpDetails } from "./useSmtpDetails";

export const DetailsContainer = () => {
  const { smtpInfo, isSmtpInfoLoading, passwordFooter } = useSmtpDetails();

  if (isSmtpInfoLoading) return <Spinner />;

  return (
    <Stack spacing={1.5}>
      {smtpInfo?.map((detail, index) => (
        <InfoBlock key={index} item={detail} footerContent={passwordFooter} />
      ))}
    </Stack>
  );
};
