import { deleteRequest, getRequest, postRequest, putRequest } from "core/api/base";

export class BillingService {
  static getBillingData = () => getRequest({ url: "/rest/billing/customer-details/" });
  static getSubscriptionInfo = () => getRequest({ url: "/rest/billing/cycle-info/" });
  static editBillingData = data => putRequest({ url: "/rest/billing/customer-details/", data });
  static getInvoices = ({ limit = 10, starting_after = "" }) => {
    const startingAfter = starting_after ? `&starting_after=${starting_after}` : "";
    return getRequest({ url: `/rest/billing/invoices/?limit=${limit}${startingAfter}` });
  };
  static getPaymentMethods = () => getRequest({ url: "/rest/billing/payment-methods/" });
  static deletePaymentMethod = paymentMethod =>
    deleteRequest({
      url: "/rest/billing/payment-methods/",
      data: { payment_method_id: paymentMethod.id, selected: paymentMethod.isSelected }
    });
  static addPaymentMethod = data => postRequest({ url: "/rest/billing/payment-methods/", data });
  static selectPaymentMethod = paymentMethodId =>
    putRequest({
      url: "/rest/billing/payment-methods/",
      data: { payment_method_id: paymentMethodId }
    });
}
