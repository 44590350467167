import { Button, styled } from "@mui/material";

export const ResendEmailButton = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: 0.1,
  lineHeight: "14px",
  color: theme.palette.black,
  padding: "7px",
  textTransform: "none",
  border: "none",
  height: "24px",
  boxShadow:
    "0px 1px 0px 0 rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 8%), 0px 0px 4px 0px rgb(0 0 0 / 12%)",
  "&:hover": {
    border: "none"
  }
}));
