const ENTITY = "statistics";

export const statisticsSelectors = {
  globalStats: state => state[ENTITY].globalStats,
  isGlobalStatsLoading: state => state[ENTITY].isGlobalStatsLoading,
  statsPerDomainTable: state => state[ENTITY].statsPerDomainTable,
  isStatsPerDomainTableLoading: state => state[ENTITY].isStatsPerDomainTableLoading,
  domainsOptions: state => state[ENTITY].domainsOptions,
  selectedDomainId: state => state[ENTITY].selectedDomainId,
  selectedDomain: state => state[ENTITY].selectedDomain,
  isDomainsOptionsLoading: state => state[ENTITY].isDomainsOptionsLoading,
  filterOptions: state => state[ENTITY].filterOptions,
  filterValue: state => state[ENTITY].filterValue,
  filterDetailsValue: state => state[ENTITY].filterDetailsValue,
  globalStatsPerDomain: state => state[ENTITY].globalStatsPerDomain,
  isGlobalStatsPerDomainLoading: state => state[ENTITY].isGlobalStatsPerDomainLoading,
  deliveriesAndBounces: state => state[ENTITY].deliveriesAndBounces,
  isDeliveriesAndBouncesLoading: state => state[ENTITY].isDeliveriesAndBouncesLoading,
  ispTable: state => state[ENTITY].ispTable,
  isIspTableLoading: state => state[ENTITY].isIspTableLoading,
  topBouncesTable: state => state[ENTITY].topBouncesTable,
  isTopBouncesTableLoading: state => state[ENTITY].isTopBouncesTableLoading,
  isDownloadingLogs: state => state[ENTITY].isDownloadingLogs,
  downloadingLogsSuccess: state => state[ENTITY].downloadingLogsSuccess,
  downloadingLogsError: state => state[ENTITY].downloadingLogsError,
  error: state => state[ENTITY].error
};
