export const typographyTheme = {
  headlineXL: {
    fontFamily: "BeatriceSemibold",
    color: "#000000",
    lineHeight: "132px",
    letterSpacing: -1.2,
    fontSize: 120,
    fontWeight: 600
  },
  headlineL: {
    fontFamily: "BeatriceSemibold",
    color: "#000000",
    lineHeight: "78px",
    letterSpacing: -1.2,
    fontSize: 60,
    fontWeight: 600
  },
  headlineM: {
    fontFamily: "BeatriceSemibold",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.3,
    fontSize: 30,
    fontWeight: 600
  },
  regularHeadlineM: {
    fontFamily: "BeatriceRegular",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.3,
    fontSize: 30,
    fontWeight: 400
  },
  headlineS: {
    fontFamily: "BeatriceSemibold",
    color: "#000000",
    lineHeight: "33px",
    letterSpacing: -0.25,
    fontSize: 20,
    fontWeight: 600
  },
  regularHeadlineS: {
    fontFamily: "BeatriceRegular",
    color: "#000000",
    lineHeight: "33px",
    letterSpacing: -0.25,
    fontSize: 20,
    fontWeight: 400
  },
  headlineXS: {
    fontFamily: "BeatriceSemibold",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 600
  },
  regularHeadlineXS: {
    fontFamily: "BeatriceRegular",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 400
  },
  bodyXL: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "32px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 400
  },
  bodyXLBold: {
    fontFamily: "VolkartMedium",
    color: "#000000",
    lineHeight: "40px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 500
  },
  bodyL: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 400
  },
  bodyLBolder: {
    fontFamily: "VolkartMedium",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 500
  },
  bodyLBold: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "28px",
    letterSpacing: 0.1,
    fontSize: 18,
    fontWeight: 700
  },
  bodyM: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "25px",
    letterSpacing: 0.2,
    fontSize: 16,
    fontWeight: 400
  },
  bodyMBold: {
    fontFamily: "VolkartMedium",
    color: "#000000",
    lineHeight: "25px",
    letterSpacing: 0.2,
    fontSize: 16,
    fontWeight: 500,
    wordBreak: "break-word"
  },
  bodyS: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "22px",
    letterSpacing: 0.2,
    fontSize: 14,
    fontWeight: 400
  },
  bodySBold: {
    fontFamily: "VolkartMedium",
    color: "#000000",
    lineHeight: "22px",
    letterSpacing: 0.2,
    fontSize: 14,
    fontWeight: 500
  },
  bodyXS: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "19px",
    letterSpacing: 0.2,
    fontSize: 12,
    fontWeight: 400
  },
  menuItem: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "17px",
    letterSpacing: 0.1,
    fontSize: 17,
    fontWeight: 400
  },
  navLinkSmall: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: 0.1,
    fontSize: 16,
    fontWeight: 400
  },
  sectionHeaderM: {
    fontFamily: "VolkartRegular",
    color: "#000000",
    lineHeight: "42px",
    letterSpacing: -0.8,
    fontSize: "38px",
    fontWeight: 400
  },
  sectionHeaderS: {
    fontFamily: "VolkartMedium",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: 0.1,
    fontSize: 20,
    fontWeight: 500
  },
  hint: {
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    lineHeight: "27px",
    fontWeight: 400,
    color: "#979797"
  }
};
