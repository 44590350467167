import { call, put, select, takeLatest } from "redux-saga/effects";

import * as messagesActions from "./messages.actions";
import { showFeedbackPopup } from "../User/user.actions";
import { MessageService } from "core/api";
import { messagesSelectors } from "./messages.selectors";

function* fetchMessageThemesHandler() {
  try {
    const themes = yield call(MessageService.getThemes);
    yield put(messagesActions.fetchMessageThemesSuccess(themes));
  } catch (error) {
    yield put(messagesActions.fetchMessageThemesFailed(error));
  }
}

function* sendMessageHandler({ payload }) {
  try {
    const result = yield call(MessageService.send, payload);
    const onSuccessCallback = yield select(messagesSelectors.onSuccessCallback);
    onSuccessCallback();
    yield put(messagesActions.sendMessageSuccess(result));
    yield put(
      showFeedbackPopup(["Thank you for your message.", "We typically respond within 24 hours."])
    );
  } catch (error) {
    yield put(messagesActions.sendMessageFailed(error));
  }
}

export default function* messagesSaga() {
  yield takeLatest(messagesActions.fetchMessageThemes, fetchMessageThemesHandler);
  yield takeLatest(messagesActions.sendMessage.type, sendMessageHandler);
}
