import { statisticsReducer } from "../entities/Statistics/statistics.slice";
import { userReducer } from "../entities/User/user.slice";
import { routerReducer } from "../utils/history";
import { domainReducer } from "../entities/Domain/domain.slice";
import { billingReducer } from "../entities/Billing/billing.slice";
import { modalReducer } from "../entities/Modal/modal.slice";
import { messagesReducer } from "../entities/Messages/messages.slice";
import { upgradePlanReducer } from "../entities/UpgradePlan/upgradePlan.slice";
import { logoutSuccess } from "../entities/User/user.actions";
import { combineReducers } from "redux";

const reducers = {
  messages: messagesReducer,
  statistics: statisticsReducer,
  user: userReducer,
  domain: domainReducer,
  billing: billingReducer,
  router: routerReducer,
  modal: modalReducer,
  upgradePlan: upgradePlanReducer
};

export const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  if (action.type === logoutSuccess.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};
