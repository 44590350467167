import React from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Outlet, Routes, Route } from "react-router-dom";

import { history } from "core/store";

import { privateRoutes, publicRoutes, unauthorizedRoutes } from "../routes";
import { UnauthorizedOnlyRoute, PrivateRoute } from "../components";

const renderRoutes = routes => {
  return routes.map((route, index) => (
    <Route key={index} {...route}>
      {route?.routes?.map(route => (
        <Route key={index} {...route} />
      ))}
    </Route>
  ));
};

export const RouterConfig = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route element={<PrivateRoute />}>{renderRoutes(privateRoutes)}</Route>
        <Route element={<Outlet />}>{renderRoutes(publicRoutes)}</Route>
        <Route element={<UnauthorizedOnlyRoute />}>{renderRoutes(unauthorizedRoutes)}</Route>
      </Routes>
    </Router>
  );
};
