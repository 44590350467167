import {
  ADD_DOMAIN_STEP,
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  DASHBOARD_STATISTICS_SCREEN,
  LOG_FILES_SCREEN,
  TOTAL_STEPS
} from "core/constants";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCustomerSupport } from "hooks";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const useNavbar = () => {
  const location = useLocation();
  const user = useSelector(userSelectors.user);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { openCustomerSupport } = useCustomerSupport();
  const settingsItems = [
    DASHBOARD_SENDING_DOMAINS_SCREEN,
    DASHBOARD_BILLING_SCREEN,
    CONNECT_API_SCREEN,
    CONNECT_SMTP_SCREEN
  ];

  useEffect(() => {
    if (settingsItems.includes(location.pathname)) {
      setIsSettingsOpen(true);
    } else {
      setIsSettingsOpen(false);
    }
  }, [location]);

  const handleExpand = status => {
    setIsExpanded(status);
  };

  const handleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
    setIsExpanded(true);
  };

  const items = [
    {
      id: "dashboard",
      name: "Dashboard",
      to: DASHBOARD_SCREEN,
      icon: "dashboard",
      onClick: null,
      isActive: location.pathname === DASHBOARD_SCREEN
    },
    {
      id: "statistics",
      name: "Statistics",
      to: `${DASHBOARD_STATISTICS_SCREEN}/1`,
      icon: "statistics",
      onClick: null,
      isActive: location.pathname.startsWith(DASHBOARD_STATISTICS_SCREEN),
      isDisabled: user.currentStep <= TOTAL_STEPS
    },
    {
      id: "settings",
      name: "Settings",
      to: "",
      icon: "settings",
      onClick: handleSettings,
      isActive: isSettingsOpen,
      showItems: isSettingsOpen,
      items: [
        {
          id: "billing",
          name: "Account and billing",
          to: DASHBOARD_BILLING_SCREEN,
          isActive: location.pathname === DASHBOARD_BILLING_SCREEN,
          isDisabled: user.currentStep < ADD_DOMAIN_STEP
        },
        {
          id: "sending-domains",
          name: "Sending domains",
          to: DASHBOARD_SENDING_DOMAINS_SCREEN,
          isActive: location.pathname === DASHBOARD_SENDING_DOMAINS_SCREEN,
          isDisabled: !user.hasDomain
        },
        {
          id: "smtp-setup",
          name: "SMTP user",
          to: CONNECT_SMTP_SCREEN,
          isActive: location.pathname === CONNECT_SMTP_SCREEN,
          isDisabled: user.currentStep < TOTAL_STEPS
        },
        {
          id: "api-setup",
          name: "API key",
          to: CONNECT_API_SCREEN,
          isActive: location.pathname === CONNECT_API_SCREEN,
          isDisabled: user.currentStep < TOTAL_STEPS
        }
      ]
    },
    {
      id: "log-files",
      name: "Log Files",
      to: LOG_FILES_SCREEN,
      icon: "logFiles",
      onClick: null,
      isActive: location.pathname === LOG_FILES_SCREEN,
      isDisabled: user.currentStep <= TOTAL_STEPS
    },
    {
      id: "support",
      name: "Help & support",
      to: "",
      icon: "comments",
      onClick: openCustomerSupport,
      isActive: false
    }
  ];
  return {
    items,
    isSettingsOpen,
    isExpanded,
    handleExpand
  };
};
