import {
  CONNECT_API_SCREEN,
  CONNECT_SMTP_SCREEN,
  DASHBOARD_BILLING_SCREEN,
  DASHBOARD_SCREEN,
  DASHBOARD_SENDING_DOMAINS_SCREEN,
  DASHBOARD_STATISTICS_SCREEN,
  LOG_FILES_SCREEN,
  SETUP_DOMAIN_SCREEN
} from "core/constants";
import {
  Billing,
  ConnectAPI,
  ConnectSMTP,
  Dashboard,
  Layout,
  LogFiles,
  SendingDomains,
  SetupDomain,
  StatisticsDetails
} from "ui/screens";
import React from "react";

export const privateRoutes = [
  {
    exact: true,
    path: DASHBOARD_SCREEN,
    element: <Layout />,
    routes: [
      {
        exact: true,
        index: true,
        element: <Dashboard />
      },
      {
        exact: true,
        path: DASHBOARD_SENDING_DOMAINS_SCREEN,
        element: <SendingDomains />
      },
      { exact: true, path: `${SETUP_DOMAIN_SCREEN}/:domainId`, element: <SetupDomain /> },
      {
        exact: true,
        path: `${DASHBOARD_STATISTICS_SCREEN}/:domainId`,
        element: <StatisticsDetails />
      },
      {
        exact: true,
        path: DASHBOARD_STATISTICS_SCREEN,
        element: <Dashboard />
      },
      {
        path: DASHBOARD_BILLING_SCREEN,
        element: <Billing />
      },
      {
        path: CONNECT_API_SCREEN,
        element: <ConnectAPI />
      },
      {
        path: CONNECT_SMTP_SCREEN,
        element: <ConnectSMTP />
      },
      {
        path: LOG_FILES_SCREEN,
        element: <LogFiles />
      }
    ]
  }
];
