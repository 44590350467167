import {
  StepConnector as MUIStepConnector,
  StepContent as MUIStepContent,
  StepLabel as MUIStepLabel,
  Stepper,
  stepClasses,
  stepConnectorClasses,
  stepContentClasses,
  stepLabelClasses,
  styled,
  touchRippleClasses
} from "@mui/material";
import { Button } from "ui/components";

export const StepperContainer = styled(Stepper)(({ firstStepDivider, theme }) => ({
  ...(firstStepDivider && {
    [`& .${stepConnectorClasses.root} .${stepConnectorClasses.line}`]: {
      minHeight: 0
    },
    [`& .${stepClasses.root} .${stepContentClasses.root}`]: {
      padding: "6px 0 6px 35px",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 0 6px 10px"
      }
    }
  })
}));

export const StepConnector = styled(MUIStepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: "18px"
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "black",
    minHeight: "12px"
  }
}));

export const StepContent = styled(MUIStepContent)(() => ({
  [`.${stepClasses.completed} &.${stepContentClasses.root}`]: {
    padding: 0
  },
  [`&.${stepContentClasses.root}`]: {
    padding: "10px 40px",
    borderColor: "black",
    marginLeft: "18px"
  }
}));

export const StepIconComponent = styled(Button)(() => ({
  minWidth: 41,
  height: 36,
  padding: 0,
  pointerEvents: "none",
  [`& .${touchRippleClasses.root}`]: {
    fontWeight: 500,
    fontSize: 16
  }
}));

export const StepLabel = styled(MUIStepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.iconContainer}`]: {
    paddingRight: "16px"
  },
  [`& .${stepLabelClasses.active}`]: {
    color: theme.palette.black
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    color: theme.palette.black
  }
}));
