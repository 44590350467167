import { StyledCopyButton } from "./styled";
import { Image } from "../../Image";
import { Box, Stack, Tooltip, Zoom } from "@mui/material";
import { useCopyToClipboard } from "hooks";

export const CopyButton = ({ text, showCopyText }) => {
  const { isCopiedToClipboard, setIsCopiedToClipboard, handleCopyToClipboard } =
    useCopyToClipboard();

  return (
    <Tooltip
      TransitionComponent={Zoom}
      open={isCopiedToClipboard}
      title="Copied to clipboard!"
      leaveDelay={500}
      onClose={() => setIsCopiedToClipboard(false)}
    >
      <StyledCopyButton onClick={() => handleCopyToClipboard(text)} variant="outlined">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Image name="copy" width={12} height={12} />
          {showCopyText && <Box>Copy</Box>}
        </Stack>
      </StyledCopyButton>
    </Tooltip>
  );
};
