import { Stack, Typography } from "@mui/material";
import { Button, Image } from "ui/components";
import { history } from "core/store";

export const ConnectItem = ({ icon, title, text, buttonText, link }) => {
  const handleConnectPage = () => {
    history.push(link);
  };
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Image name={icon} width={35} height={32} />
      <Stack>
        <Typography variant="headlineS">{title}</Typography>
        <Typography variant="bodyM">{text}</Typography>
      </Stack>
      <Button onClick={handleConnectPage}>{buttonText}</Button>
    </Stack>
  );
};
