import { userActions } from "./user.slice";

export const {
  createCustomerObject,
  createCustomerObjectSuccess,
  createCustomerObjectFailed,

  createCustomerProfile,
  createCustomerProfileSuccess,
  createCustomerProfileFailed,

  fetchMailingSystems,
  fetchMailingSystemsSuccess,
  fetchMailingSystemsFailed,

  login,
  loginSuccess,
  loginFailed,

  loginAsAdmin,
  loginAsAdminSuccess,
  loginAsAdminFailed,

  logoutAsAdmin,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,

  changePasswordOnReset,
  changePasswordOnResetSuccess,
  changePasswordOnResetFailed,

  changePassword,
  changePasswordSuccess,
  changePasswordFailed,

  confirmEmail,
  confirmEmailSuccess,
  confirmEmailFailed,

  resendEmail,
  resendEmailSuccess,
  resendEmailFailed,

  selectProfile,
  restoreProfile,

  getUser,
  getUserSuccess,
  getUserFailed,

  editUser,
  editUserSuccess,
  editUserFailed,

  showFeedbackPopup,
  hideFeedbackPopup,

  getSmtpInfo,
  getSmtpInfoSuccess,
  getSmtpInfoFailed,

  getReturnPaths,
  getReturnPathsSuccess,
  getReturnPathsFailed,

  getWebInfo,
  getWebInfoSuccess,
  getWebInfoFailed,

  clearErrorMessage,
  logout,
  logoutSuccess,
  logoutFailed
} = userActions;
