import React from "react";

import { formatPercentage } from "helpers/percentage";

import { DiffIcon, DiffStatusContainer } from "./styled";
import { Typography } from "@mui/material";

export const DiffStatus = ({ diff, fixedWidth = false, inverted }) => {
  const up = diff >= 0;
  const sign = diff !== 0 ? (diff > 0 ? "+" : "-") : "";

  return (
    <DiffStatusContainer variant="bodyLBold" fixedWidth={fixedWidth} inverted={inverted} up={up}>
      {!!diff && <DiffIcon inverted={inverted} up={up} />}
      <Typography color="inherit" variant="bodyLBolder">
        {sign}
        {formatPercentage(Math.abs(diff))}%
      </Typography>
    </DiffStatusContainer>
  );
};
