const ENTITY = "upgradePlan";

export const upgradePlanSelectors = {
  showUpgradePlanModal: state => state[ENTITY].showUpgradePlanModal,
  showUpgradePlanConfirmation: state => state[ENTITY].showUpgradePlanConfirmation,
  sendUpgradePlan: state => state[ENTITY].sendUpgradePlan,
  sendUpgradePlanSuccess: state => state[ENTITY].sendUpgradePlanSuccess,
  sendUpgradePlanError: state => state[ENTITY].sendUpgradePlanError,
  upgradePlans: state => state[ENTITY].upgradePlans,
  customPlans: state => state[ENTITY].customPlans,
  countries: state => state[ENTITY].countries,
  topCountries: state => state[ENTITY].topCountries
};
