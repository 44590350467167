import { Stack } from "@mui/material";
import { Chip } from "ui/components";
import {
  DnsRecordContainer,
  DnsRecordNote,
  DnsRecordParameters,
  StyledDnsRecordItem
} from "./styled";
import { DnsRecordItem } from "./DnsRecordItem";
import { DnsRecordHeader } from "./DnsRecordHeader";

export const DnsRecord = ({ headers, record, recordWidth, showHeaders }) => {
  return (
    <>
      {showHeaders && (
        <Stack direction="row">
          {headers.map((header, index) => (
            <DnsRecordHeader key={index} width={header.width} title={header.title} />
          ))}
        </Stack>
      )}
      <DnsRecordContainer type={`${record.error ? "error" : ""}`}>
        <DnsRecordParameters direction="row">
          <DnsRecordItem width={recordWidth["type"]} text={record.type} />
          <DnsRecordItem
            width={recordWidth["name"]}
            text={record.name}
            overflowWidth="175px"
            copy
            showCopyText
          />
          <DnsRecordItem
            flex={1}
            width={recordWidth["value"]}
            text={record.value}
            overflowWidth="500px"
            copy
            showCopyText
          />
          {!!record.priority && (
            <DnsRecordItem width={recordWidth["priority"]} text={record.priority} copy />
          )}
        </DnsRecordParameters>
        <StyledDnsRecordItem>
          <Stack spacing={1}>
            <DnsRecordNote variant="bodyS">{record.note}</DnsRecordNote>
            {!!record.error && <Chip label={record.error} type="alert" />}
          </Stack>
        </StyledDnsRecordItem>
      </DnsRecordContainer>
    </>
  );
};
