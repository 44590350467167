import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

export const DownloadLogsText = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="headlineS">Download your log files</Typography>
      <Typography variant="bodyL">
        Below you can download your bounce logs in .csv format.
      </Typography>
      <Typography variant="bodyL">
        If you own more than one account, please select the correct one from the dropdown in the
        upper right corner.
      </Typography>
      <Typography variant="bodyL">
        Choose the period of time from the dropdown, press submit and your download will be ready
        soon!
      </Typography>
      <Divider sx={{ opacity: "20%", height: "2px !important", mt: 7 }} />
    </Stack>
  );
};
