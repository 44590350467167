import { Stack, styled } from "@mui/material";

export const PlusIconContainer = styled(Stack)(() => ({
  cursor: "pointer",
  width: 19,
  height: 19,
  borderRadius: "9.5px",
  border: "1px solid #DBDBDB",
  boxShadow: "0 1px 0 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 16%), 0 0 3px 0 rgb(0 0 0 / 12%)",
  justifyContent: "center",
  alignItems: "center"
}));
