import { useImperativeHandle, useState } from "react";
import { useTheme } from "@mui/material";

export const StripeInput = ({ component: Component, inputRef, options, ...props }) => {
  const [mountNode, setMountNode] = useState(null);
  const theme = useTheme();
  useImperativeHandle(
    inputRef,
    () => ({
      focus: () => {
        if (mountNode === null) {
          return;
        }
        mountNode.focus();
      }
    }),
    [mountNode]
  );
  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            fontSize: "14px",
            lineHeight: "23px",
            fontFamily: "VolkartRegular"
          },
          invalid: {
            color: theme.palette.error.main
          }
        }
      }}
      {...props}
    />
  );
};
