import { call, put, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  getPaymentMethods,
  getPaymentMethodsFailed,
  selectPaymentMethod
} from "../billing.actions";

function* handleSelectPaymentMethod({ payload }) {
  try {
    yield call(BillingService.selectPaymentMethod, payload);
  } catch (error) {
    yield put(getPaymentMethodsFailed(error));
  } finally {
    yield put(getPaymentMethods());
  }
}

export default function* watchSelectPaymentMethod() {
  yield takeEvery(selectPaymentMethod, handleSelectPaymentMethod);
}
