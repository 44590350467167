import { Grid, Stack, Typography } from "@mui/material";
import { CloseIcon, Image } from "ui/components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PaidPlanBlockContainer } from "./styled";
import { numberWithDots } from "helpers";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const PaidPlanBlock = () => {
  const user = useSelector(userSelectors.user);
  const [showBlock, setShowBlock] = useState(true);

  const handleHideBlock = () => {
    setShowBlock(false);
  };

  if (!showBlock) return null;

  return (
    <Grid item xs={12} lg={7.9} mb={4}>
      <PaidPlanBlockContainer>
        <CloseIcon onClose={handleHideBlock} />
        <Stack direction="row" alignItems="center" spacing={2}>
          <Image
            name="plantPremiumPlan"
            height={90}
            customStyles={{
              marginTop: "-40px"
            }}
          />
          <Stack>
            <Typography variant="bodyLBolder">
              {`Thank you for choosing our ${user.plan} plan (${numberWithDots(
                user.emailsVolume
              )} emails / mo)`}
            </Typography>
            <Typography variant="bodyM">
              We will contact you soon to validate and complete the subscription.
            </Typography>
          </Stack>
        </Stack>
      </PaidPlanBlockContainer>
    </Grid>
  );
};
