import React from "react";
import { Grid, Typography } from "@mui/material";
import { Image, Link } from "ui/components";
import { CreateAccountStep1 } from "./CreateAccountStep1";
import { CreateAccountStep2 } from "./CreateAccountStep2";
import { TextContainer } from "./TextContainer";
import { LOGIN_SCREEN } from "core/constants";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const CreateAccount = () => {
  const step = useSelector(userSelectors.createAccountStep);
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
      <Grid
        item
        xs={8}
        md={5}
        lg={4}
        xl={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          resize: "none"
        }}
      >
        <Image name="inboxroadIcon" height="35px" />
        <TextContainer />
        <Grid item sx={{ mt: "30px", width: "330px" }}>
          {step === 1 ? <CreateAccountStep1 /> : <CreateAccountStep2 />}
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="bodyXS">
                By continuing, you agree to our{" "}
                <Link href="https://inboxroad.com/terms-of-service" target="_blank" external>
                  Terms of Service
                </Link>{" "}
                and <Link to="/">Privacy Policy</Link>
              </Typography>
            </Grid>
            <Grid item xs textAlign="center">
              <Typography variant="bodyS">
                Already an account? <Link to={LOGIN_SCREEN}>Log in</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
