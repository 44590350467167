import { DialogContent, DialogTitle } from "@mui/material";
import { CloseIcon } from "ui/components";
import { StyledDialog } from "./styled";

export const Dialog = ({ children, open, onClose, ...props }) => {
  if (!open) return null;

  return (
    <StyledDialog open={open} onClose={onClose} {...props}>
      <DialogTitle>
        <CloseIcon onClose={onClose} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};
