import { call, put, takeLatest } from "redux-saga/effects";

import * as modalActions from "./modal.actions";
import { StatisticsService } from "core/api";
import { SHOW_PAID_PLAN_BLOCK } from "core/constants";

function* showPaidPlanBlockHandler() {
  try {
    const { show_thank } = yield call(StatisticsService.getTinsel);

    if (show_thank) yield put(modalActions.showModal(SHOW_PAID_PLAN_BLOCK));
  } catch (error) {
    yield put(modalActions.hideModal(SHOW_PAID_PLAN_BLOCK));
  }
}

export default function* messagesSaga() {
  yield takeLatest(modalActions.showPaidPlanBlock, showPaidPlanBlockHandler);
}
