import { Fade, styled, Tooltip as MUITooltip, tooltipClasses } from "@mui/material";

const styles = ({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.gray[50],
    color: theme.palette.black,
    boxShadow:
      "0px 6px 15px 0 rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 8%), 0px 0px 4px 0px rgb(0 0 0 / 20%)",
    padding: 15,
    fontSize: 14,
    lineHeight: "21px",
    marginLeft: "10px",
    marginTop: "-5px !important"
  },
  [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}`]: {
    color: theme.palette.gray[50],
    transform: "translate3d(15px, 0px, 0px) !important",
    marginTop: "-1em",
    height: "1em",
    width: "2em"
  },
  [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}:before`]: {
    boxShadow:
      "0px 6px 15px 0 rgb(0 0 0 / 8%), 0px 2px 2px 0px rgb(0 0 0 / 8%), 0px 0px 4px 0px rgb(0 0 0 / 8%)"
  }
});

export const Tooltip = styled(({ className, children, ...props }) => (
  <MUITooltip
    classes={{ popper: className }}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 400 }}
    {...props}
  >
    {children}
  </MUITooltip>
))(styles);
