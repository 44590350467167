import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { DASHBOARD_SCREEN } from "core/constants";
import { isAuthenticated } from "helpers/checkAuth";

export const UnauthorizedOnlyRoute = () => {
  // TODO: create wrapper to unify and to remove from components calls "window" stored gtag function
  // window.gtag("event", "page_view", {
  //   page_path: props.path
  // });

  return isAuthenticated() ? <Navigate to={DASHBOARD_SCREEN} /> : <Outlet />;
};
