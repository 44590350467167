export const diffPercentage = (a = 0, b = 0) => {
  return (a ? (b - a) / a : 0) * 100;
};

export const percentage = (a = 0, b = 0) => {
  return b ? (a / b) * 100 : 0;
};

export const formatPercentage = (value, exponent = 1) => {
  return (Math.round(value * Math.pow(10, exponent)) / Math.pow(10, exponent))
    .toFixed(exponent)
    .replace(/\d(?=(\d{3})+\.)/g, "$&.");
};
