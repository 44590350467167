import { useCustomerSupport } from "hooks";
import { UPGRADE_PLAN_THEME } from "core/constants";
import { useSelector } from "react-redux";
import { billingSubscriptionSelectors } from "core/store/entities/Billing/billing.selectors";

export const useCurrentBillingCycle = () => {
  const subscriptionErrors = useSelector(billingSubscriptionSelectors.error);
  const { openCustomerSupport } = useCustomerSupport(UPGRADE_PLAN_THEME);
  const totalSend = useSelector(billingSubscriptionSelectors.totalSend);
  const totalAvailable = useSelector(billingSubscriptionSelectors.totalAvailable);
  const totalPercentage = useSelector(billingSubscriptionSelectors.totalSendPercentage);
  const subscriptionStart = useSelector(billingSubscriptionSelectors.subscriptionStart);
  const subscriptionEnd = useSelector(billingSubscriptionSelectors.subscriptionEnd);

  return {
    openCustomerSupport,
    totalSend,
    totalAvailable,
    totalPercentage,
    subscriptionStart,
    subscriptionEnd,
    subscriptionErrors
  };
};
