import { TableBody, Typography } from "@mui/material";
import { TableBodyCell, TableBodyRow } from "../../styled";
import React from "react";

export const TableBodyEmpty = ({ colSpan, emptyText }) => {
  return (
    <TableBody>
      <TableBodyRow>
        <TableBodyCell align="center" colSpan={colSpan}>
          <Typography variant="bodyM">
            {emptyText
              ? emptyText
              : `Our system is brewing up your data. It might take up to an hour for your stats to show
            up`}
          </Typography>
        </TableBodyCell>
      </TableBodyRow>
    </TableBody>
  );
};
