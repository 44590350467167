import { useDispatch, useSelector } from "react-redux";

import { userSelectors } from "core/store/entities/User/user.selectors";
import { upgradePlanSelectors } from "core/store/entities/UpgradePlan/upgradePlan.selectors";
import {
  showUpgradePlanModal,
  hideUpgradePlanModal
} from "core/store/entities/UpgradePlan/upgradePlan.actions";

export const useUpgradePlan = () => {
  const dispatch = useDispatch();
  const isUpgradePlanOpened = useSelector(upgradePlanSelectors.showUpgradePlanModal);
  const { currentStep = 0 } = useSelector(userSelectors.user);

  const openUpgradePlan = () => dispatch(showUpgradePlanModal());
  const closeUpgradePlan = () => {
    dispatch(hideUpgradePlanModal());
  };

  return { isUpgradePlanOpened, openUpgradePlan, closeUpgradePlan, currentStep };
};
