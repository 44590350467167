export const upgradePlanReducers = {
  showUpgradePlanModal: state => {
    state.showUpgradePlanModal = true;
  },
  hideUpgradePlanModal: state => {
    state.showUpgradePlanModal = false;
    state.sendUpgradePlanSuccess = false;
    state.sendUpgradePlanError = null;
  },
  showUpgradePlanConfirmation: state => {
    state.showUpgradePlanConfirmation = true;
  },
  hideUpgradePlanConfirmation: state => {
    state.showUpgradePlanConfirmation = false;
    state.sendUpgradePlanSuccess = false;
  },

  sendUpgradePlan: () => {},
  sendUpgradePlanSuccess: state => {
    state.sendUpgradePlanSuccess = true;
    state.showUpgradePlanModal = false;
  },
  sendUpgradePlanFailed: (state, { payload }) => {
    state.sendUpgradePlanError = payload;
  }
};
