import { KeyboardArrowDown } from "@mui/icons-material";
import { Spinner, Menu, MenuButton } from "../../.";
import React from "react";
import { Stack, Typography } from "@mui/material";
import { useProfileMenu } from "./useProfileMenu";
import { ProfileLetter } from "ui/components/Buttons/MenuButton/styled";

export const ProfileMenu = ({ onProfileSelect }) => {
  const {
    profileName,
    open,
    menu,
    menuItems,
    menuButtonId,
    menuId,
    handleOpenMenu,
    handleCloseMenu
  } = useProfileMenu({ onProfileSelect });
  return (
    <>
      <MenuButton
        id={menuButtonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDown />}
      >
        {profileName ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <ProfileLetter variant="bodySBold">{profileName[0].toUpperCase()}</ProfileLetter>
            <Typography variant="bodySBold" whiteSpace="nowrap">
              {profileName}
            </Typography>
          </Stack>
        ) : (
          <Spinner />
        )}
      </MenuButton>
      <Menu
        id={menuId}
        MenuListProps={{
          "aria-labelledby": menuButtonId
        }}
        anchorEl={menu}
        open={open}
        onClose={handleCloseMenu}
        items={menuItems}
      />
    </>
  );
};
