import { call, put, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  getPaymentMethods,
  removePaymentMethod,
  removePaymentMethodFailed
} from "../billing.actions";

function* handleRemovePaymentMethod({ payload }) {
  try {
    yield call(BillingService.deletePaymentMethod, payload);
    yield put(getPaymentMethods());
  } catch (error) {
    yield put(removePaymentMethodFailed(error));
    yield put(getPaymentMethods());
  }
}

export default function* watchRemovePaymentMethods() {
  yield takeEvery(removePaymentMethod.type, handleRemovePaymentMethod);
}
