import { Alert, Grid, Typography } from "@mui/material";
import { Button, LinearProgress } from "ui/components";
import { useCurrentBillingCycle } from "./useCurrentBillingCycle";
import { BillingSectionTitle } from "../BillingSectionTitle";
import { useUpgradePlan } from "hooks";
import React from "react";

export const CurrentBillingCycle = () => {
  const {
    totalSend,
    totalAvailable,
    totalPercentage,
    subscriptionStart,
    subscriptionEnd,
    subscriptionErrors
  } = useCurrentBillingCycle();
  const { openUpgradePlan, currentStep } = useUpgradePlan();

  return (
    <>
      <BillingSectionTitle
        title="Current billing cycle"
        subTitle={subscriptionStart ? `${subscriptionStart} → ${subscriptionEnd}` : ""}
      />
      <Grid container item lg={7.9} gap={2}>
        {!!subscriptionErrors && (
          <Grid container item>
            <Alert variant="filled" severity="error">
              {subscriptionErrors?.detail}
            </Alert>
          </Grid>
        )}
        <Grid container item xs={12} flexDirection="column" alignItems="flex-start" gap={1}>
          {totalAvailable && (
            <>
              <LinearProgress value={totalPercentage} />
              <Typography variant="bodyM">{`${totalSend} of ${totalAvailable} emails`}</Typography>
            </>
          )}
          <Button size="small" onClick={openUpgradePlan} disabled={currentStep <= 2}>
            Upgrade plan
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
