import { StyledDnsRecordItem, StyledDnsRecordItemText } from "../styled";
import { CopyButton } from "ui/components";

export const DnsRecordItem = ({ width, overflowWidth, text, copy, showCopyText, flex }) => {
  return (
    <StyledDnsRecordItem
      flex={flex}
      width={width}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <StyledDnsRecordItemText overflowWidth={overflowWidth} variant="bodyM">
        {text}
      </StyledDnsRecordItemText>
      {copy && <CopyButton text={text} showCopyText={showCopyText} />}
    </StyledDnsRecordItem>
  );
};
