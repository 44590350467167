import React from "react";

import { numberWithDots } from "helpers";

import { Box, Stack, Typography } from "@mui/material";
import { DiffStatus } from "../../index";

export const Indicator = ({ value, label, diff, inverted }) => {
  return (
    <Box>
      <Typography variant="sectionHeaderM">{numberWithDots(value)}</Typography>
      <Stack spacing={2}>
        <Typography variant="bodyLBolder">{label}</Typography>
        <div>
          <DiffStatus inverted={inverted} diff={diff} />
        </div>
      </Stack>
    </Box>
  );
};
