import { SETUP_STEPS, TOTAL_STEPS, VERIFY_EMAIL_STEP } from "core/constants";

export class UserMapper {
  static customerObjectErrorsToState = errors => {
    return {
      email: { message: errors?.detail },
      nonFieldErrors: errors?.non_field_errors
    };
  };
  static customerProfileToApi = data => {
    return {
      email: data.email,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      company: data.companyName,
      mail_system_id: data.mailingSystem
    };
  };
  static customerProfileErrorsToState = data => {
    return {
      email: { message: data?.email },
      password: { message: data?.password },
      firstName: { message: data?.first_name },
      lastName: { message: data?.last_name },
      companyName: { message: data?.company },
      mailingSystem: { message: data?.mail_system_id },
      nonFieldErrors: data?.non_field_errors || "User can not be created."
    };
  };
  static loginErrorsToState = data => {
    return {
      email: { message: data?.email },
      password: { message: data?.password },
      nonFieldErrors: data?.non_field_errors
    };
  };
  static resetPasswordErrorsToState = data => {
    return {
      email: { message: data?.msg },
      nonFieldErrors: data?.non_field_errors
    };
  };
  static changePasswordToApi = data => {
    return {
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
      hash: data?.hash,
      id_: data?.id
    };
  };
  static changePasswordErrorsToState = data => {
    return {
      newPassword: { message: data?.new_password },
      confirmPassword: { message: data?.confirm_password },
      nonFieldErrors: data?.msg
    };
  };
  static userDataToState = data => {
    const {
      email = "",
      first_name = "",
      last_name = "",
      plan = "",
      company = "",
      volume = "",
      show_steps = true,
      has_domain = false
    } = data;
    const step = data.step || 1;
    return {
      email,
      firstName: first_name,
      lastName: last_name,
      company,
      fullName: `${first_name} ${last_name}`,
      currentStep: step + 1,
      progress: (step / TOTAL_STEPS) * 100,
      isSetupFinished: step === TOTAL_STEPS,
      nextStep: SETUP_STEPS[step + 1],
      plan: plan.toLowerCase(),
      emailsVolume: volume,
      showSetupFlow: show_steps,
      hasDomain: has_domain,
      showStats: step > VERIFY_EMAIL_STEP
    };
  };
  static editUserDataToState = data => {
    return {
      company: data?.company_name,
      firstName: data?.first_name,
      lastName: data?.last_name
    };
  };
  static editUserDataToRequest = data => {
    return {
      first_name: data?.firstName,
      last_name: data?.lastName,
      new_company_name: data?.company
    };
  };
  static editUserErrorsToState = errors => {
    return {
      firstName: { message: errors?.first_name },
      lastName: { message: errors?.last_name },
      company: { message: errors?.new_company_name },
      nonFieldErrors: errors?.non_field_errors || errors
    };
  };
  static smtpInfoToState = smtpInfo => {
    return [
      {
        title: "Username",
        value: smtpInfo.login
      },
      {
        title: "Password",
        value: smtpInfo.password,
        footer: true
      },
      {
        title: "SMTP server (host)",
        value: smtpInfo.host
      },
      {
        title: "SMTP port",
        value: smtpInfo.port
      }
    ];
  };
  static returnPathsToState = returnPaths =>
    returnPaths.map(returnPath => ({
      title: "Return Path Address",
      value: returnPath.return_path
    }));
  static webInfoToState = webInfo => ({
    title: "API Key",
    value: webInfo.token,
    footer: true
  });
}
