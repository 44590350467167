export const TOTAL_STEPS = 4;
export const CREATE_ACCOUNT_STEP = 1;
export const VERIFY_EMAIL_STEP = 2;
export const ADD_DOMAIN_STEP = 3;
export const API_OR_SMTP_STEP = 4;

export const SETUP_STEPS = {
  [CREATE_ACCOUNT_STEP]: "Create account",
  [VERIFY_EMAIL_STEP]: "Verify your email",
  [ADD_DOMAIN_STEP]: "Add and verify a domain",
  [API_OR_SMTP_STEP]: "API or SMTP"
};
