import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "core/api/base";
import { CREATE_ACCOUNT_TOKEN, GENERAL_TOKEN, LOGOUT_TOKEN } from "core/constants";

export class UserService {
  static createCustomerObject = data =>
    postRequest({ url: "/rest-auth/sign_up/", data, auth: false });
  static createCustomerProfile = data =>
    patchRequest({ url: "/rest-auth/sign_up_fin/", data, token: CREATE_ACCOUNT_TOKEN });
  static fetchMailingSystems = () =>
    getRequest({ url: "/rest/mailing_systems/", token: CREATE_ACCOUNT_TOKEN });
  static login = data => postRequest({ url: "/rest-auth/login/", data, auth: false });
  static loginAsAdmin = token => getRequest({ url: `/rest-auth/admin_view/${token}`, auth: false });
  static logoutAsAdmin = token =>
    deleteRequest({ url: `/rest-auth/destroy_admin/${token}`, auth: false });
  static confirmEmail = data =>
    patchRequest({ url: `/rest-auth/account_confirm/${data.id_}/${data.hash}`, data, auth: false });
  static resendConfirmEmail = () =>
    postRequest({ url: "/rest-auth/resend_confirm/", token: GENERAL_TOKEN });
  static getUser = () => getRequest({ url: "/rest/user", token: GENERAL_TOKEN });
  static editAccount = data =>
    patchRequest({ url: "/rest/profile/personal", data, token: GENERAL_TOKEN });
  static resetPassword = data => postRequest({ url: "/rest-auth/reset/", data, auth: false });
  static changePasswordOnReset = data =>
    putRequest({ url: `/rest-auth/confirm/${data.id_}/${data.hash}`, data, auth: false });
  static changePassword = data =>
    patchRequest({ url: `/rest/profile/creds`, data, token: GENERAL_TOKEN });
  static getSmtpInfo = () => getRequest({ url: "/rest/smtp_info" });
  static getReturnPaths = () => getRequest({ url: "/rest/return_pathes" });
  static getWebInfo = params => getRequest({ url: `/rest/web_info/${params}/` });
  static logout = () => deleteRequest({ url: "/rest-auth/logout/", token: LOGOUT_TOKEN });
}
