import { Menu, styled } from "@mui/material";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.shape.borderRadius,
    marginTop: 13,
    minWidth: 216,
    boxShadow: "0 2px 2px 0 rgba(0 0 0 / 8%), 0 0 4px 0px rgba(0 0 0 / 20%)",
    "& .MuiMenu-list": {
      padding: "18px 27px 15px"
    },
    "& .MuiMenuItem-root": {
      padding: "6px 0",
      "& > *": {
        marginRight: "10px"
      },
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  }
}));
