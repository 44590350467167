import { filterOptions } from "core/constants";

export const initialState = {
  globalStats: {},
  isGlobalStatsLoading: false,
  statsPerDomainTable: [],
  isStatsPerDomainTableLoading: false,

  domainsOptions: [],
  selectedDomainId: null,
  selectedDomain: {},
  isDomainsOptionsLoading: false,

  filterOptions,
  filterValue: filterOptions[2].value,
  filterDetailsValue: filterOptions[2].value,
  globalStatsPerDomain: {},
  isGlobalStatsPerDomainLoading: false,

  deliveriesAndBounces: [],
  isDeliveriesAndBouncesLoading: false,

  ispTable: [],
  isIspTableLoading: false,

  topBouncesTable: [],
  isTopBouncesTableLoading: false,

  isDownloadingLogs: false,
  downloadingLogsSuccess: "",
  downloadingLogsError: "",

  error: ""
};
