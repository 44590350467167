import { Link } from "ui/components";
import { DASHBOARD_STATISTICS_SCREEN } from "core/constants";
import { Stack, Typography } from "@mui/material";
import { formatUrl, numberWithDots } from "helpers";
import React, { useMemo } from "react";
import { DiffStatus } from "ui/components";
import { diffPercentage } from "helpers/percentage";
import { Chip } from "ui/components";
import { getLongestNumber } from "helpers/number";
import { useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useDomainTable = () => {
  const statsPerDomainTable = useSelector(statisticsSelectors.statsPerDomainTable);

  const getAdviceClass = item => {
    if (!item?.advice) return null;

    if (item.delivery_rate < 75) {
      return <Chip label={item.advice} type="alert" />;
    } else if (item.delivery_rate >= 75 && item.delivery_rate < 97) {
      return <Chip label={item.advice} type="warning" />;
    }

    return <Chip variant="outlined" label={item.advice} />;
  };

  const headers = [
    {
      name: "Sending domain",
      align: "left"
    },
    {
      name: "Deliverability status",
      align: "left"
    },
    {
      name: "Sent",
      align: "left"
    },
    {
      name: "Delivery rate",
      align: "right"
    }
  ];

  const longestValue = useMemo(
    () => getLongestNumber(statsPerDomainTable, "total_send"),
    [statsPerDomainTable]
  );

  const rows = statsPerDomainTable.map(item => ({
    key: item.domain_id,
    columns: [
      {
        align: "left",
        content: (
          <Link to={`${DASHBOARD_STATISTICS_SCREEN}/${item.domain_id}`}>
            <Typography variant="bodyM">
              {item.domain_name ? formatUrl(item.domain_name) : ""}
            </Typography>
          </Link>
        )
      },
      {
        align: "left",
        content: getAdviceClass(item)
      },
      {
        align: "left",
        content: (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="bodyM" width={longestValue * 10} align="right">
              {numberWithDots(item.total_send)}
            </Typography>
            <DiffStatus diff={diffPercentage(item.total_send_old, item.total_send)} />
          </Stack>
        )
      },
      {
        align: "right",
        content: <Typography variant="bodyM">{item.delivery_rate}%</Typography>
      }
    ]
  }));

  return { headers, rows };
};
