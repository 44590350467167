import { Box } from "@mui/material";
import { Image } from "ui/components";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteSendingDomain } from "core/store/entities/Domain/domain.actions";
import ConfirmationDialog from "./ConfirmationPopup";

export const Delete = ({ item }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);

  const handleDeleteClick = () => {
    console.log("CHECK");
    setShowDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSendingDomain({ domain_id: item.domain_id }));
    setShowDialog(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <Box>
      <Box sx={{ cursor: "pointer" }} onClick={handleDeleteClick}>
        <Image name="trashCan" height={15} width={15} />
      </Box>
      <ConfirmationDialog
        open={showDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        domainName={item.orig_domain}
      />
    </Box>
  );
};
