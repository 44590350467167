import { Box, Typography, styled } from "@mui/material";
import graphStatsAscend from "ui/assets/images/arrow-trend-up-regular.svg";
import graphStatsDescend from "ui/assets/images/arrow-trend-down-regular.svg";
import graphStatsAscendInverted from "ui/assets/images/arrow-trend-up-regular-inverted.svg";
import graphStatsDescendInverted from "ui/assets/images/arrow-trend-down-regular-inverted.svg";

export const DiffStatusContainer = styled(Typography)(({ fixedWidth, inverted, up }) => ({
  display: "flex",
  ...(fixedWidth && {
    justifyContent: "space-between",
    width: "70px"
  }),
  ...(inverted
    ? {
        ...(up
          ? {
              color: "#757575",
              [`& > div`]: {
                backgroundImage: `url(${graphStatsAscendInverted})`
              }
            }
          : {
              color: "#3DAE79",
              [`& > div`]: {
                backgroundImage: `url(${graphStatsDescendInverted})`
              }
            })
      }
    : {
        ...(up
          ? {
              color: "#3DAE79",
              [`& > div`]: {
                backgroundImage: `url(${graphStatsAscend})`
              }
            }
          : {
              color: "#757575",
              [`& > div`]: {
                backgroundImage: `url(${graphStatsDescend})`
              }
            })
      })
}));

export const DiffIcon = styled(Box)(() => ({
  width: "25px",
  minWidth: "25px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left center",
  marginRight: "3px"
}));
