import { Alert, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { Input, ShowPasswordIcon, Button } from "ui/components";
import React from "react";
import { useChangePasswordForm } from "./useChangePasswordForm";

export const ChangePasswordForm = ({ rowSpacing }) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    showNewPassword,
    showConfirmPassword,
    toggleShowNewPassword,
    toggleShowConfirmPassword
  } = useChangePasswordForm();

  return (
    <Grid
      container
      justifyContent="center"
      pl="22px"
      rowSpacing={rowSpacing}
      mt={0}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {!!errors?.nonFieldErrors && (
        <Grid item xs={12}>
          <Alert variant="filled" severity="error">
            {errors?.nonFieldErrors}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="newPassword"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              id="newPassword"
              label="New password"
              name="newPassword"
              error={!!errors?.newPassword?.message}
              helperText={errors?.newPassword?.message}
              type={showNewPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <ShowPasswordIcon show={showNewPassword} toggle={toggleShowNewPassword} />
                )
              }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              id="confirmPassword"
              label="Confirm new password"
              name="confirmPassword"
              error={!!errors?.confirmPassword?.message}
              helperText={errors?.confirmPassword?.message}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <ShowPasswordIcon show={showConfirmPassword} toggle={toggleShowConfirmPassword} />
                )
              }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" fullWidth sx={{ mb: 2 }}>
          Change password
        </Button>
      </Grid>
    </Grid>
  );
};
