import { Link } from "ui/components";
import { SETUP_DOMAIN_SCREEN } from "core/constants";
import { Typography } from "@mui/material";
import { formatUrl } from "helpers";
import React from "react";

export const SendingDomain = ({ item }) => {
  return (
    <Link to={`${SETUP_DOMAIN_SCREEN}/${item.domain_id}`}>
      <Typography variant="bodyM">
        {item.sending_domain ? formatUrl(item.sending_domain) : ""}
      </Typography>
    </Link>
  );
};
