import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { Image } from "../Image";
import { StyledNavItem, StyledSubNavItem } from "./styled";
import { usePreventDefault } from "hooks";

export const NavItem = ({
  id,
  to,
  isActive,
  isDisabled,
  onClick,
  name,
  icon,
  items,
  showItems
}) => {
  const handleClick = usePreventDefault(onClick);
  return (
    <Box>
      <StyledNavItem
        to={to}
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick && handleClick}
        data-testid={`nav-link-${id}`}
      >
        <Typography color="white" variant="bodyXLBold">
          {name}
        </Typography>
        <Image name={icon} height={20} width={30} />
      </StyledNavItem>
      {showItems && (
        <List sx={{ p: 0 }}>
          {items?.map((item, index) => (
            <ListItem key={index} sx={{ p: 0, pb: "10px" }}>
              <StyledSubNavItem
                to={item.to}
                isActive={item.isActive && !item.isDisabled}
                isDisabled={item.isDisabled}
              >
                <Typography variant="navLinkSmall" color="inherit">
                  {item.name}
                </Typography>
              </StyledSubNavItem>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
