import React from "react";
import { Grid, Typography } from "@mui/material";
import { ChangePasswordForm, Image, Link } from "ui/components";
import { CREATE_FREE_ACCOUNT_SCREEN } from "core/constants";

export const ChooseNewPassword = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" minHeight="100vh">
      <Grid
        item
        xs={8}
        md={5}
        lg={4}
        xl={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image name="inboxroadIcon" height="35px" />
        <Typography variant="headlineM" mt={5}>
          Choose new password
        </Typography>
        <ChangePasswordForm rowSpacing={3} />
        <Grid item xs textAlign="center">
          <Typography variant="bodyS">
            No account yet? <Link to={CREATE_FREE_ACCOUNT_SCREEN}>Start here</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
