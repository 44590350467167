import { Grid, Stack } from "@mui/material";
import { SetupStepContainer } from "./SetupStepContainer";
import { ResendEmail } from "./ResendEmail";
import { AddDomain } from "./AddDomain";
import { Connect } from "./Connect";
import { VerifyDomain } from "./VerifyDomain";
import { useSelector } from "react-redux";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const SetupFlow = () => {
  const user = useSelector(userSelectors.user);
  return (
    <Grid item xs={12} lg={8} xl={7.9}>
      <Stack spacing={1.5}>
        <SetupStepContainer stepNumber={1} text="Create your Inboxroad account" />
        <SetupStepContainer stepNumber={2} text="Verify your email address">
          <ResendEmail />
        </SetupStepContainer>
        <SetupStepContainer stepNumber={3} text="Add and verify a sending domain">
          {user.hasDomain ? <VerifyDomain /> : <AddDomain />}
        </SetupStepContainer>
        <SetupStepContainer stepNumber={4} text="Connect through API or SMTP">
          <Connect />
        </SetupStepContainer>
      </Stack>
    </Grid>
  );
};
