import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { Button, TextButton } from "ui/components";

const ConfirmationDialog = ({ open, onClose, onConfirm, domainName }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Delete domain</DialogTitle>
      <DialogContent
        sx={{
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography variant="body1" gutterBottom>
          You are about to delete your sending domain:
          <Typography variant="body1">{domainName}</Typography>
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold", marginTop: "1rem" }}>
          Are you sure?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", padding: "16px 24px" }}>
        <Button size="small" onClick={onConfirm}>
          Yes, delete my domain
        </Button>
        <TextButton underline onClick={onClose}>
          <Typography sx={{ fontSize: "14px", marginLeft: "5px" }}>
            No, I&apos;m not sure
          </Typography>
        </TextButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
