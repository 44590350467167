import React from "react";
import { Header } from "ui/components";
import { Grid } from "@mui/material";

import { CurrentBillingCycle } from "./CurrentBillingCycle";
import { BillingAccount } from "./BillingAccount";
import { BillingInformation } from "./BillingInformation";
import { InvoicesTable } from "./InvoicesTable";
import { useBilling } from "./useBilling";
import { PaymentMethods } from "./PaymentMethods";
import { generalContentPadding } from "ui/components/Layout";

export const Billing = () => {
  useBilling();

  return (
    <>
      <Header title="Account and billing" />
      <Grid container rowSpacing={6.5} sx={{ padding: generalContentPadding }}>
        <CurrentBillingCycle />
        <BillingAccount />
        <PaymentMethods />
        <BillingInformation />
        <InvoicesTable />
      </Grid>
    </>
  );
};
