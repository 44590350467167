import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export const CloseIcon = ({ onClose }) => {
  return (
    <IconButton
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: theme => theme.palette.black
      }}
    >
      <Close sx={{ fontSize: "16px" }} />
    </IconButton>
  );
};
