import { Box, Stack, Typography, styled } from "@mui/material";

export const IllustrationContainer = styled(Stack)(({ theme }) => ({
  position: "relative",
  height: "100%",
  marginLeft: 14,
  backgroundColor: theme.palette.gray[50],
  padding: "28px 28px 25px 28px"
}));

export const IllustrationContentContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  padding: "20px 30px 22px 20px",
  borderRadius: "4px",
  boxShadow: "0 6px 24px 0 rgb(0 0 0 / 12%)",
  marginBottom: 21
}));

export const IllustrationAvatar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.gray[50],
  width: "36px",
  height: "36px"
}));

export const IllustrationTitle = styled(Typography)(() => ({
  fontSize: "13px",
  fontWeight: 700,
  letterSpacing: 0.2,
  lineHeight: "22px"
}));

export const IllustrationSubTitle = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 400,
  letterSpacing: 0.3,
  lineHeight: "10px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  "&:after": {
    content: "''",
    width: 0,
    height: 0,
    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    borderTop: "6px solid",
    opacity: "40%",
    marginLeft: "4px"
  }
}));

export const IllustrationEmail = styled(Typography)(() => ({
  fontSize: "13px",
  fontWeight: 400,
  letterSpacing: 0.2,
  lineHeight: "22px"
}));

export const IllustrationEmailDomain = styled(IllustrationEmail)(({ theme }) => ({
  fontSize: "13px",
  fontWeight: 400,
  letterSpacing: 0.2,
  textDecoration: "underline",
  textDecorationColor: theme.palette.green[400],
  textDecorationThickness: "2px"
}));

export const IllustrationPin = styled(Box)(({ theme }) => ({
  height: "5px",
  width: "5px",
  borderRadius: "50%",
  position: "absolute",
  top: "59%",
  left: "50%",
  backgroundColor: theme.palette.green[400],
  "&:before": {
    content: "''",
    display: "block",
    position: "absolute",
    zIndex: 1,
    top: "100%",
    left: "40%",
    border: "1px solid",
    borderWidth: "0 0 0 1px",
    width: "1px",
    height: "28px",
    color: theme.palette.green[400]
  }
}));

export const IllustrationSkeletonBox = styled(Box)(({ theme, size }) => ({
  backgroundColor: theme.palette.gray[50],
  borderRadius: "1px",
  ...(size === "large" && {
    width: "100%",
    height: "20px",
    marginTop: 16
  }),
  ...(size === "small" && {
    width: "85%",
    height: "10px",
    marginTop: "10px"
  })
}));
