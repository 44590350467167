import { TableBodyCell, TableBodyRow } from "../../styled";
import { TableBody as TableBodyMUI } from "@mui/material";
import React from "react";

export const TableBody = ({ data }) => {
  return (
    <TableBodyMUI>
      {data.map(item => (
        <TableBodyRow key={item.key} sx={item.sx}>
          {item.columns.map((column, index) => (
            <TableBodyCell
              key={index}
              align={column.align}
              colSpan={column.colSpan}
              sx={{ paddingRight: "10px" }}
            >
              {column.content}
            </TableBodyCell>
          ))}
        </TableBodyRow>
      ))}
    </TableBodyMUI>
  );
};
