import { Alert, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useInvoicesTable } from "./useInvoicesTable";
import { Table, Spinner, RegularButton } from "ui/components";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "core/store/entities/Billing/billing.actions";
import { billingInvoicesSelectors } from "core/store/entities/Billing/billing.selectors";

export const InvoicesTable = () => {
  const dispatch = useDispatch();
  const hasMoreInvoices = useSelector(billingInvoicesSelectors.hasMore);
  const { headers, rows, isInvoicesLoading, invoicesErrors, noInvoicesText } = useInvoicesTable();

  const handleMore = () => {
    dispatch(getInvoices());
  };

  return (
    <Grid container item xs mt={1}>
      <Stack spacing={2} width="100%" alignItems="flex-start">
        <Typography variant="sectionHeaderS">Invoices</Typography>
        {!!invoicesErrors && (
          <Alert variant="filled" severity="error">
            {invoicesErrors?.detail}
          </Alert>
        )}
        {isInvoicesLoading ? (
          <Spinner />
        ) : (
          <Table headers={headers} data={rows} emptyText={noInvoicesText} />
        )}
        <RegularButton onClick={handleMore} disabled={!hasMoreInvoices}>
          Show older invoices
        </RegularButton>
      </Stack>
    </Grid>
  );
};
