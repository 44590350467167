import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { format as formatPrice } from "helpers/price";
import { formatDate } from "helpers/date";
import downloadIcon from "ui/assets/images/download-icon.png";
import React from "react";
import { DownloadInvoiceButton } from "./styled";
import { billingInvoicesSelectors } from "core/store/entities/Billing/billing.selectors";

export const useInvoicesTable = () => {
  const invoicesData = useSelector(billingInvoicesSelectors.data);
  const isInvoicesLoading = useSelector(billingInvoicesSelectors.isLoading);
  const invoicesErrors = useSelector(billingInvoicesSelectors.error);
  const noInvoicesText = "No invoices to display yet";

  const headers = [
    {
      name: "Month",
      align: "left"
    },
    {
      name: "Invoice number",
      align: "left"
    },
    {
      name: "Amount",
      align: "left"
    },
    {
      name: "Invoice date",
      align: "left"
    },
    {
      name: "Download invoice",
      align: "left"
    }
  ];

  const rows = invoicesData.map((invoice, index) => ({
    key: index,
    columns: [
      {
        align: "left",
        content: <Typography variant="bodyM">{invoice.month}</Typography>
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{invoice.number}</Typography>
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{formatPrice(invoice)}</Typography>
      },
      {
        align: "left",
        content: <Typography variant="bodyM">{formatDate(invoice.invoice_date)}</Typography>
      },
      {
        align: "left",
        content: (
          <DownloadInvoiceButton href={invoice?.invoice_pdf} target="_blank">
            <img src={downloadIcon} alt="download" />{" "}
            <Typography variant="bodyM" color="inherit">
              Download invoice (pdf)
            </Typography>
          </DownloadInvoiceButton>
        )
      }
    ]
  }));

  return { headers, rows, isInvoicesLoading, invoicesErrors, noInvoicesText };
};
