import { useDispatch, useSelector } from "react-redux";

import { hideUpgradePlanConfirmation } from "core/store/entities/UpgradePlan/upgradePlan.actions";
import { upgradePlanSelectors } from "core/store/entities/UpgradePlan/upgradePlan.selectors";

export const useUpgradePlanConfirmation = () => {
  const dispatch = useDispatch();
  const show = useSelector(upgradePlanSelectors.showUpgradePlanConfirmation);

  const handleClose = () => {
    dispatch(hideUpgradePlanConfirmation());
  };

  return { show, handleClose };
};
