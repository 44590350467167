export const formatUrl = url => {
  return url.replace(/http:\/\/|https:\/\/|www.|\//gi, "");
};

export const roundToPoint = (value, exponent = 1) => {
  return Math.round(value * Math.pow(10, exponent)) / Math.pow(10, exponent);
};

export const numberWithDots = x => {
  const num = x ? x.toString() : "0";
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
