export const filterOptions = [
  { label: "Last 30 days", value: 30 },
  { label: "Last 14 days", value: 14 },
  { label: "Last 7 days", value: 7 },
  { label: "Last 3 days", value: 3 },
  { label: "Yesterday", value: 1 },
  { label: "Today", value: 0 }
];

export const filterLogOptions = [
  { label: "Last 7 days", value: 7 },
  { label: "Last 3 days", value: 3 },
  { label: "Yesterday", value: 1 }
];
