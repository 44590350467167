import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import React from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { Button, Input, Spinner, StripeInput } from "ui/components";
import { useAddCreditCardForm } from "./useAddCreditCardForm";
import { Controller } from "react-hook-form";

export const AddCreditCardForm = () => {
  const {
    control,
    stripe,
    handleSubmit,
    onSubmit,
    errors,
    addPaymentMethodError,
    isAddingLoading
  } = useAddCreditCardForm();

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="100%"
      spacing={2.5}
      ml={0}
    >
      <Grid item xs={12}>
        <Typography variant="bodyL">Add a new credit card</Typography>
      </Grid>
      {!!addPaymentMethodError?.message && (
        <Grid item xs={12}>
          <Alert variant="filled" severity="error">
            {addPaymentMethodError.message}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="cardholderName"
          control={control}
          render={({ field }) => (
            <Input
              id="cardholderName"
              label="Cardholder Name"
              name="cardholderName"
              fullWidth
              error={!!errors?.cardholderName?.message}
              helperText={errors?.cardholderName?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          fullWidth
          id="cardNumber"
          label="Credit Card Number"
          placeholder="Credit Card Number"
          name="cardNumber"
          error={addPaymentMethodError?.code?.includes("number")}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement
            }
          }}
        />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <Input
            fullWidth
            id="cvc"
            label="Cvc code"
            name="cvc"
            error={addPaymentMethodError?.code?.includes("cvc")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            fullWidth
            id="expiry"
            label="Expiration date"
            name="expiry"
            error={addPaymentMethodError?.code?.includes("expiry")}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button sx={{ mt: 1 }} type="submit" fullWidth disabled={!stripe} loading={true}>
          {isAddingLoading ? <Spinner /> : " Add credit card"}
        </Button>
      </Grid>
    </Grid>
  );
};
