import React from "react";
import { Stack, Typography } from "@mui/material";
import { Spinner, Table } from "ui/components";
import { useBouncesTable } from "./useBouncesTable";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const BouncesTable = () => {
  const { isLoading, headers, rows } = useBouncesTable();
  const { currentPeriodText } = useCurrentPeriodText();

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="end" spacing={2}>
        <Typography variant="sectionHeaderS">Top bounces</Typography>
        <Typography variant="bodyXS">{currentPeriodText}</Typography>
      </Stack>

      {isLoading ? <Spinner /> : <Table headers={headers} data={rows} />}
    </Stack>
  );
};
