import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSendingDomains, verifyDomain } from "core/store/entities/Domain/domain.actions";
import { DnsRecords } from "./DnsRecords";
import { VerifyDomainButton } from "./styled/VerifyDomainButton";
import React, { useEffect } from "react";
import { domainSelectors } from "core/store/entities/Domain/domain.selectors";

export const useSetupDomain = () => {
  const domains = useSelector(domainSelectors.domains);
  const { domainId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!domains.length) {
      dispatch(getSendingDomains());
    }
  }, [domains]);

  const domainName = domains.find(domain => domain.domain_id == domainId)?.sending_domain;

  const handleVerifyDomain = () => {
    dispatch(verifyDomain({ domain_id: domainId }));
  };
  const steps = [
    {
      title: "Login to your webhosting company (DNS host):"
    },
    {
      title: "Copy the DNS records below to your hosting provider's DNS section:",
      content: <DnsRecords />
    },
    {
      title: "Once you added these DNS records, press ‘Verify domain’",
      content: <VerifyDomainButton onClick={handleVerifyDomain}>Verify domain</VerifyDomainButton>
    }
  ];

  return { steps, domainName };
};
