import { upgradePlanActions } from "./upgradePlan.slice";

export const {
  showUpgradePlanModal,
  hideUpgradePlanModal,
  showUpgradePlanConfirmation,
  hideUpgradePlanConfirmation,

  sendUpgradePlan,
  sendUpgradePlanSuccess,
  sendUpgradePlanFailed
} = upgradePlanActions;
