import { messagesReducers } from "./messages.reducers";

const { createSlice } = require("@reduxjs/toolkit");

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    onSuccessCallback: () => {},
    showMessageModal: false,
    customerSupportSent: false,
    themes: [],
    modalTitle: "",
    subject: "",
    errors: [],
    modalErrors: []
  },
  reducers: messagesReducers
});

const messagesActions = messagesSlice.actions;
const messagesReducer = messagesSlice.reducer;

export { messagesActions, messagesReducer };
