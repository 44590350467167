import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_BILLING_INFO_MODAL } from "core/constants";
import { hideModal } from "core/store/entities/Modal/modal.actions";
import { editBillingData } from "core/store/entities/Billing/billing.actions";
import { modalSelectors } from "core/store/entities/Modal/modal.selectors";
import { billingDataSelectors } from "core/store/entities/Billing/billing.selectors";

export const useEditBillingInformation = () => {
  const dispatch = useDispatch();
  const billingData = useSelector(billingDataSelectors.data);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      companyName: billingData.companyName,
      email: billingData.email,
      phone: billingData.phone,
      firstAddressLine: billingData.firstAddressLine,
      secondAddressLine: billingData.secondAddressLine,
      postalCode: billingData.postalCode,
      city: billingData.city,
      country: billingData.country,
      state: billingData.state
    }
  });
  const errors = useSelector(billingDataSelectors.error);

  const isEditBillingInfoModalOpen = useSelector(modalSelectors[EDIT_BILLING_INFO_MODAL]);

  const onSubmit = data => {
    dispatch(editBillingData(data));
  };

  const onClose = () => {
    dispatch(hideModal(EDIT_BILLING_INFO_MODAL));
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    onClose,
    errors,
    isEditBillingInfoModalOpen
  };
};
