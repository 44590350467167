export const billingReducers = {
  getBillingData: state => {
    state.billingData = {
      ...state.billingData,
      isLoading: true,
      error: null
    };
  },
  getBillingDataSuccess: (state, { payload }) => {
    state.billingData = {
      isLoading: false,
      error: null,
      data: payload
    };
  },
  getBillingDataFailed: (state, { payload }) => {
    state.billingData = {
      ...state.billingData,
      isLoading: false,
      error: payload
    };
  },
  editBillingData: state => {
    state.billingData = {
      ...state.billingData,
      isLoading: true,
      error: null
    };
  },
  editBillingDataSuccess: (state, { payload }) => {
    state.billingData = {
      isLoading: false,
      error: null,
      data: payload
    };
  },
  editBillingDataFailed: (state, { payload }) => {
    state.billingData = {
      ...state.billingData,
      isLoading: false,
      error: payload
    };
  },
  getInvoices: state => {
    state.invoices = {
      ...state.invoices,
      isLoading: true,
      error: null
    };
  },
  getInvoicesSuccess: (state, { payload }) => {
    state.invoices = {
      ...state.invoices,
      data: [...state.invoices.data, ...payload],
      isLoading: false,
      error: null
    };
  },
  getInvoicesStartSuccess: (state, { payload }) => {
    state.invoices = {
      ...state.invoices,
      data: [...payload],
      isLoading: false,
      error: null
    };
  },
  getInvoicesFailed: (state, { payload }) => {
    state.invoices = {
      ...state.invoices,
      isLoading: false,
      error: payload
    };
  },
  invoicesPagination: (state, { payload }) => {
    state.invoices = {
      ...state.invoices,
      ...payload
    };
  },
  getSubscriptionInfo: state => {
    state.subscription = {
      ...state.subscription,
      isLoading: true,
      error: null
    };
  },
  getSubscriptionInfoSuccess: (state, { payload }) => {
    state.subscription = {
      isLoading: false,
      error: null,
      data: payload
    };
  },
  getSubscriptionInfoFailed: (state, { payload }) => {
    state.subscription = {
      ...state.subscription,
      isLoading: false,
      error: payload
    };
  },
  getPaymentMethods: state => {
    state.paymentMethods = {
      ...state.paymentMethods,
      isProccessingDelete: null,
      isLoading: true,
      error: null
    };
  },
  getPaymentMethodsSuccess: (state, { payload }) => {
    state.paymentMethods = {
      ...state.paymentMethods,
      isLoading: false,
      error: null,
      data: payload
    };
  },
  getPaymentMethodsFailed: (state, { payload }) => {
    state.paymentMethods = {
      ...state.paymentMethods,
      isLoading: false,
      error: payload
    };
  },
  addPaymentMethod: state => {
    state.paymentMethods = {
      ...state.paymentMethods,
      isAdding: true,
      addError: null
    };
  },
  addPaymentMethodSuccess: state => {
    state.paymentMethods = {
      ...state.paymentMethods,
      addError: null,
      isAdding: false
    };
  },
  addPaymentMethodFailed: (state, { payload }) => {
    state.paymentMethods = {
      ...state.paymentMethods,
      isAdding: false,
      addError: payload
    };
  },
  removePaymentMethod: (state, { payload }) => {
    state.paymentMethods = {
      ...state.paymentMethods,
      paymentMethodToDelete: payload,
      errorDeleting: null
    };
  },
  removePaymentMethodFailed: (state, { payload }) => {
    state.paymentMethods = {
      ...state.paymentMethods,
      paymentMethodToDelete: null,
      errorDeleting: payload,
      error: payload
    };
  },
  selectPaymentMethod: () => {}
};
