import {
  PaymentCardActions,
  PaymentCardBody,
  PaymentCardContainer,
  PaymentCardData,
  PaymentCardInfo,
  PaymentCardLoader,
  PaymentCardNumber,
  PaymentCardSelected
} from "./styled";
import { Typography } from "@mui/material";
import { Divider, Image, Spinner, TextButton } from "ui/components";
import { Check } from "@mui/icons-material";
import React from "react";
import { usePaymentCard } from "./usePaymentCard";

export const PaymentCard = ({
  id,
  name,
  expirationDate,
  cardLastDigits,
  brand,
  isSelected,
  isDeleteDisabled,
  isLoading
}) => {
  const { icon, removePaymentCard, selectPaymentCard } = usePaymentCard({
    id,
    brand,
    isSelected
  });
  return (
    <PaymentCardContainer
      isLoading={isLoading}
      isSelected={isSelected}
      gap={2}
      onClick={!isSelected ? selectPaymentCard : null}
    >
      {isLoading && (
        <PaymentCardLoader>
          <Spinner size={50} />
        </PaymentCardLoader>
      )}
      <PaymentCardBody direction="row" spacing={1}>
        <Image name={icon} width="auto" height={32} />
        <PaymentCardData>
          <PaymentCardNumber spacing={1} direction="row">
            <Typography variant="bodyMBold">{brand}</Typography>
            <Divider orientation="vertical" sx={{ height: "13px !important" }} />
            <Typography variant="bodyMBold" fontSize={20} pt={1}>
              ****
            </Typography>
            <Typography variant="bodyMBold">{cardLastDigits}</Typography>
          </PaymentCardNumber>
          <PaymentCardInfo>
            <Typography variant="bodyS">{name}</Typography>
            <Typography variant="bodyS">{expirationDate}</Typography>
          </PaymentCardInfo>
        </PaymentCardData>
      </PaymentCardBody>
      <PaymentCardActions direction="row" justifyContent="flex-end">
        {isSelected && (
          <PaymentCardSelected direction="row" spacing={0.5} alignItems="start" flex={1}>
            <Check fontSize="small" />
            <Typography variant="bodySBold">Selected</Typography>
          </PaymentCardSelected>
        )}
        <TextButton
          disabled={isDeleteDisabled}
          startIcon={<Image name="trashCan" height={16} width={14} />}
          onClick={removePaymentCard}
        >
          Remove
        </TextButton>
      </PaymentCardActions>
    </PaymentCardContainer>
  );
};
