import { ReactComponent as Plus } from "ui/assets/images/plus.svg";
import { PlusIconContainer } from "./styled";

export const PlusIcon = () => {
  return (
    <PlusIconContainer>
      <Plus />
    </PlusIconContainer>
  );
};
