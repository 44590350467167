import { Typography } from "@mui/material";
import React from "react";
import { Link } from "ui/components";
import { DASHBOARD_SENDING_DOMAINS_SCREEN } from "core/constants";

export const VerifyDomain = () => {
  return (
    <Typography variant="bodyM">
      You will need to <Link to={DASHBOARD_SENDING_DOMAINS_SCREEN}>verify</Link> your domain in
      order to continue to the next step
    </Typography>
  );
};
