import React from "react";
import { Box } from "@mui/material";
import { FullscreenSpinner, Header, Stepper } from "ui/components";
import { generalContentPadding } from "ui/components/Layout";
import { ARROW_RIGHT_SYMBOL } from "core/constants";
import { useSetupDomain } from "./useSetupDomain";

export const SetupDomain = () => {
  const { steps, domainName } = useSetupDomain();

  if (!domainName) return <FullscreenSpinner />;

  return (
    <>
      <Header
        title={`Okay, let's now set up your domain ${ARROW_RIGHT_SYMBOL} ${domainName}`}
        sx={{
          flexDirection: { xs: "column-reverse", sm: "row" },
          wordBreak: { xs: "break-word", sm: "normal" },
          gap: { xs: 2, sm: 0 }
        }}
      />
      <Box sx={{ padding: { ...generalContentPadding, lg: "0 128px" } }}>
        <Stepper steps={steps} firstStepDivider />
      </Box>
    </>
  );
};
