import { formatPercentage } from "./percentage";

export function format(number, separator = " ") {
  const num = number ? number.toString() : "0";
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const getLongestNumber = (array, key) => {
  return Math.max(...array.map(item => `${item[key]}`.length || 0));
};

export const getLongestPercentageValue = (array, key, percentageKey) => {
  return Math.max(
    ...array.map(item => {
      const value = item[key] ? formatPercentage(100 - item[percentageKey]) : 0;
      return `${value}`.length || 0;
    })
  );
};
