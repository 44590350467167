import { formatUrl } from "helpers";

export const domainReducers = {
  getSendingDomains: state => {
    state.isSendingDomainsLoading = true;
  },
  getSendingDomainsSuccess: (state, { payload }) => {
    state.domainsOptions = payload.map(domain => ({
      label: formatUrl(domain.orig_domain),
      value: domain.domain_id
    }));

    state.domains = payload;
    state.isSendingDomainsLoading = false;
  },
  getSendingDomainsFailed: (state, { payload }) => {
    state.errors = payload;
    state.isSendingDomainsLoading = false;
  },
  addSendingDomain: () => {},
  addSendingDomainSuccess: () => {},
  addSendingDomainFailed: () => {},
  deleteSendingDomain: () => {},
  deleteSendingDomainSuccess: (state, { payload }) => {
    state.domains = state.domains.filter(domain => domain?.domain_id !== payload.domain_id);
  },
  deleteSendingDomainFailed: (state, payload) => {
    state.errors = payload;
  },
  getDnsRecords: () => {},
  getDnsRecordsSuccess: (state, { payload }) => {
    state.dnsRecords = payload;
  },
  getDnsRecordsFailed: (state, { payload }) => {
    state.errors = payload;
  },
  verifyDomain: () => {},
  verifyDomainSuccess: state => {
    state.errors = [];
  },
  verifyDomainFailed: (state, { payload }) => {
    state.errors = payload;
  }
};
