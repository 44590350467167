import { Dialog as MUIDialog, dialogClasses, dialogContentClasses, styled } from "@mui/material";

export const StyledDialog = styled(MUIDialog)(({ paperStyles }) => ({
  [`& .${dialogContentClasses.root}`]: {
    padding: "0"
  },
  [`& .${dialogClasses.paper}`]: {
    ...paperStyles
  }
}));
