import { useSelector } from "react-redux";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useDeliveriesBounces = () => {
  const isDomainsOptionsLoading = useSelector(statisticsSelectors.isDomainsOptionsLoading);
  const deliveriesAndBounces = useSelector(statisticsSelectors.deliveriesAndBounces);
  const isDeliveriesAndBouncesLoading = useSelector(
    statisticsSelectors.isDeliveriesAndBouncesLoading
  );

  return {
    isLoading: isDomainsOptionsLoading || isDeliveriesAndBouncesLoading,
    deliveriesAndBounces
  };
};
