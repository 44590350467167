import React from "react";

import { Link } from "react-router-dom";

import { DASHBOARD_SCREEN } from "core/constants";
import { Box, List, ListItem } from "@mui/material";
import { Image, NavItem } from "../index";
import { useNavbar } from "./useNavbar";
import { ProgressBlock } from "./ProgressBlock";
import { useSelector } from "react-redux";
import { NavbarContainer } from "./styled";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const Navbar = () => {
  const { items } = useNavbar();
  const user = useSelector(userSelectors.user);

  return (
    <NavbarContainer
      sx={{
        minWidth: "264px",
        backgroundColor: "black",
        padding: "20px 15px",
        height: "inherit"
      }}
    >
      <Box flex={1}>
        <Link to={DASHBOARD_SCREEN}>
          <Image name="inboxroadIconSmall" />
        </Link>
        <List
          sx={{
            p: "0",
            mt: "28px"
          }}
        >
          {items.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                display: "block"
              }}
            >
              <NavItem
                id={item.id}
                to={item.to}
                name={item.name}
                icon={item.icon}
                onClick={item.onClick}
                isActive={item.isActive}
                isDisabled={item.isDisabled}
                showItems={item.showItems}
                items={item.items}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      {!user.isSetupFinished && <ProgressBlock />}
    </NavbarContainer>
  );
};
