import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCustomerProfile, fetchMailingSystems } from "core/store/entities/User/user.actions";
import * as yup from "yup";
import { userSelectors } from "core/store/entities/User/user.selectors";

const schema = yup.object({
  email: yup.string().required("This field is required"),
  password: yup.string().required("This field is required"),
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  companyName: yup
    .string()
    .required("This field is required")
    .matches("[a-zA-Z]+", "Company name should contain at least one letter"),
  mailingSystem: yup.number().required("This field is required")
});

export const useCreateAccountStep2 = () => {
  const apiErrors = useSelector(userSelectors.errors);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const mailingSystemsOptions = useSelector(userSelectors.mailingSystemsOptions);
  const isMailingSystemsLoading = useSelector(userSelectors.isMailingSystemsLoading);
  const email = useSelector(userSelectors.email);

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email
    }
  });
  const errors = { ...apiErrors, ...formErrors };

  useEffect(() => {
    dispatch(fetchMailingSystems());
  }, []);

  const onSubmit = data => {
    dispatch(createCustomerProfile(data));
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    handleClickShowPassword,
    showPassword,
    mailingSystemsOptions,
    isMailingSystemsLoading
  };
};
