import { Header } from "../../components";
import { Grid } from "@mui/material";
import React from "react";
import { DownloadLogsText } from "./DownloadLogsText";
import { BounceLogsImportanceText } from "./BounceLogsImportanceText";
import { DownloadLogs } from "./DownloadLogs";

export const LogFiles = () => {
  return (
    <>
      <Header
        title="Log files"
        sx={{ padding: { xs: "28px 30px 56px", sm: "28px 40px 56px 64px" } }}
      />
      <Grid container sx={{ padding: { xs: "0 30px 64px", sm: "0 64px 64px" } }}>
        <Grid item container columnGap={6}>
          <Grid item container xs={10} sm={8} md={6} direction="column">
            <DownloadLogsText />
            <DownloadLogs />
          </Grid>
          <Grid item xs={12} lg={5}>
            <BounceLogsImportanceText />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
