import { styled } from "@mui/material";
import { NavLink as NavLinkRR } from "react-router-dom";

export const StyledNavItem = styled(NavLinkRR)(({ isActive, isDisabled, theme }) => ({
  color: theme.palette.white,
  opacity: isActive ? "100%" : "60%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textDecoration: "none",
  "& > img": {
    display: isActive ? "block" : "none"
  },
  "&:hover": {
    color: theme.palette.white,
    opacity: "80%",
    "& > img": {
      display: "block"
    }
  },
  ...(isDisabled && {
    opacity: "20%",
    pointerEvents: "none",
    userSelect: "none"
  })
}));

export const StyledSubNavItem = styled(NavLinkRR)(({ isActive, isDisabled, theme }) => ({
  color: theme.palette.white,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textDecoration: "none",
  opacity: "60%",
  "&:hover": {
    color: theme.palette.white,
    opacity: "80%",
    textDecoration: "underline"
  },
  ...(isActive && {
    textDecoration: "underline",
    opacity: "100%"
  }),
  ...(isDisabled && {
    opacity: "20%",
    pointerEvents: "none"
  })
}));
