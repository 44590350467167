import { useState } from "react";

export const useCopyToClipboard = () => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const handleCopyToClipboard = text => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text).then(
        () => {
          setIsCopiedToClipboard(true);
        },
        () => {
          setIsCopiedToClipboard(false);
        }
      );
    } else {
      return document.execCommand("copy", true, text);
    }
    setTimeout(() => {
      setIsCopiedToClipboard(false);
    }, 1500);
  };

  return { isCopiedToClipboard, setIsCopiedToClipboard, handleCopyToClipboard };
};
