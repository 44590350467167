import { TableCell, styled } from "@mui/material";

export const TableBodyCell = styled(TableCell)(() => ({
  padding: "14px 0"
}));

export const TableBodyCellBordered = styled(TableCell)(({ alert, theme }) => ({
  border: "1px solid #D6D6D6",
  borderRadius: 2,
  padding: "15px 10px",
  ...(alert && {
    border: `1px solid ${theme.palette.error}`
  })
}));
