import { Box, Collapse, styled } from "@mui/material";
import { Button } from "ui/components";

export const StepContainer = styled(Box)(({ theme, disabled }) => ({
  border: "1px solid rgba(0, 0, 0, .16)",
  borderRadius: "4px",
  padding: "10px 0px 10px 10px",
  ...(disabled && {
    backgroundColor: "rgba(230, 230, 230, .25)",
    button: {
      pointerEvents: "none",
      userSelect: "none",
      "&:after": {
        backgroundColor: theme.palette.green[300]
      },
      "&:hover:after": {
        backgroundColor: theme.palette.green[300]
      },
      "&:active": {
        backgroundColor: theme.palette.green[300],
        boxShadow: "none"
      }
    },
    span: {
      opacity: "25%",
      textDecoration: "line-through"
    }
  })
}));

export const StepButton = styled(Button)(() => {
  return {
    height: "35px",
    minWidth: "40px",
    padding: "10px",
    pointerEvents: "none"
  };
});

export const SetupStepCollapse = styled(Collapse)(() => ({
  padding: "10px 50px"
}));
