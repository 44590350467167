import React from "react";
import { Stack, Typography } from "@mui/material";
import { Spinner, Table } from "ui/components";
import { useISPTable } from "./useISPTable";
import { useCurrentPeriodText } from "hooks/useCurrentPeriodText";

export const ISPTable = () => {
  const { isLoading, headers, rows } = useISPTable();
  const { currentPeriodText } = useCurrentPeriodText({ compareToPrevious: true });

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="end" spacing={2}>
        <Typography variant="sectionHeaderS">ISP&apos;s sorted on volume</Typography>
        <Typography variant="bodyXS">{currentPeriodText}</Typography>
      </Stack>

      {isLoading ? <Spinner /> : <Table headers={headers} data={rows} />}
    </Stack>
  );
};
