import { ChangePasswordForm, Dialog } from "ui/components";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useChangePasswordModal } from "./useChangePasswordModal";

export const ChangePasswordModal = () => {
  const { isChangePasswordModalOpen, onClose } = useChangePasswordModal();

  return (
    <Dialog open={isChangePasswordModalOpen} onClose={onClose} paperStyles={{ width: "476px" }}>
      <Stack spacing={0.5} p="0 48px 32px 28px">
        <Typography variant="bodyL" ml={3}>
          Change password
        </Typography>
        <ChangePasswordForm rowSpacing={2.5} />
      </Stack>
    </Dialog>
  );
};
