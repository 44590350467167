import { Button, styled, Typography, buttonClasses } from "@mui/material";

export const StyledMenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.black,
  borderRadius: theme.shape.borderRadius,
  padding: "10px 10px 10px 0",
  textTransform: "none",
  height: "32px",
  transform: "skewX(-5deg)",
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: 2
  },
  boxShadow:
    "0 1px 0 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 16%), 0 0 3px 0px rgb(0 0 0 / 12%)",
  "&:hover": {
    boxShadow:
      "0 1px 0 0 rgb(0 0 0 / 12%), 0 1px 2px 0 rgb(0 0 0 / 16%), 0 0 3px 0px rgb(0 0 0 / 12%)"
  }
}));

export const ProfileLetter = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.white,
  backgroundColor: theme.palette.green[400],
  height: "32px",
  padding: "13px",
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius
}));
