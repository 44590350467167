import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../../../core/store/entities/User/user.actions";
import * as yup from "yup";
import { userSelectors } from "../../../../core/store/entities/User/user.selectors";

const schema = yup.object({ email: yup.string().required("This field is required") });

export const useResetPassword = () => {
  const dispatch = useDispatch();
  const apiErrors = useSelector(userSelectors.errors);
  const {
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm({ resolver: yupResolver(schema) });

  const errors = { ...apiErrors, ...formErrors };

  const onSubmit = data => {
    dispatch(resetPassword(data));
  };

  return { control, handleSubmit, onSubmit, errors };
};
