import { InputAdornment } from "@mui/material";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const ShowPasswordIcon = ({ show, toggle }) => {
  return (
    <InputAdornment position="end" onClick={toggle}>
      {show ? <Visibility /> : <VisibilityOff />}
    </InputAdornment>
  );
};
