import { billingReducers } from "./billing.reducers";

const { createSlice } = require("@reduxjs/toolkit");

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    subscription: {
      isLoading: false,
      error: null,
      data: {}
    },
    billingData: {
      isLoading: false,
      error: null,
      data: {}
    },
    invoices: {
      isLoading: false,
      hasMore: true,
      startingAfter: null,
      error: null,
      data: []
    },
    paymentMethods: {
      isLoading: false,
      paymentMethodToDelete: false,
      isAdding: false,
      errorDeleting: null,
      error: null,
      addError: null,
      data: []
    }
  },
  reducers: billingReducers
});

const billingActions = billingSlice.actions;
const billingReducer = billingSlice.reducer;

export { billingActions, billingReducer };
