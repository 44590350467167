import { IntegrationItemContainer } from "./styled";
import { Link, Stack, Typography } from "@mui/material";
import { Image } from "ui/components";

export const IntegrationItem = ({ integration: { title, text, icon, link } }) => {
  return (
    <Link href={link} target="_blank" underline="none">
      <IntegrationItemContainer direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Image name={icon} width={40} height={40} />
          <Stack ml={2}>
            <Typography variant="bodyLBold">{title}</Typography>
            <Typography variant="bodyM">{text}</Typography>
          </Stack>
        </Stack>
        <Image name="arrowRight" width={16} height={16} />
      </IntegrationItemContainer>
    </Link>
  );
};
