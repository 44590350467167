import { Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as UserImage } from "ui/assets/images/user-solid.svg";
import {
  IllustrationAvatar,
  IllustrationContainer,
  IllustrationContentContainer,
  IllustrationEmail,
  IllustrationEmailDomain,
  IllustrationPin,
  IllustrationSkeletonBox,
  IllustrationSubTitle,
  IllustrationTitle
} from "./styled";

export const Illustration = () => {
  return (
    <Grid item xs={12} lg={5} mt="11px">
      <IllustrationContainer>
        <IllustrationContentContainer>
          <Stack>
            <Stack direction="row" spacing={1.5}>
              <IllustrationAvatar>
                <UserImage fill="#0000000" opacity="12%" height={20} width={16} />
              </IllustrationAvatar>
              <Stack spacing={0.5}>
                <Stack direction="row" spacing={0.5}>
                  <IllustrationTitle>Your business</IllustrationTitle>
                  <Stack direction="row">
                    <IllustrationEmail>{"<email@"}</IllustrationEmail>
                    <IllustrationEmailDomain>your-business.com</IllustrationEmailDomain>
                    <IllustrationEmail>{">"}</IllustrationEmail>
                  </Stack>
                </Stack>
                <IllustrationSubTitle>to me</IllustrationSubTitle>
              </Stack>
            </Stack>
            <IllustrationSkeletonBox size="large" />
            <IllustrationSkeletonBox size="small" />
          </Stack>
        </IllustrationContentContainer>
        <Typography variant="bodyS" textAlign="center" alignSelf="center" maxWidth={355}>
          Adding a sending domain confirms recipients that emails are from your company
        </Typography>
        <IllustrationPin />
      </IllustrationContainer>
    </Grid>
  );
};
