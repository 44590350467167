import { Stepper } from "ui/components";
import React from "react";
import { Grid } from "@mui/material";
import { DetailsContainer } from "../DetailsContainer";
import { ReturnPathContainer } from "../ReturnPathContainer";

export const StepsContainer = () => {
  const steps = [
    {
      title: "Go to your email marketing software of choice"
    },
    {
      title: "Setup an account with these details:",
      content: <DetailsContainer />
    },
    {
      title: "Use the following Return Path Address",
      content: <ReturnPathContainer />
    }
  ];
  return (
    <Grid item xs={12} lg={8}>
      <Stepper steps={steps} firstStepDivider />
    </Grid>
  );
};
