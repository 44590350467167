import { call, put, takeLatest } from "redux-saga/effects";
import { getBillingData, getBillingDataFailed, getBillingDataSuccess } from "../billing.actions";
import { BillingService } from "core/api/services/BillingService";
import { BillingMapper } from "core/api";

function* handleGetBillingData() {
  try {
    const billingData = yield call(BillingService.getBillingData);
    yield put(getBillingDataSuccess(BillingMapper.billingDataToState(billingData)));
  } catch (error) {
    yield put(getBillingDataFailed(error));
  }
}

export default function* watchGetBillingData() {
  yield takeLatest(getBillingData.type, handleGetBillingData);
}
