export const GENERAL_TOKEN = "general_token";
export const PROFILE_TOKEN = "profile_token";
export const LOGOUT_TOKEN = "token_logout";
export const ADMIN_TOKEN = "admin_token";
export const CREATE_ACCOUNT_TOKEN = "create_account_token";
export const CREATE_ACCOUNT_EMAIL = "create_account_email";
export const CREATE_ACCOUNT_CURRENT_STEP = "create_account_step";

export const SELECTED_PROFILE = "selected_profile";
export const PROFILES = "profiles";
