import { Box, Stack, Typography, styled } from "@mui/material";

export const DnsRecordContainer = styled(Box)(({ theme, type }) => ({
  borderRadius: "2px",
  border: "1px solid #D6D6D6",
  ...(type === "error" && {
    border: `1px solid ${theme.palette.error.main}`
  }),
  ...(type === "success" && {
    border: `1px solid ${theme.palette.green[500]}`
  })
}));

export const StyledDnsRecordHeader = styled(Box)(() => ({
  paddingLeft: "10px"
}));

export const StyledDnsRecordItem = styled(Stack)(() => ({
  padding: "15px 10px",
  "&:not(:last-of-type)": {
    borderRight: "1px solid #D6D6D6"
  }
}));

export const StyledDnsRecordItemText = styled(Typography)(({ overflowWidth }) => ({
  display: "block",
  width: overflowWidth,
  textOverflow: "ellipsis",
  overflow: "hidden"
}));

export const DnsRecordParameters = styled(Stack)(() => ({
  borderBottom: "1px solid #D6D6D6"
}));

export const DnsRecordNote = styled(Typography)(() => ({
  maxWidth: "75%"
}));
