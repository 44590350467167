import React from "react";
import { Grid } from "@mui/material";

import { Header } from "ui/components";
import { StepsContainer } from "./StepsContainer";
import { IntegrationsContainer } from "./IntegrationsContainer";
import { generalContentPadding } from "ui/components/Layout";

export const ConnectSMTP = () => {
  return (
    <>
      <Header title="Connect through SMTP" />
      <Grid container columnSpacing={3} sx={{ padding: generalContentPadding }}>
        <StepsContainer />
        <IntegrationsContainer />
      </Grid>
    </>
  );
};
