import { call, put, takeEvery } from "redux-saga/effects";
import { BillingService } from "core/api/services/BillingService";
import {
  getSubscriptionInfo,
  getSubscriptionInfoFailed,
  getSubscriptionInfoSuccess
} from "../billing.actions";
import { BillingMapper } from "core/api";

function* handleGetSubscription() {
  try {
    const subscription = yield call(BillingService.getSubscriptionInfo);

    yield put(getSubscriptionInfoSuccess(BillingMapper.subscriptionDataToState(subscription)));
  } catch (error) {
    yield put(getSubscriptionInfoFailed(error));
  }
}

export default function* watchGetSubscription() {
  yield takeEvery(getSubscriptionInfo.type, handleGetSubscription);
}
