import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FilterSelect, Spinner } from "ui/components";
import { useOverall } from "./useOverall";
import { OverallBlock } from "ui/components";

export const Overall = () => {
  const {
    isGlobalStatsLoading,
    totalSent,
    totalSentDiff,
    filterOptions,
    filterValue,
    percentDelivered,
    percentDeliveredDiff,
    percentBounced,
    totalBouncedDiff,
    currentPeriodText,
    filterChangeHandler
  } = useOverall();

  return (
    <Grid item xs={12} mt={6}>
      <Stack direction="row" alignItems="end" mb={2} spacing={2}>
        <Typography variant="sectionHeaderS">Overall</Typography>
        <Typography variant="bodyXS">{currentPeriodText}</Typography>
        <FilterSelect
          options={filterOptions}
          value={filterValue}
          onChange={filterChangeHandler}
          style={{ marginLeft: "auto" }}
        />
      </Stack>
      {isGlobalStatsLoading ? (
        <Spinner />
      ) : (
        <OverallBlock
          totalSent={totalSent}
          totalSentDiff={totalSentDiff}
          percentDelivered={percentDelivered}
          percentDeliveredDiff={percentDeliveredDiff}
          percentBounced={percentBounced}
          totalBouncedDiff={totalBouncedDiff}
        />
      )}
    </Grid>
  );
};
