import { Button, FilterSelect, Alert } from "ui/components";
import React from "react";
import { useDownloadLogs } from "./useDownloadLogs";
import { filterLogOptions } from "core/constants";
import { Stack } from "@mui/material";

export const DownloadLogs = () => {
  const {
    selectedPeriod,
    handleChangePeriod,
    handleDownloadLogs,
    isDownloadingLogs,
    downloadingLogsSuccess,
    downloadingLogsError
  } = useDownloadLogs();

  return (
    <Stack gap={4}>
      <Stack direction="row" mt={4} columnGap={5}>
        <FilterSelect
          options={filterLogOptions}
          value={selectedPeriod}
          onChange={handleChangePeriod}
        />
        <Button size="small" onClick={handleDownloadLogs}>
          {isDownloadingLogs ? "Downloading..." : "Download"}
        </Button>
      </Stack>
      <Alert
        message={downloadingLogsError}
        severity="warning"
        sx={{ backgroundColor: "#ffc107" }}
      />
      <Alert message={downloadingLogsSuccess} severity="success" />
    </Stack>
  );
};
