import { all, fork } from "redux-saga/effects";
import watchGetBillingData from "./sagas/getBillingData";
import watchEditBillingData from "./sagas/editBillingData";
import watchGetInvoices from "./sagas/invoices";
import watchGetSubscription from "./sagas/subscription";
import watchGetPaymentMethods from "./sagas/getPaymentMethods";
import watchAddPaymentMethod from "./sagas/addPaymentMethod";
import watchRemovePaymentMethods from "./sagas/removePaymentMethod";
import watchSelectPaymentMethod from "./sagas/selectPaymentMethod";

export default function* billingSaga() {
  yield all([
    fork(watchGetBillingData),
    fork(watchEditBillingData),
    fork(watchGetInvoices),
    fork(watchGetSubscription),
    fork(watchGetPaymentMethods),
    fork(watchAddPaymentMethod),
    fork(watchRemovePaymentMethods),
    fork(watchSelectPaymentMethod)
  ]);
}
