import { statisticsActions } from "./statistics.slice";

export const {
  setFilterValue,

  fetchGlobalStats,
  fetchGlobalStatsSuccess,
  fetchGlobalStatsFailed,

  fetchPerDomainStatsTable,
  fetchPerDomainStatsTableSuccess,
  fetchPerDomainStatsTableFailed,

  fetchDomainsList,
  fetchDomainsListSuccess,
  fetchDomainsListFailed,
  selectDomain,

  filterDetailsChange,
  fetchGlobalStatsPerDomain,
  fetchGlobalStatsPerDomainSuccess,
  fetchGlobalStatsPerDomainFailed,

  fetchDeliveriesAndBounces,
  fetchDeliveriesAndBouncesSuccess,
  fetchDeliveriesAndBouncesFailed,

  downloadLogs,
  downloadLogsSuccess,
  downloadLogsFailed,
  downloadLogsResetFeedback,

  clearErrorMessage
} = statisticsActions;
