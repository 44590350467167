import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Button } from "ui/components";
import React from "react";
import { useCustomerSupport } from "hooks";
import { REQUEST_DOMAIN_THEME } from "core/constants";

export const AddDomain = () => {
  const { openCustomerSupport } = useCustomerSupport(REQUEST_DOMAIN_THEME);

  return (
    <Grid item xs={12} lg={7}>
      <Stack>
        <Stack>
          <Typography variant="bodyXL">
            First, enter your sending domain (a domain that you own).
          </Typography>
          <Typography variant="bodyXL">
            Next, you will be required to update your DNS records.
          </Typography>
        </Stack>
        <Stack spacing={1.5} alignItems="flex-start" mt={4.1}>
          <Typography variant="bodyXLBold">Add a new sending domain</Typography>
          <Button onClick={openCustomerSupport}>Add domain</Button>
        </Stack>
        <Divider sx={{ opacity: "12%", mt: 7 }} />
      </Stack>
    </Grid>
  );
};
