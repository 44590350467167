import { billingActions } from "./billing.slice";

export const {
  getBillingData,
  getBillingDataSuccess,
  getBillingDataFailed,

  editBillingData,
  editBillingDataSuccess,
  editBillingDataFailed,

  getInvoices,
  getInvoicesSuccess,
  getInvoicesStartSuccess,
  getInvoicesFailed,
  invoicesPagination,

  getSubscriptionInfo,
  getSubscriptionInfoSuccess,
  getSubscriptionInfoFailed,

  getPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,

  addPaymentMethod,
  addPaymentMethodSuccess,
  addPaymentMethodFailed,

  removePaymentMethod,
  removePaymentMethodFailed,
  selectPaymentMethod
} = billingActions;
