import inboxroadIcon from "ui/assets/images/inboxroad-icon.svg";
import inboxroadIconSmall from "ui/assets/images/inboxroad-mark-small.png";
import onboardingVisual from "ui/assets/images/onboarding-visual.svg";
import plantPremiumPlan from "ui/assets/images/plant-premium-plan.svg";
import plantMajorProPlan from "ui/assets/images/plant-major-pro-plan.svg";
import plantProPlan from "ui/assets/images/plant-pro-plan.svg";
import plantBasicPlan from "ui/assets/images/plant-basic-plan.svg";
import dashboard from "ui/assets/images/dashboard.svg";
import support from "ui/assets/images/support.svg";
import settings from "ui/assets/images/settings.svg";
import logFiles from "ui/assets/images/log-files.svg";
import statistics from "ui/assets/images/statistics.svg";
import comments from "ui/assets/images/comments.svg";
import trashCan from "ui/assets/images/trash-can-light.svg";
import pencil from "ui/assets/images/pencil-light.svg";
import user from "ui/assets/images/user-solid.svg";
import copy from "ui/assets/images/copy.svg";
import smtp from "ui/assets/images/smtp.png";
import terminal from "ui/assets/images/terminal.svg";
import ongage from "ui/assets/images/ongage.svg";
import mailwizz from "ui/assets/images/mailwizz.svg";
import interspire from "ui/assets/images/interspire.svg";
import arrowRight from "ui/assets/images/arrow-right-solid.svg";
import plus from "ui/assets/images/plus.svg";
import mastercard from "ui/assets/images/mastercard-icon.png";
import visa from "ui/assets/images/visa.png";
import discover from "ui/assets/images/discover.png";
import defaultPaymentIcon from "ui/assets/images/payment-card.png";
import logout from "ui/assets/images/logout.svg";
import exportIcon from "ui/assets/images/export.svg";
import daniel from "ui/assets/images/daniel-1x.png";

const images = {
  inboxroadIcon,
  inboxroadIconSmall,
  onboardingVisual,
  plantPremiumPlan,
  plantMajorProPlan,
  plantProPlan,
  plantBasicPlan,
  dashboard,
  support,
  settings,
  logFiles,
  statistics,
  comments,
  trashCan,
  pencil,
  user,
  copy,
  smtp,
  terminal,
  arrowRight,
  ongage,
  mailwizz,
  interspire,
  plus,
  mastercard,
  visa,
  discover,
  defaultPaymentIcon,
  logout,
  export: exportIcon,
  daniel
};

export const Image = ({ name, customStyles = {}, height, width }) => {
  return <img style={customStyles} src={images[name]} alt={name} height={height} width={width} />;
};
