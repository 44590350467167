import { StyledTextButton } from "./styled";
import { Typography } from "@mui/material";

export const TextButton = ({ children, ...props }) => {
  return (
    <StyledTextButton {...props}>
      <Typography variant="bodySBold">{children}</Typography>
    </StyledTextButton>
  );
};
