import { call, put, select, takeLatest } from "redux-saga/effects";

import * as upgradePlanActions from "./upgradePlan.actions";
import { MessageService } from "core/api";
import { UPGRADE_PLAN_THEME } from "core/constants";
import { messagesSelectors } from "../Messages/messages.selectors";
import { getCountryName } from "helpers/handleCountries";

function* sendUpgradePlanHandler({ payload }) {
  const messageThemes = yield select(messagesSelectors.themes);

  const subject = messageThemes.find(messageTheme => messageTheme.tag === UPGRADE_PLAN_THEME);

  const countryName = getCountryName(payload?.country);
  const userName = payload?.username || "";
  const userEmail = payload?.email || "";
  const requestedPlan = payload?.selectedPlan || "";

  const text = `The client ${userName} from ${countryName} with email ${userEmail} has requested the plan upgrade to ${requestedPlan}`;

  const body = {
    subject: subject?.value,
    text
  };

  try {
    yield call(MessageService.send, body);
    yield put(upgradePlanActions.sendUpgradePlanSuccess());
    yield put(upgradePlanActions.showUpgradePlanConfirmation());
  } catch (error) {
    yield put(upgradePlanActions.sendUpgradePlanFailed(error));
  }
}

export default function* messagesSaga() {
  yield takeLatest(upgradePlanActions.sendUpgradePlan, sendUpgradePlanHandler);
}
