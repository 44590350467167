import { Grid, Stack, Typography } from "@mui/material";
import { IntegrationItem } from "./IntegrationItem";

const integrations = [
  {
    title: "Ongage",
    text: "View Ongage integration",
    icon: "ongage",
    link: "https://inboxroad.com/documentation/front-end-integrations/ongage-setup/"
  },
  {
    title: "MailWizz",
    text: "View MailWizz integration",
    icon: "mailwizz",
    link: "https://inboxroad.com/documentation/front-end-integrations/mailwizz-setup/"
  },
  {
    title: "Interspire",
    text: "View Interspire integration",
    icon: "interspire",
    link: "https://inboxroad.com/documentation/front-end-integrations/interspire-setup/"
  }
];

export const IntegrationsContainer = () => {
  return (
    <Grid item xs>
      <Stack>
        <Typography mt={2} mb={2} variant="bodyM">
          Integrate with Ongage, Maillwizz or Interspire
        </Typography>
        <Stack spacing={1.5}>
          {integrations.map((integration, index) => (
            <IntegrationItem key={index} integration={integration} />
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
};
