import React from "react";
import { Link } from "react-router-dom";
import { Box, List, ListItem, Drawer } from "@mui/material";

import { Image, NavItem } from "../../index";
import { NavbarContainer } from "../styled";
import { Navbar } from "..";
import { useNavbar } from "../useNavbar";
import { MenuIcon } from "ui/components/Icons/MenuIcon";
import { DASHBOARD_SCREEN } from "core/constants";

const CompressedNavbar = () => {
  const { items, isExpanded, handleExpand } = useNavbar();

  return (
    <NavbarContainer
      sx={{
        minWidth: "40px",
        backgroundColor: "black",
        padding: "20px 15px"
      }}
    >
      <MenuIcon
        onClick={() => handleExpand(true)}
        sx={{ padding: "0 0 0 2px", marginBottom: "32px" }}
      />
      <Box flex={1}>
        <Link to={DASHBOARD_SCREEN}>
          <Image name="inboxroadIconSmall" />
        </Link>
        <List
          sx={{
            p: "0",
            mt: "40px"
          }}
        >
          {items.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                p: 0,
                display: "flex",
                marginBottom: "40px"
              }}
            >
              <NavItem
                id={item.id}
                to={item.to}
                name=""
                icon={item.icon}
                onClick={item.onClick}
                isActive={true}
                isDisabled={item.isDisabled}
                showItems={false}
                items={item.items}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Drawer anchor="left" open={isExpanded} onClose={() => handleExpand(false)}>
        <Navbar />
      </Drawer>
    </NavbarContainer>
  );
};

export default CompressedNavbar;
