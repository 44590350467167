import {
  ADD_CREDIT_CARD_MODAL,
  CHANGE_PASSWORD_MODAL,
  EDIT_BILLING_INFO_MODAL,
  EDIT_PERSONAL_INFO_MODAL,
  SHOW_PAID_PLAN_BLOCK
} from "core/constants";

const ENTITY = "modal";

export const modalSelectors = {
  [EDIT_BILLING_INFO_MODAL]: state => state[ENTITY][EDIT_BILLING_INFO_MODAL],
  [EDIT_PERSONAL_INFO_MODAL]: state => state[ENTITY][EDIT_PERSONAL_INFO_MODAL],
  [CHANGE_PASSWORD_MODAL]: state => state[ENTITY][CHANGE_PASSWORD_MODAL],
  [ADD_CREDIT_CARD_MODAL]: state => state[ENTITY][ADD_CREDIT_CARD_MODAL],
  [SHOW_PAID_PLAN_BLOCK]: state => state[ENTITY][SHOW_PAID_PLAN_BLOCK]
};
