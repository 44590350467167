import { statisticsReducers } from "./statistics.reducers";
import { initialState } from "./statistics.initialState";

const { createSlice } = require("@reduxjs/toolkit");

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: statisticsReducers
});

const statisticsActions = statisticsSlice.actions;
const statisticsReducer = statisticsSlice.reducer;

export { statisticsActions, statisticsReducer };
