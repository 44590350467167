import { Button, Input, Select, ShowPasswordIcon, Spinner, Tooltip } from "ui/components";
import React from "react";
import { Alert, Grid } from "@mui/material";
import { Controller } from "react-hook-form";

import { useCreateAccountStep2 } from "./useCreateAccountStep2";

export const CreateAccountStep2 = () => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    handleClickShowPassword,
    showPassword,
    mailingSystemsOptions,
    isMailingSystemsLoading
  } = useCreateAccountStep2();

  return (
    <>
      <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        {!!errors?.nonFieldErrors && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              {errors?.nonFieldErrors}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="email"
                label="Your business email"
                name="email"
                autoComplete="email"
                error={errors?.email?.message}
                helperText={errors?.email?.message}
                InputProps={{
                  readOnly: true
                }}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Tooltip
                title="Use 8 characters or more. Include both a number and special character (like !@#$)"
                disableHoverListener
                arrow
                enterDelay={1500}
                placement="bottom-start"
              >
                <Input
                  {...field}
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoFocus
                  type={showPassword ? "text" : "password"}
                  onChange={event => {
                    field.onChange(event);
                  }}
                  InputProps={{
                    endAdornment: (
                      <ShowPasswordIcon show={showPassword} toggle={handleClickShowPassword} />
                    )
                  }}
                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                />
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="firstName"
                label="First name"
                name="firstName"
                autoComplete="firstName"
                {...field}
                error={errors?.firstName?.message}
                helperText={errors?.firstName?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="lastName"
                label="Last name"
                name="lastName"
                autoComplete="lastName"
                error={errors?.lastName?.message}
                helperText={errors?.lastName?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <Input
                fullWidth
                id="companyName"
                label="Company name"
                name="companyName"
                autoComplete="companyName"
                {...field}
                error={errors?.companyName?.message}
                helperText={errors?.companyName?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {!isMailingSystemsLoading ? (
            <Controller
              name="mailingSystem"
              control={control}
              render={({ field }) => (
                <Select
                  defaultValue=""
                  name="mailingSystem"
                  fullWidth
                  options={mailingSystemsOptions}
                  label="Email System"
                  placeholder="Email System"
                  error={errors.mailingSystem?.message}
                  helperText={errors.mailingSystem?.message}
                  {...field}
                />
              )}
            />
          ) : (
            <Spinner />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth sx={{ mb: 2 }}>
            Create account
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
