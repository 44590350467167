import { Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { FREE_PLAN } from "core/constants";

export const TextContainer = () => {
  const { plan } = useParams();
  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography mt={2} variant="headlineM">
        Create your account
      </Typography>
      {plan === FREE_PLAN && (
        <>
          <Typography display="flex" variant="regularHeadlineS">
            Get 1.000 free emails per month.
          </Typography>
          <Typography display="flex" variant="regularHeadlineS">
            Try for free without credit card.
          </Typography>
        </>
      )}
    </Stack>
  );
};
