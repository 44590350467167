import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { filterLogOptions } from "core/constants";
import {
  downloadLogs,
  downloadLogsResetFeedback
} from "core/store/entities/Statistics/statistics.actions";
import { statisticsSelectors } from "core/store/entities/Statistics/statistics.selectors";

export const useDownloadLogs = () => {
  const dispatch = useDispatch();
  const isDownloadingLogs = useSelector(statisticsSelectors.isDownloadingLogs);
  const downloadingLogsError = useSelector(statisticsSelectors.downloadingLogsError);
  const downloadingLogsSuccess = useSelector(statisticsSelectors.downloadingLogsSuccess);
  const [selectedPeriod, setSelectedPeriod] = useState(filterLogOptions[0].value);

  const handleChangePeriod = event => {
    dispatch(downloadLogsResetFeedback());
    setSelectedPeriod(event.target.value);
  };

  const handleDownloadLogs = () => {
    dispatch(downloadLogsResetFeedback());
    dispatch(downloadLogs({ period: selectedPeriod }));
  };

  return {
    isDownloadingLogs,
    downloadingLogsError,
    downloadingLogsSuccess,
    selectedPeriod,
    handleChangePeriod,
    handleDownloadLogs
  };
};
