import { useDispatch, useSelector } from "react-redux";
import { useCustomerSupport } from "hooks";
import { REQUEST_NEW_API_KEY } from "core/constants";
import { useEffect } from "react";
import { getWebInfo } from "core/store/entities/User/user.actions";
import { userSelectors } from "core/store/entities/User/user.selectors";

export const useConnectAPI = () => {
  const dispatch = useDispatch();
  const { openCustomerSupport } = useCustomerSupport(REQUEST_NEW_API_KEY);
  const webInfo = useSelector(userSelectors.webInfo);
  const isWebInfoLoading = useSelector(userSelectors.isWebInfoLoading);
  const footer = {
    text: "Request new API key",
    onClick: openCustomerSupport
  };

  useEffect(() => {
    dispatch(getWebInfo());
  }, []);

  return { webInfo, isWebInfoLoading, footer };
};
