import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Navigate } from "react-router-dom";
import { Spinner } from "ui/components";
import { userSelectors } from "core/store/entities/User/user.selectors";
import { loginAsAdmin } from "core/store/entities/User/user.actions";
import { DASHBOARD_SCREEN } from "core/constants";
import { Typography } from "@mui/material";

export const AdminView = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const adminToken = searchParams.get("as");
  const isLoginAsAdminLoading = useSelector(userSelectors.isLoginAsAdminLoading);
  const errorMessage = useSelector(userSelectors.errorMessage);

  useEffect(() => {
    dispatch(loginAsAdmin(adminToken));
  }, []);

  if (adminToken) {
    return (
      <div className="d-flex flex-column align-items-center mt-3">
        {isLoginAsAdminLoading && (
          <>
            <Spinner />
            <Typography variant="headlineM">Logging in as Admin...</Typography>
          </>
        )}
        {errorMessage && <Typography variant="headlineM">{errorMessage}</Typography>}
      </div>
    );
  } else {
    return <Navigate to={DASHBOARD_SCREEN} />;
  }
};
