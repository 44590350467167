import { Stack, Typography } from "@mui/material";
import { EditButton } from "ui/components";

export const BillingInfoBlock = ({ data, spacing, handleEdit }) => {
  return (
    <Stack direction="row" alignItems="flex-start" overflow="auto" gap={1}>
      <Stack flex={1} spacing={spacing}>
        {data.map((item, index) => (
          <Stack key={index} direction="row" alignItems="flex-start">
            <Typography flex={1} variant="bodyS" paddingRight="10px">
              {item.title}
            </Typography>
            <Stack flex={5}>
              {item.values.map((value, index) => (
                <Typography key={index} variant="bodyM">
                  {value}
                </Typography>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <EditButton onClick={handleEdit} />
    </Stack>
  );
};
