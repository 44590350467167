import { all } from "redux-saga/effects";

import Statistics from "../entities/Statistics/statistics.saga";
import Messages from "../entities/Messages/messages.saga";
import Modal from "../entities/Modal/modal.saga";
import UpgradePlan from "../entities/UpgradePlan/upgradePlan.saga";
import UserSaga from "../entities/User/user.saga";
import DomainSaga from "../entities/Domain/domain.saga";
import Billing from "../entities/Billing/billing.saga";

export default function* rootSaga() {
  yield all([
    Statistics(),
    Messages(),
    Modal(),
    UpgradePlan(),
    UserSaga(),
    DomainSaga(),
    Billing()
  ]);
}
