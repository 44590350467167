import { Box, Typography, styled } from "@mui/material";

export const StyledInfoBox = styled(Box)(({ theme, withFooter }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: "15px 20px 20px 25px",
  border: "1px solid #D6D6D6",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: "15px 20px 20px 10px"
  },
  ...(withFooter && {
    paddingBottom: "0 !important"
  })
}));

export const StyledInfoBoxFooter = styled(Box)(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  margin: "20px -20px 0 -25px",
  padding: "10px 20px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px -20px 0 -10px"
  }
}));

export const StyledInfoBoxText = styled(Typography)(() => ({
  cursor: "pointer",
  fontWeight: 700,
  fontSize: 14,
  lineHeight: "14px",
  textDecoration: "underline"
}));
