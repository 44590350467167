import React from "react";
import { Grid } from "@mui/material";
import { Header } from "ui/components";
import { AddDomain } from "./AddDomain";
import { SendingDomainsTable } from "./SendingDomainsTable";
import { Illustration } from "./Illustration";
import { generalContentPadding } from "ui/components/Layout";

export const SendingDomains = () => {
  return (
    <>
      <Header title="Sending domains" />
      <Grid container sx={{ padding: generalContentPadding }}>
        <AddDomain />
        <Illustration />
        <SendingDomainsTable />
      </Grid>
    </>
  );
};
